// Angular
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
// RxJS
import { RegistrationPageContainer } from '@core/models/registration-page.model';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-section-container1',
  templateUrl: './section-container1.component.html',
  styleUrls: ['./section-container1.component.scss'],
})
export class SectionContainer1Component implements OnInit {
  @Input()
  container: RegistrationPageContainer;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 1000,
    autoplaySpeed: 1000,
    autoplay: true,
    items: 1,
    nav: false,
    animateOut: 'animate__animated animate__slideOutDown',
  };

  constructor(private router: Router) {}

  ngOnInit() {}

  onNavigate(link: string) {
    if (link !== '') {
      window.location.href = link;
    }
  }
}
