// Angular
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { DatePipe } from '@angular/common';
// Model
import { Pagination } from '@core/models/pagination.model';
import { ReferralHistory } from '@core/models/referral-history';
// Service
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ReferralHttpService } from '@core/services/referral-http.service';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.scss']
})
export class ReferralsComponent implements OnInit {
  svg: any = svgIconList;

  @ViewChild('tooltipCode') tooltipCode: MatTooltip;
  @ViewChild('tooltipLink') tooltipLink: MatTooltip;

  details: any = {};
  history: Observable<ReferralHistory[]>;
  currency: string;
  userData = JSON.parse(localStorage.getItem('user_data'));

  pagination: Pagination;
  pageSize = 15;
  page = 1;
  maxSize = 5;
  params = `?pagination=true&page=${this.page}&perPage=${this.pageSize}`;

  constructor(
    private referralHttpService: ReferralHttpService,
    private loadingBar: LoadingBarService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.currency = this.userData.currency;
    if (this.userData.KYC === 'pro') {
      this.referralHttpService.getDetails().subscribe(
        (res) => this.details = res,
        (err) => {
          this.details = {};
        },
        () => { });
      this.onViewPageBy();
    }
  }

  onViewPageBy(page = 1, params?: string) {
    this.loadingBar.start();
    this.params = `?pagination=true&page=${page}&perPage=${this.pageSize}`;
    this.history = this.referralHttpService.getHistory(this.params).pipe(
      tap(res => {
        this.pagination = this.referralHttpService.pagination;
        res.map(item => item.register_date = this.datePipe.transform(item['register_date'], 'yyyy-MM-dd HH:mm'));
        this.loadingBar.complete();
      })
    );
  }

  onCopyText(inputElement: any, type: string) {
    inputElement.select();
    document.execCommand('copy');

    if (type === 'code') {
      this.tooltipCode.show();
      setTimeout(() => this.tooltipCode.hide(), 2000);
    } else {
      this.tooltipLink.show();
      setTimeout(() => this.tooltipLink.hide(), 2000);
    }
  }
}
