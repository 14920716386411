// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Component
import { ForgotPasswordComponent } from './forgot-password.component';
// Module
import { SharedModule } from '@shared/shared.module';
import { ViewsModule } from '@views/views.module';

const routes: Routes = [
  {
    path: '',
    component: ForgotPasswordComponent
  }
];

@NgModule({
  declarations: [ForgotPasswordComponent],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    ViewsModule
  ],
  exports: [RouterModule]
})
export class ForgotPasswordModule { }
