
<!-- Modal -->
<div class="block mx-auto ma-box boxshadow">
    <div class="modal1" id="modal-forgotpassword">
       <button title="Close (Esc)" type="button" class="mfp-close" (click)="onCloseDialog()">×</button>
       <div class="row" style="margin-left:0px;margin-right:0px;">
          <div class="col-lg-12">
             <div style="padding: 30px;">
                <div class="fancy-title title-border">
                    <h4>{{ 'Forgot Password' | translate }}</h4>
                </div>
                <form [formGroup]="form" id="forgot-password-form" name="forgot-password-form">
                    <div class="col-12 form-group">
                      <label>{{ 'Username' | translate }}:</label>
                      <input type="text" value="" class="form-control" formControlName="username" (input)="toLowerCaseInput('username', $event)">
                    </div>
                    <div class="col-12 form-group">
                      <label>{{ 'Phone Number' | translate }}: </label>
                      <input type="text" value="" class="form-control" formControlName="phone_number" (keypress)="numberOnly($event)">
                    </div>
                    <div class="col-12 form-group">
                      <button (click)="submit()" class="button button-3d button-orangish m-0" value="">{{'Submit'| translate}}</button>
                      <hr>
                      {{'Do not have an account yet?' | translate}} {{'Click here to' | translate}} <a routerLink="/signup" class="auth-text" (click)="onCloseDialog()">{{'Register Now'| translate}} !</a> </div>
                  </form>
             </div>
          </div>
       </div>
    </div>
 </div>
 <!--End modal-->
 
<app-swal-alert [success]="isSuccess" [message]="messages$ | async" (confirmed)="onRedirectHome()"></app-swal-alert>