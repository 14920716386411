import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { interval } from 'rxjs';
import { environment } from '@env/environment';

@Component({
  selector: 'app-site-route',
  templateUrl: './site-route.component.html',
  styleUrls: ['./site-route.component.scss']
})
export class SiteRouteComponent implements OnInit {

  tick: number = 10;
  disableButton: boolean = false;
  link: string;
  countdown: any;
  username: string;
  sitePrefix: string;

  supportedCountry = this.dropdownHttpService.availableCountryAndLanguages;
  hostname = (window.location.hostname).replace(/^([w]{3}.)(?=[a-z0-9]+)/, '');
  mainDomainBlocked: Boolean;
  currentDomain: string;

  constructor(
    private dialogRef: MatDialogRef<SiteRouteComponent>,
    private loadingBar: LoadingBarService,
    @Inject(MAT_DIALOG_DATA) public data: {
      domain: string
    },
    private dropdownHttpService: DropdownHttpService,
  ) { }

  async ngOnInit() {
    this.sitePrefix = environment.sitePrefix.toUpperCase();
    await this.getHostname();

    const domain = this.supportedCountry[this.currentDomain][localStorage.getItem('country_code')].redirect;
    const user_data = JSON.parse(localStorage.getItem('user_data'));

    this.link = domain;
    this.username = user_data.username;
    if (environment.sitePrefix != 'BP9') {
      this.dialogRef.disableClose = true;
    }
  }

  ngAfterViewInit() {
    // if (environment.sitePrefix != 'BP9') {
    //   this.startCountdown();
    // }
  }

  startCountdown() {
    this.countdown = interval(1000).subscribe(() => {
      if (this.tick > 0) {
        this.tick--;
      } else {
        this.redirectsite();
      }
    });
  }

  redirectsite() {
    const protocol = environment.https ? 'https://' : 'http://';
    this.loadingBar.start();
    this.disableButton = true;
    // this.countdown.unsubscribe();

    window.location.href = `${protocol}${this.link}?userToken=${localStorage.getItem('user_token')}&userData=${localStorage.getItem('user_data')}`;
  }

  hidenotice() {
    localStorage.setItem('hide_save_link_dialog', '1');
    this.closedialog();
  }

  closedialog() {
    this.dialogRef.close();
  }

  private getHostname() {
    return new Promise<void>((resolve, reject) => {
      // E.g: localhost.com
      // Get localhost.com
      if (this.hostname.split('.').length > 2) {
        this.hostname = this.hostname.substr(this.hostname.indexOf('.') + 1);
      }

      // First Alternative
      if (
        JSON.stringify(this.supportedCountry[environment.altDomainName]).includes('"' + this.hostname + '"') ||
        environment.altDomainName.split('|').includes(this.hostname)
      ) {
        sessionStorage.setItem('mainDomainBlocked', 'true');
        this.mainDomainBlocked = true;
        this.currentDomain = environment.altDomainName;
        resolve();
      }
      // Second Alternative
      else if (
        JSON.stringify(this.supportedCountry[environment.altDomainNameTwo]).includes('"' + this.hostname + '"') ||
        environment.altDomainNameTwo.split('|').includes(this.hostname)
      ) {
        sessionStorage.setItem('mainDomainBlocked', 'true');
        this.mainDomainBlocked = true;
        this.currentDomain = environment.altDomainNameTwo;
        resolve();
      }
      // Default
      else {
        sessionStorage.removeItem('mainDomainBlocked');
        this.mainDomainBlocked = false;
        this.currentDomain = environment.domainName;
        resolve();
      }
    })
  }
}
