// Service
import { BankAccountHttpService } from '@core/services/bank-account-http.service';
// Angular
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CryptoHttpService } from '@core/services/crypto-http.service';

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss']
})
export class BankComponent implements OnInit {

  activeTab = this.activatedRoute.snapshot.paramMap.get('tab') ? this.activatedRoute.snapshot.paramMap.get('tab') : 'bank';
  messages$ = this.bankAccountHttpService.messages$;
  availableTokens:any;
  constructor(
    private bankAccountHttpService: BankAccountHttpService,
    private activatedRoute: ActivatedRoute,
    private cryptoHttpService: CryptoHttpService,
  ) { }

  ngOnInit() {
    this.cryptoHttpService.getCryptoTokens().subscribe(res => {
      this.availableTokens = JSON.parse(JSON.stringify(res));
    })
  }

  onSelectTab(tab: string) {
    this.activeTab = tab;
    window.history.replaceState({}, '', `/member/bank;tab=${tab}`);
  }

}
