// Angular
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Model
import { ApiResponse } from '@core/models/api-response.model';
import { Observable } from 'rxjs';
// RxJS
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationHttpService {

  constructor(private http: HttpClient) { }

  getByCoordinates(longtitude: string, latitude: string) {
    return this.http.get(`//geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=json&location=${longtitude},${latitude}`).pipe(
      map(res => res['address'])
    );
  }

  // Blocked by CORS policy
  // getByIpAddress(ip_address: string) {
  //   return this.http.get(`//ipapi.co/${ip_address}/json`);
  // }

  getIpAddress(): Observable<any[]> {
    return this.http.get<ApiResponse>(`/getip`, { observe: 'response' }).pipe(
      map((res: HttpResponse<ApiResponse>) => {
        // Access the X-Country-Code header
        const country_code = res.headers.get('X-Country-Code');
        // Add country_code to the response data
        const responseData = res.body?.data?.rows || [];

        return { ...responseData, country_code };
      })
    );
  }
}
