import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { environment } from '@env/environment';

@Component({
  selector: 'app-popup-referral',
  templateUrl: './popup-referral.component.html',
  styleUrls: ['./popup-referral.component.scss']
})
export class PopupReferralComponent implements OnInit {

  @ViewChild('tooltipCode') tooltipCode: MatTooltip;
  @ViewChild('tooltipLink') tooltipLink: MatTooltip;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { details: any },
    public dialogRef: MatDialogRef<PopupReferralComponent>,
  ) { }

  ngOnInit(): void {
  }

  onCopyText(inputElement: any, type: string) {
    inputElement.select();
    document.execCommand('copy');

    if (type === 'code') {
      this.tooltipCode.show();
      setTimeout(() => this.tooltipCode.hide(), 2000);
    } else {
      this.tooltipLink.show();
      setTimeout(() => this.tooltipLink.hide(), 2000);
    }
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }


}
