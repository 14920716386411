<!-- Modal -->
<div class="block mx-auto modal-box">
    <div class="modal1" id="modal-signup">
       <button title="Close (Esc)" type="button" class="mfp-close m-r-5 m-t-5" (click)="onCloseDialog()">×</button>
       <div class="row">
            <div class="col-lg-12">
                <div class="p-20">
                    <div class="fancy-title">
                        <h4>{{ 'Share This Link' | translate }}</h4>
                    </div>
                    <hr>
                    <div class="input-with-icon input_container">
                        <input type="text" class="form-control form-control-sm form-input-style with-copy" [value]="data ? data.details.register_link : ''" readonly #referralLink>
                        <img src="/assets/images/copy-icon.png" alt="Copy Icon" class="copy-icon"
                            (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation()" mat-raised-button #tooltipLink="matTooltip"
                            matTooltip="{{ 'Copied' | translate }}" matTooltipPosition="above" (click)="onCopyText(referralLink, 'link')">
                    </div>
                    <div class="col-12 form-group m-t-20 p-l-0 p-r-0">
                        <button class="button-blue w-100" (click)="onCloseDialog()">{{ 'Close' | translate }}</button>
                    </div>
                </div>
            </div>
       </div>
    </div>
 </div>
 
<!-- <div class="block mx-auto modal-box">
    <div class="modal1" id="modal-signup">
       <button title="Close (Esc)" type="button" class="mfp-close m-t-15 m-r-15" (click)="onCloseDialog()">×</button>
       <div class="row">
          <div class="col-lg-12">
             <div class="p-20">
                <div class="fancy-title">
                   <img src="/assets/images/register.png" class="title-image">
                   <h4>{{ 'Registration' | translate }}</h4>
                </div>
                <hr>
                <form class="row mb-0" [formGroup]="form" (ngSubmit)="submit(this.form.value)" id="signup-modal-form" name="signup-modal-form">
                   <div class="col-12 form-group">
                      <label>{{ 'Username' | translate }}</label>
                      <input type="text" class="form-control data-hj-allow" formControlName="username" [ngClass]="{'is-invalid': checkValidity && form.controls.username.errors }" (input)="toLowerCaseInput('username', $event)" id="username" name="username"/>
                      <small for="register-form-password" class="text-danger" *ngIf="!form.get('username').pristine && !form.get('username').valid">
                         {{ 'Username must contain a length of 6 to 16, consists of letters and digits.' | translate }}</small>
                   </div>
                   <div class="col-12 form-group">
                      <label for="register-form-password">{{ 'Phone Number' | translate }} </label>
                      <div class="row">
                         <div class="col-3">
                            <mat-select [(value)]="selected" class="form-control p-0" [ngClass]="selected" formControlName="country_code" disableOptionCentering>
                               <mat-option *ngFor="let dropDownCountry of dropDownCountries" [value]="dropDownCountry.value" (click)="onChangeCountry(dropDownCountry.value)">
                                  <img src='{{dropDownCountry.img}}'>
                               </mat-option>
                            </mat-select>
                         </div>
                         <div class="col-9 p-l-0">
                            <input type="" class="form-control" formControlName="phone_number" [ngClass]="{'is-invalid': checkValidity && form.controls.phone_number.errors }" (keypress)="numberOnly($event)" />
                         </div>
                      </div>
                   </div>
                   <div class="col-12 form-group">
                      <label for="register-form-password">{{ 'Password' | translate }} </label>
                      <div class="input-icons-password ">
                         <i [class]="passwordInput.icon + ' icon-eye-password'" (click)="onPasswordMask('password')"></i>
                         <input [type]="passwordInput.type" class="form-control" formControlName="password" [ngClass]="{'is-invalid password-invalid': checkValidity && form.controls.password.errors }" (keyup)="onCheckPassword()" id="password" name="password"/>
                         <small for="register-form-password" class="text-danger" *ngIf="!form.get('password').pristine && !form.get('password').valid && form.get('password').errors?.minlength">
                            {{ 'Password must be at least 6 characters' | translate }}
                         </small>
                      </div>
                   </div>
                   <div class="col-12 form-group">
                      <label for="register-form-password">{{ 'Confirm Password' | translate }} </label>
                      <div class="input-icons-password ">
                         <i [class]="confirmPasswordInput.icon + ' icon-eye-password'" (click)="onPasswordMask('confirmPassword')"></i>
                         <input [type]="confirmPasswordInput.type" class="form-control" formControlName="confirm_password" [ngClass]="{'is-invalid password-invalid': checkValidity && form.controls.confirm_password.errors }" (keyup)="onCheckPassword()"/>
                         <small for="register-form-password" class="text-danger" *ngIf="passwordMatched === false">{{ 'Password not matched' | translate }}</small>
                      </div>
                   </div>
                   <div class="col-12 form-group">
                      <label>{{ 'Referral' | translate }} ({{ 'Optional' | translate }})</label>
                      <input type="" class="form-control" formControlName="ref" />
                   </div>
                   <div class="col-12 form-group">
                      <p>{{ 'By clicking Join, I confirm that I am at least 18 years of age and that I accept the Terms & Conditions.' | translate }}</p>
                      <button type="submit" class="button-blue w-100" value="" >{{ 'Join' | translate }}</button>
                   </div>
                   <div class="row m-l-0 m-r-0 w-100">
                      <div class="col-lg-12 text-center p-r-0">
                         <span>{{ 'Existing Member?' | translate }}
                            <a routerLink="/login" class="auth-text" (click)="onCloseDialog()">{{ 'Login Now' | translate }}</a>
                         </span>
                      </div>
                   </div>
                </form>
             </div>
          </div>
       </div>
    </div>
 </div> -->