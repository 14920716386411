<p  class="m-t-0 p-l-0" *ngIf="display === 'promotion'; else elseCountDownGameRef">

  <span span *ngIf="isDurationEnded(); else activePromoRef">{{ 'Promotion Ended' | translate }}</span>
  <ng-template #activePromoRef>
    <i class="icon-stopwatch"></i>
    <span *ngIf="currentTimeInSeconds < startDateInSeconds">{{ 'Starting In' | translate }}: </span>
    <span *ngIf="currentTimeInSeconds > startDateInSeconds">{{ 'Remaining Time' | translate }}: </span>
  </ng-template>

  <ng-container *ngIf="!isDurationEnded()">
    {{ '[days] days [hours] hours' | translate : durationData }}
  </ng-container>
</p>

<ng-template #elseCountDownGameRef>
  <ng-container *ngIf="display === 'prediction' else elseCountDownGame">
    <div class="prediction-timer text-center">
      <ng-container *ngIf="days > 0">
        <div class="bloc-time">
          <span class="d-block label">{{ 'Day' | translate }}</span>
          <div [innerHtml]="onFormatTimer(days)"></div>
        </div>
        <div>
          <span class="d-block" style="height: 13px;"></span>
          <div class="separator">:</div>
        </div>
      </ng-container>
      <div class="bloc-time" *ngIf="hours >= 0">
        <span class="d-block label">{{ 'Hour' | translate }}</span>
        <div [innerHtml]="onFormatTimer(hours)"></div>
      </div>
      <div *ngIf="hours >= 0">
        <span class="d-block" style="height: 13px;"></span>
        <div class="separator">:</div>
      </div>
      <div class="bloc-time" *ngIf="minutes >= 0">
        <span class="d-block label">{{ 'Minute' | translate }}</span>
        <div [innerHtml]="onFormatTimer(minutes)"></div>
      </div>
      <ng-container *ngIf="days == 0">
        <div>
          <span class="d-block" style="height: 13px;"></span>
          <div class="separator">:</div>
        </div>
        <div class="bloc-time">
          <span class="d-block label">{{ 'Second' | translate }}</span>
          <div [innerHtml]="onFormatTimer(seconds)"></div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #elseCountDownGame>
    <p class="m-t-0 p-l-0" *ngIf="days > 0">
      {{ '[days] days [hours] hours' | translate : durationData }}
    </p>
    <p class="m-t-0 p-l-0" *ngIf="days == 0 ">
      {{ 'will be back in [hours] hour(s) [minutes] minute(s) [seconds] second(s)' | translate : durationData }}
    </p>
  </ng-template>
</ng-template>