// Service
import { createReducer, on, Action } from '@ngrx/store';
import { AuthActions } from './auth-action.types';
// Model
import { Auth } from '@core/models/auth.model';

export interface AuthState {
  user: Auth;
}

const initialAuthState: AuthState = {
  user: undefined
};

export const reducer = createReducer(

  initialAuthState,

  on(AuthActions.login, (state, action) => {
    return { ...state, user: action };
  }),

  on(AuthActions.logout, (state, action) => {
    return { ...state, user: {} };
  })
);

export function authReducer(state: AuthState, action: Action) {
  return reducer(state, action);
}



