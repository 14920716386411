import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationCancel } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';


@Component({
  selector: 'app-base-inner',
  templateUrl: './base-inner.component.html',
  styleUrls: ['./base-inner.component.scss']
})
export class BaseInnerComponent implements OnInit {
  maintenanceTime = localStorage.getItem('maintenance');;
  hasRewards: boolean;
  show = false;

  constructor(
    private router: Router,
    public loadingBar: LoadingBarService,
  ) { }

  ngOnInit() {
    // Percentage just in case needed!
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loadingBar.start();
      }
      if (event instanceof RouteConfigLoadStart) {
        this.loadingBar.increment(35);
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.loadingBar.increment(75);
      }
      if (event instanceof NavigationEnd) {
        this.maintenanceTime = localStorage.getItem('maintenance');
        this.toShow();
        this.loadingBar.complete();
      }
      if (event instanceof NavigationCancel) {
        this.loadingBar.complete();
      }
    });

    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd),
    // ).subscribe(() => {
    // });
  }

  private toShow() {
    if (this.router.url == '/landing' || this.router.url.includes('landing')) {
      this.show = false;
    } else {
      this.show = true;
    }
  }
}
