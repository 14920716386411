<!-- Modal -->
<div class="block mx-auto modal-box">
   <div class="modal1" id="modal-login">
      <button title="Close (Esc)" type="button" class="mfp-close m-t-15 m-r-15" (click)="onCloseDialog()">×</button>
      <div class="row">
         <div class="col-lg-12">
            <div class="p-20">
               <div class="fancy-title">
                  <span [innerHTML]="svg.loginIcon  | safeHtml" class="title-image"></span>
                  <h4>{{ 'Login' | translate }}</h4>
               </div>
               <hr>
               <form action="#" method="post" class="row mb-0" [formGroup]="form" (ngSubmit)="submit()" id="login-modal-form" name="login-modal-form">
                  <div class="col-12 form-group">
                     <label class="text-capitalize">{{ 'Username' | translate }} </label>
                     <input type="text" class="form-control data-hj-allow" formControlName="username" (input)="toLowerCaseInput('username', $event)" #focusInput/>
                  </div>
                  <div class="col-12 form-group">
                     <label class="text-capitalize">{{ 'Password' | translate }} </label>
                     <div class="input-icons-password">
                        <i class="icon-eye-password" (click)="onPasswordMask()" [innerHTML]="passwordInput.icon | safeHtml"></i>
                        <input [type]="passwordInput.type" class="form-control" formControlName="password" />
                        <small for="register-form-password" class="text-danger" *ngIf="!form.get('password').pristine && !form.get('password').valid && form.get('password').errors?.minlength">
                           {{ 'Password must be at least 6 characters' | translate }}
                        </small>
                     </div>
                  </div>
                  <div class="col-12 form-group">
                     <button type="submit" class="button-blue w-100">{{ 'Login' | translate }}</button>
                  </div>
                  <div class="row m-l-0 m-r-0 w-100">
                     <div class="col-lg-6 text-left p-r-0">
                        <a routerLink="/forgot-password" class="auth-text" (click)="onCloseDialog()">{{ 'Forgot Password' | translate}}?</a>
                     </div>
                     <div class="col-lg-6 text-right p-l-0">
                        <ng-container *ngIf="currentCountry === 'TH' && languageCode === 'TH'; else elseOther">
                          <span>
                             <a routerLink="/signup" class="auth-text" (click)="onCloseDialog()">{{ 'Register Now' | translate | uppercase}}</a>
                          </span>
                        </ng-container>
                        <ng-template #elseOther>
                           <span>{{ 'New User?' | translate }}
                              <a routerLink="/signup" class="auth-text" (click)="onCloseDialog()">{{ 'Sign Up' | translate | uppercase}}</a>
                           </span>
                        </ng-template>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </div>
   </div>
</div>
<!--End modal-->
<app-swal-alert [message]="messages$ | async" [success]="true" [login]="true"></app-swal-alert>