<div class="block mx-auto ma-box modal-box">
    <div class="modal1">
        <div class="approval-notice">
            <span class="msg-icon-greentick" [innerHTML]="svg.greenTickIcon | safeHtml"></span>
            <h4 class="m-b-10">{{ 'Verification Successful' | translate }}</h4>
            <p>{{ 'Your Ewallet has been verified and can be used for withdrawal.' | translate }}</p>
            <div class="ewallet-detail row">
             <!-- Ewallet-->
             <label class="col-5 m-0 py-1">{{ 'E-Wallet' | translate }}</label>
             <label class="col-1 m-0 py-1 px-0">:</label>
             <label class="col-6 m-0 py-1 px-0">{{ this.data.bank_name }}</label>
             <!-- Account Number -->
             <label class="col-5 m-0 py-1">{{ 'Account Number' | translate }}</label>
             <label class="col-1 m-0 py-1 px-0">:</label>
             <label class="col-6 m-0 py-1 px-0">{{ this.data.account_number }}</label>
            </div>
            <!-- No need for translation -->
            <button class="button-blue w-100 m-b-10" (click)="onCloseDialog()">OK</button>
        </div>
    </div>
 </div>