import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { TwoStepDepositService } from '@views/modules/member/services/two-step-deposit.service';
import { TwoStepDepositDataService } from '@views/modules/member/services/two-step-deposit-data.service';

@Injectable({
  providedIn: 'root',
})
export class WalletDeactivateGuard implements CanDeactivate<any> {
  constructor(
    private twoStepDepositService: TwoStepDepositService,
    private twoStepDepositDataService: TwoStepDepositDataService,
  ) {}

  async canDeactivate(component: any): Promise<boolean> {
    const isDepositTab = localStorage.getItem('wallet_tab') === 'deposit';
    const lsStepTwoDeposit =
      this.twoStepDepositDataService.getLSStepTwoDeposit();

    if (lsStepTwoDeposit && isDepositTab) {
      return this.checkDepositTabDeactivation(lsStepTwoDeposit);
    }

    // user didn't have any pending callback deposit
    this.removeWalletTab();
    return true;
  }

  async checkDepositTabDeactivation(
    lsStepTwoDeposit: string
  ): Promise<boolean> {
    const result = await this.twoStepDepositService.handleLeavingStepTwoPage(lsStepTwoDeposit);

    if (result) {
      this.removeWalletTab();
      return true;
    }

    return false;
  }

  removeWalletTab() {
    localStorage.removeItem('wallet_tab');
  }
}
