// Angular
import { Component, OnInit, AfterViewInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
// Service
import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { BankHttpService } from '@core/services/bank-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AuthHttpService } from '@core/services/auth-http.service';
import * as fromAuthActions from '@core/store/auth/auth.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { BankAccountHttpService } from '@core/services/bank-account-http.service';
import { environment } from '@env/environment';
// Model
import { AuthRegister } from '@core/models/auth-register.model';
//Recaptcha
import { ReCaptchaV3Service } from 'ng-recaptcha';
// RxJS
import { of, Subscription, forkJoin } from 'rxjs';
import { delay, tap, catchError, map } from 'rxjs/operators';
import svgIconList from 'assets/icons.json';

declare const $: any;
@Component({
  selector: 'app-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.scss']
})
export class SignupFormComponent implements OnInit, AfterViewInit {
  svg: any = svgIconList;

  @ViewChildren('focusInput') focusInput: QueryList<ElementRef>;
  mode: string = window.mode;
  messages$ = this.auth.messages$;
  form: FormGroup;
  sourceTracker: string;
  campaignCode: string;
  clickid: string;
  telemarketer_id: string;
  cid: string;
  ref = null;
  aff = null;
  agent = null;
  isRefSet = false;
  showRef = true;
  showAgent = false;
  showAff = false;
  passwordMatched = true;
  dropDownCountries = [];
  selected = "";
  css = "";
  dialogCode = null;

  passwordInput = {
    type: 'password',
    icon: this.svg.hidePasswordIcon
  };

  confirmPasswordInput = {
    type: 'password',
    icon: this.svg.hidePasswordIcon
  };

  currentCountry = localStorage.getItem('country_code');

  usernameFormatValid = {
    consist: false,
    special: false
  };

  // recaptcha
  recaptchaScore = 0;

  // server side validationS
  enableServerSideValidation = true;
  serverSideValidated = false;
  serverSideValidations = { // true if valid, else false
    username: false,
  };

  disableButton = false;
  checkValidity = false;
  supportedCountry = this.dropdownHttpService.availableCountryAndLanguages;
  currentDomain = JSON.parse(sessionStorage.getItem('mainDomainBlocked')) ? this.getAltDomain() : environment.domainName;
  private unsubscribe: Subscription[] = [];
  private subscription = new Subscription();

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private router: Router,
    private auth: AuthHttpService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private eventEmitterService: EventEmitterService,
    public loadingBar: LoadingBarService,
    private bankHttpService: BankHttpService,
    private gameCategoryHttpService: GameCategoryHttpService,
    private merchantBankHttpService: MerchantBankHttpService,
    private bankAccountHttpService: BankAccountHttpService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) { }

  ngOnInit() {
    Object.keys(this.dropdownHttpService.availableCountryAndLanguages[this.currentDomain]).forEach(key => {
      if (key != 'TH') {
        this.css = "." + key + " {background: url('/assets/images/language/" + key + ".png') 40% 50% / contain no-repeat; white-space: nowrap; background-size: 25px;}";
        var style = document.createElement("STYLE");
        style.innerText = this.css;
        document.body.appendChild(style);
        this.dropDownCountries.push({
          value: key,
          img: '/assets/images/language/' + key + '.png'
        });
      }
    })
    this.selected = localStorage.getItem('country_code') ? localStorage.getItem('country_code').toUpperCase() : 'MY';
    this.route.queryParams.subscribe((params: Params) => {
      this.campaignCode = params[`campaign`];
      this.dialogCode = params[`dialog`];
      this.clickid = params[`clickid`];
      this.cid = params[`cid`];
      this.telemarketer_id = params[`telemarketer_id`];
    });
    this.setRefAgentAff();
    this.initializeForm();
    of(null).pipe(
      delay(200), tap(() => this.focusInput.first.nativeElement.focus()
      )).subscribe();
    this.eventEmitterService.buttonEmitter.subscribe(() => {
      this.disableButton = false;
    });

    if (this.mode != 'Members Only') {
      $(document).on('click', ".tnc-link", function () {
        window.history.replaceState({}, '', `/info/terms-conditions`);
        window.location.reload();
      });
    }
  }

  ngAfterViewInit() {
    this.auth.getAffiliateAndMarketingParams();
  }

  onCheckPassword() {
    this.passwordMatched = true;
    if (this.form.value.confirm_password !== null && this.form.value.password !== null) {
      if (this.form.value.password !== this.form.value.confirm_password) {
        this.passwordMatched = false;
        this.form.controls['confirm_password'].setErrors({ 'incorrect': true });
      } else {
        this.form.controls['confirm_password'].setErrors(null);
        this.passwordMatched = true;
      }

      if (this.form.value.password.length === 0 || this.form.value.confirm_password.length === 0) {
        this.passwordMatched = true;
      }
    }
  }

  onCheckUsernameFormat(username: any) {
    return this.usernameFormatValid = {
      consist: /[a-zA-Z]/.test(username) || (/[0-9]/.test(username) && /[a-zA-Z]/.test(username)),
      special: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(username)
    };
  }

  resetServerValidation() {
    this.serverSideValidated = false;

    if (this.enableServerSideValidation) {
      this.serverSideValidations = {
        username: false,
      };
    }
  }

  onServerSideUsernameValidation(username: any) {
    if (!username) return;

    this.recaptchaV3Service
      .execute('ValidateUsername')
      .pipe(
        tap((token: any) => {
          // if token not available, disable server side validation
          if (!token) {
            this.enableServerSideValidation = false;
            return;
          }

          if (this.enableServerSideValidation) {
            this.auth.validate({ response: token, username }).subscribe(res => {
              this.serverSideValidated = true;
              const errorType = res.data?.rows?.error_type;
              this.serverSideValidations.username = (errorType == 0) || !(errorType == 1);
            });
          }
        }),
        catchError((err) => {
          this.enableServerSideValidation = false;
          this.serverSideValidations.username = true;
          return err;
        })
      ).subscribe();
  }

  onPasswordMask(type?: string) {
    if (type === 'password') {
      this.passwordInput.type = this.passwordInput.type === 'password' ? 'text' : 'password';
      this.passwordInput.icon = this.passwordInput.icon === this.svg.hidePasswordIcon ?this.svg.viewPasswordIcon : this.svg.hidePasswordIcon;
    } else {
      this.confirmPasswordInput.type = this.confirmPasswordInput.type === 'password' ? 'text' : 'password';
      this.confirmPasswordInput.icon = this.confirmPasswordInput.icon === this.svg.hidePasswordIcon ?this.svg.viewPasswordIcon : this.svg.hidePasswordIcon;
    }
  }

  onRedirectLogin() {
    this.router.navigate(['/']);
  }

  submit(value: AuthRegister) {
    localStorage.setItem('country_code', value.country_code);
    this.disableButton = true;
    this.checkValidity = true;
    if (this.form.valid && this.passwordMatched && this.serverSideValidations.username) {
      this.loadingBar.start();
      for (let key in value) {
        if (value[key] === null) {
          delete value[key];
        }
      }
      this.auth.register(value).pipe(
        tap((res) => {
          this.store.dispatch(fromAuthActions.register());
          of(null).pipe(
            delay(1000), tap(() => {
              this.eventEmitterService.onTopBarTabEmitter();
              this.setSessionStorage(res.data.user.country_code);
            }
            )).subscribe();

          this.store.dispatch(fromAuthActions.login({
            user: res.data.user,
            token: res.data.token,
          }));
          localStorage.setItem('verified_number', res.data.user.phone_validated);
          localStorage.setItem('reward_notice', res.data.user.pending_reward);
          this.router.navigate(['/']);
          this.loadingBar.complete();
          this.disableButton = false;
          this.checkValidity = false;
        })
      ).subscribe(
        response => {
          if (this.dialogCode != null) {
            localStorage.setItem('dialog', this.dialogCode);
          }
          localStorage.removeItem('announcements_pop');
        }
      );
    }
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.auth.forceLowerCaseInputControl(this.form, controlName, event);
  }

  numberOnly(event: KeyboardEvent) {
    return this.auth.numberOnly(event);
  }

  onChangeCountry(countryCode: string) {
    this.currentCountry = countryCode;
    this.form.controls['phone_number'].setValidators([Validators.pattern(this.getPhoneNumberRegex(this.currentCountry)), Validators.required]);
    this.selected = countryCode;
    this.form.patchValue({
      country_code: countryCode,
      phone_number: null
    })
  }

  private setSessionStorage(countryCode: string) {
    this.bankHttpService.getAllBanks().subscribe(res => {
      sessionStorage.setItem('all_bank', JSON.stringify(res))
    });

    // this.gameCategoryHttpService.getGameCategoryListWithPromo().subscribe();
    
    this.merchantBankHttpService.getMerchantBankDeposits().subscribe(res => {
      sessionStorage.setItem('member_merchant_bank', JSON.stringify(res))
    });
    this.bankAccountHttpService.getAll().subscribe(res => {
      sessionStorage.setItem('member_bank_accounts', JSON.stringify(res))
    });
    if (countryCode.toLocaleUpperCase() === 'SG') {
      this.bankHttpService.getPaymentGatewayBank('FP').subscribe(res => {
        sessionStorage.setItem('member_fpbank_code', JSON.stringify(res))
      });
    }
  }

  private initializeForm() {
    this.form = this.fb.group({
      username: [
        '',
        [Validators.pattern(`(^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$)|([a-zA-Z])`), Validators.required, Validators.minLength(6), Validators.maxLength(16)]
      ],
      country_code: [
        this.selected
      ],
      phone_number: [
        '',
        [Validators.pattern(this.getPhoneNumberRegex(this.currentCountry)), Validators.required]
      ],
      password: [
        '',
        [Validators.required, Validators.minLength(6)]
      ],
      confirm_password: [
        '',
        [Validators.required]
      ],
      ref: [
        this.ref
      ],
      aff: [
        this.aff
      ],
      agent: [
        this.agent
      ]
    });
  }

  private getPhoneNumberRegex(countryCode: string) {
    return this.dropdownHttpService.regex[countryCode];
  }

  private setRefAgentAff() {
    var ref = JSON.parse(localStorage.getItem('ref'));
    var agent = JSON.parse(localStorage.getItem('agent'));
    var aff = JSON.parse(localStorage.getItem('aff'));

    if (ref !== null) {
      this.ref = ref;
      this.isRefSet = true;
      this.showRef = true;
    }
    else if (agent !== null) {
      this.agent = agent;
      this.showAgent = true;
      this.showRef = false;
    }
    else if (aff !== null) {
      this.aff = aff;
      this.showAff = true;
      this.showRef = false;
    }

  }

  private getAltDomain() {
    let hostname = (window.location.hostname).replace(/^([w]{3}.)(?=[a-z0-9]+)/, '');
    // E.g: localhost.com
    // Get localhost.com
    if (hostname.split('.').length > 2) {
      hostname = hostname.substr(hostname.indexOf('.') + 1);
    }

    // First Alternative
    if (
      JSON.stringify(this.supportedCountry[environment.altDomainName]).includes('"' + hostname + '"') ||
      environment.altDomainName.split('|').includes(hostname)
    ) {
      return environment.altDomainName;
    }
    // Second Alternative
    else if (
      JSON.stringify(this.supportedCountry[environment.altDomainNameTwo]).includes('"' + hostname + '"') ||
      environment.altDomainNameTwo.split('|').includes(hostname)
    ) {
      return environment.altDomainNameTwo;
    }
  }

}
