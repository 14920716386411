// Model
import { Banner } from './../models/banner.model';
import { ApiResponse } from '../models/api-response.model';
// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// RxJS
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LiveChatHttpService {

  website: any;
  chat_page: any;
  live_chat_platform_id: any;
  live_chat_platform_name: any;

  constructor(private http: HttpClient) { }

  getLiveChatScript(Param: string): Observable<any> {
    return this.http.get<ApiResponse>(`/livechat/getscript${Param}`).pipe(
      map(res => {
        if( res.data != null  ){
          this.website = res.data.rows.website;
          this.chat_page = res.data.rows.chat_page;
          this.live_chat_platform_id = res.data.rows.live_chat_platform_id;
          this.live_chat_platform_name = res.data.rows.live_chat_platform_name;
          return res.data.rows
        }
        this.website = '';
        this.chat_page = '';
        this.live_chat_platform_id = '';
        this.live_chat_platform_name = '';
        return res.data;
      }),
    );
  }

}
