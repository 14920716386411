// Angular
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class LiveChatIdService {

  sitePrefix = environment.sitePrefix.toUpperCase();

  constructor(
  ) { }

  idStack = {
    ID: {
      EN: 9,
      ID: 8
    },
    TH: {
      EN: 7,
      TH: 6
    },
    SG: {
      EN: 4,
      ZH: 5
    },
    MY: {
      EN: this.sitePrefix == 'BP9' ? 30 : 1,
      ZH: this.sitePrefix == 'BP9' ? 31 : 2,
      MS: this.sitePrefix == 'BP9' ? 30 : 3
    }
  }

  id: number;

  getId(country: string, language: string) {
    country = country !== null ? country.toUpperCase() : null;
    language = language !== null ? language.toUpperCase() : null;
    if (language === 'EN' && country === 'MY') {
      if (this.sitePrefix == 'BP9') {
        this.id = 30;
      } else {
        this.id = 1;
      }
    } else if (language === 'ZH' && country === 'MY') {
      if (this.sitePrefix == 'BP9') {
        this.id = 31;
      } else {
        this.id = 2;
      }
    } else if (language === 'MS' && country === 'MY') {
      if (this.sitePrefix == 'BP9') {
        this.id = 30;
      } else {
        this.id = 3;
      }
    } else if (language === 'EN' && country === 'SG') {
      this.id = 4;
    } else if (language === 'ZH' && country === 'SG') {
      this.id = 5;
    } else if (language === 'EN' && country === 'ID') {
      this.id = 9;
    } else if (language === 'ID' && country === 'ID') {
      this.id = 8;
    } else if (language === 'TH' && country === 'TH') {
      this.id = 6;
    } else if (language === 'EN' && country === 'TH') {
      this.id = 7;
    }
    return this.id;
  }

}
