// Service
import { ContactListHttpService } from './core/services/contact-list-http.service';
import { SeoService } from '@core/services/seo.service';
import { EmailVerificationHttpService } from './core/services/verify-email-http.service';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { Store, select } from '@ngrx/store';
import { AppState } from './store/reducers';
import { login } from '@core/store/auth/auth.actions';
import { TranslateService } from '@ngx-translate/core';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AnnouncementHttpService } from '@core/services/announcement-http.service';
import { LocationHttpService } from '@core/services/location-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { OneSignalService } from './core/services/one-signal.service';
import { environment } from '@env/environment';
import { SiteDomainService } from '@core/services/site-domain.service';
import { BannerHttpService } from '@core/services/banner-http.service';
import { PredictionHttpService } from '@views/modules/game/services/prediction-http.service';
import { EwalletVerificationHttpService } from '@core/services/ewallet-verification-http.service';
import { BankAccountHttpService } from '@core/services/bank-account-http.service';
import { version } from '../../package.json';
import { EwalletVerification } from '@core/models/ewallet-verification.model';

// Angular
import { Component, OnDestroy, OnInit, Renderer2, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

//Component
import { PopupConnectionErrorComponent } from '@shared/popup-connection-error/popup-connection-error.component';
import { EwalletVerificationApprovalComponent } from '@shared/ewallet-verification-approval/ewallet-verification-approval.component';
import { EwalletVerificationRejectionComponent } from '@shared/ewallet-verification-rejection/ewallet-verification-rejection.component';

// RxJS
import { filter, map, tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

// Third Party
import countryISOMapping from 'country-iso-3-to-2';
import { ISOCountryCode } from '@core/enums/ISO-country-code.enum';
import { LiveChatHttpService } from '@core/services/livechat-http.service';
import { Crisp } from "crisp-sdk-web";
import { ConnectionService, ConnectionState } from 'ng-connection-service';
import { Announcement } from '@core/models/announcement.model';

declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  mode: string = window.mode;
  siteName = (environment.sitePrefix as string).toUpperCase();
  shutdownflag = environment.shutdown;
  pageTitle = '';
  isMobileDetected = false;
  maintenanceDateTime = null;
  currentURL = this.router.url.split('/');
  page = this.currentURL[this.currentURL.length - 1] === '' || window.location.pathname == '/' ? 'home' : this.currentURL[this.currentURL.length - 1];

  announcements$ = [];
  announcementIds = [];
  campaignCode: string;
  affiliateCode: string;
  todayDate = new Date();

  ISOCountryCode = ISOCountryCode;

  showChangeCountryNotice = false;
  countryCode = null;
  hostname = (window.location.hostname).replace(/^([w]{3}.)(?=[a-z0-9]+)/, '');
  countryDomain: string;
  countryCodeToChange: string;

  supportedCountry = this.dropdownHttpService.availableCountryAndLanguages;
  supportedLanguage = this.dropdownHttpService.languages;
  hideAppBaseList = ['/unsupported-country', '/gamelaunch2'];
  hideAppBase = this.hideAppBaseList.includes(window.location.pathname);
  isLoggedIn$: Observable<boolean>;
  isLoggedIn: boolean;
  mainDomainBlocked: Boolean;
  currentDomain: string;
  hiddenDomains = this.dropdownHttpService.vipHiddenDomains;
  verificationData: EwalletVerification = null;
  newRequestIncoming = this.ewalletVerificationHttpService.newRequestIncoming;

  // This dialog location is same as in BO
  // The data is hardcoded from BO and not from API
  dialogLocation = [
    { id:1  , name : 'All' },
    { id:2  , name : 'Home' },
    { id:3  , name : 'Deposit' },
    { id:4  , name : 'Withdraw' },
    { id:5  , name : 'Transfer' },
    { id:6  , name : 'Slots-Game' },
    { id:7  , name : 'Casino-Game' },
    { id:8  , name : 'Sports' },
    { id:9  , name : 'Fishing' },
    { id:10 , name : 'Promotion' },
    { id:11 , name : 'Vip' },
    { id:12 , name : 'Contact' },
    { id:13 , name : 'Slots' },
    { id:14 , name : 'Casino' }
  ];

  // TEMPORARILY
  s3RedirectURL = {
    '/EN50FSD': 'https://aiodin-pro1.s3.ap-southeast-1.amazonaws.com/promotion/#COUNTRY_LANGUAGE+-+50+Free+Spins+-+Deposit.png',
    '/EN50FSND': 'https://aiodin-pro1.s3.ap-southeast-1.amazonaws.com/promotion/#COUNTRY_LANGUAGE+-+50+Free+Spins+-+No+Deposit.png',
    '/ZH50FSD': 'https://aiodin-pro1.s3.ap-southeast-1.amazonaws.com/promotion/#COUNTRY_LANGUAGE+-+50+Free+Spins+-+Deposit.png',
    '/ZH50FSND': 'https://aiodin-pro1.s3.ap-southeast-1.amazonaws.com/promotion/#COUNTRY_LANGUAGE+-+50+Free+Spins+-+No+Deposit.png'
  }
  pathName = '';
  show = false;

  showReminder: boolean = false;
  newLink: string = null;

  connectionState!: ConnectionState;
  connectionSubscription = new Subscription();
  announcementSubscription = new Subscription();

  newPromptIncoming = this.announcementHttpService.newPromptIncoming;
  rewardPrompt: Announcement = null;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private deviceDetectorService: DeviceDetectorService,
    private authService: AuthHttpService,
    private eventEmitterService: EventEmitterService,
    private announcementHttpService: AnnouncementHttpService,
    private datePipe: DatePipe,
    private locationHttpService: LocationHttpService,
    private dropdownHttpService: DropdownHttpService,
    private gameCategoryHttpService: GameCategoryHttpService,
    private emailVerificationHttpService: EmailVerificationHttpService,
    private os: OneSignalService,
    private seoService: SeoService,
    private renderer: Renderer2,
    private contactListHttpService: ContactListHttpService,
    private siteDomainService: SiteDomainService,
    private livechatHttpService: LiveChatHttpService,
    private bannerHttpService: BannerHttpService,
    private predictionHttpService: PredictionHttpService,
    private connectionService: ConnectionService,
    private ewalletVerificationHttpService: EwalletVerificationHttpService,
    private bankAccountHttpService: BankAccountHttpService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
  ) {

    //here to decrypt and set items to localstorage if launch page and got param redirectDomain
    if (window.location.pathname == '/launch') {
      let params = this.authService.getUrlParams(window.location.search);
      let redirectDomain = params['redirectDomain'];
      if (redirectDomain && redirectDomain == '1') {
        localStorage.removeItem('gameCategory');
        localStorage.removeItem('gameCode');
        localStorage.removeItem('gameProviderId');
        localStorage.removeItem('gameProviderCode');
        localStorage.removeItem('isPlayTechClicked');
        localStorage.removeItem('gameType');
        localStorage.removeItem('gameMode');
        localStorage.removeItem('gameDemo');
        localStorage.removeItem('gameProviderDemoMode');
        localStorage.removeItem('lobbyUrl');
        localStorage.removeItem('subCategoryCode');

        const decrypted_userData = params['userData'];
        const decrypted_userToken = params['accessToken'];
        if (decrypted_userData && decrypted_userToken) {
          let user = JSON.parse(decrypted_userData);
          localStorage.setItem('country_code', user['country_code']);
          localStorage.setItem('user_data', decrypted_userData);
          localStorage.setItem('user_token', decrypted_userToken);
          if (params['gameCode']) localStorage.setItem('gameCode', params['gameCode']);
          if (params['gameProviderId']) localStorage.setItem('gameProviderId', params['gameProviderId']);
          if (params['gameProviderCode']) localStorage.setItem('gameProviderCode', params['gameProviderCode']);
          if (params['gameCategory']) localStorage.setItem('gameCategory', params['gameCategory']);
          if (params['gameType']) localStorage.setItem('gameType', params['gameType']);
          if (params['gameMode']) localStorage.setItem('gameMode', params['gameMode']);
          if (params['isPlayTechClicked']) localStorage.setItem('isPlayTechClicked', params['isPlayTechClicked']);
          if (params['gameDemo']) localStorage.setItem('gameDemo', params['gameDemo']);
          if (params['gameProviderDemoMode']) localStorage.setItem('gameProviderDemoMode', params['gameProviderDemoMode']);
          if (params['subCategoryCode']) localStorage.setItem('subCategoryCode', params['subCategoryCode']);
          if (params['lobbyUrl']) localStorage.setItem('lobbyUrl', params['lobbyUrl']);
          if (params['countryCode']) localStorage.setItem('country_code', params['countryCode']);
          if (params['mainDomainUrl']) localStorage.setItem('main_domain_url', params['mainDomainUrl']);
          const userData = localStorage.getItem('user_data');
          const userToken = localStorage.getItem('user_token');
          this.store.dispatch(login({ user: JSON.parse(userData), token: JSON.parse(userToken) }));
        }
      }
    }

    if (environment.production && localStorage.getItem('country_code') === 'SG') {
      document.addEventListener("DOMContentLoaded", () => {
        seoService.setJsonLd();
        seoService.renderer = this.renderer;
      });
    }
    router.events.pipe(
      tap(() => this.authService.getAffiliateAndMarketingParams()),
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event: NavigationEnd) => {
      this.checkVersionNew();
      this.toShow();
      this.getRouteTitle(this.activatedRoute).data
        .pipe(tap(data => {
          const currentPopUpAnnouncement = localStorage.getItem('announcements_pop');
          this.pageTitle = data.title;
          this.updateSeoFooter();
          if (currentPopUpAnnouncement !== null) {
            this.onSwalFire(data.title?.toLowerCase(), currentPopUpAnnouncement);
          }
          this.eventEmitterService.footerPaddingEmiter.emit(500);
        })).subscribe(() => {
          if (window.location.pathname == '/launch') {
            this.titleService.setTitle(this.pageTitle ? this.pageTitle : '');
          }
          else {
            this.titleService.setTitle((this.pageTitle ? this.pageTitle + ' | ' : '') + this.siteName);
          }

          if (!['/casino', '/slots', '/sports', '/fishing', '/esports', '/crash-game', '/table'].includes(event.url)) {
            this.seoService.execute();
          }
        });
    });
    window.onbeforeunload = () => { sessionStorage.removeItem('game_providers_balance') };
  }

  async ngOnInit() {
    this.eventEmitterService.updateAnnouncementEmitter.subscribe(() => {
        this.onGetAnnouncements();
    });
    
    this.newPromptIncoming.subscribe(() => {
      const userData = localStorage.getItem('user_data');
      this.rewardPrompt = AnnouncementHttpService.rewardPrompt;
      if (this.rewardPrompt.updateRewardPrompt == true && JSON.parse(userData).id === this.rewardPrompt.member_account_id) {
        this.eventEmitterService.onUpdateRewardBubble();
        this.onGetAnnouncements()
      }
    })

    if (window.location.pathname !== '/unsupported-country') {
      // dynamic url for landing page
      const redirect_site_domain = JSON.parse(localStorage.getItem('redirect_site_domain')),
        landing_page_domain = redirect_site_domain.find(x => x.type == 2),
        game_launch_domain = redirect_site_domain.find(x => x.type == 3);
      if (game_launch_domain && window.location.href.includes(game_launch_domain['domain'])) {
        if (!(window.location.href.includes('/launch?redirectDomain'))) {
          let url = ((environment.https ? 'https://' : 'http://') + landing_page_domain['domain']);
          window.location.replace(url);
        }
      }

      this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
      this.pathName = window.location.pathname;
      this.removePopUp();
      this.getHostname();

      // Grab and update click count whenever affiliate
      // or marketing campaign param is present from URL
      this.route.queryParams.subscribe((params: Params) => {
        this.campaignCode = params[`campaign`];
        this.affiliateCode = params[`aff`];
        if (this.campaignCode) {
          this.authService.updateCampaignClickCount(this.campaignCode).subscribe();
        }
        if (this.affiliateCode) {
          this.authService.updateAffiliateClickCount(this.affiliateCode).subscribe();
        }
      });

      this.isLoggedIn$.subscribe(res => {
        if (res) {
          this.isLoggedIn = res;
          setTimeout(() => {
            this.checkReminder();
            this.countryCode  = localStorage.getItem('country_code');
            this.onLoginVerificationPop();
            this.os.onInit().subscribe();
          }, 100)
        }
      });

      this.onVerifyEmail();
      // this.browserInit();
      this.landingPage();
      this.ewalletVerificationPop();

      // No need to call these API when in /launch page
      if (window.location.pathname !== '/launch') {
        this.getContactUs();
      }

      // Set timeout to prevent get null value for localStorage variable
      setTimeout(() => {
        if (!this.hiddenDomains.includes(this.hostname)) {
          this.checkLocation();
        }
        this.getAllGameProvider();
      }, 1000)

      const languageCode = localStorage.getItem('language_code');
      const userLocale = localStorage.getItem('user_locale');
      if (languageCode !== null) {
        this.translateService.use((languageCode).toLocaleLowerCase());
      } else {
        if (this.supportedLanguage[userLocale.toUpperCase()] !== undefined) {
          this.translateService.use((userLocale).toLocaleLowerCase());
        };
      }

      const userData = localStorage.getItem('user_data');
      const userToken = localStorage.getItem('user_token');
      if (userData && userToken) {
        this.store.dispatch(login({ user: JSON.parse(userData), token: JSON.parse(userToken) }));
      }

      this.eventEmitterService.metaTagDefault.subscribe(() => {
        if (window.location.pathname == '/launch') {
          this.titleService.setTitle(this.pageTitle ? this.pageTitle : '');
        }
        else {
          this.titleService.setTitle((this.pageTitle ? this.pageTitle + ' | ' : '') + this.siteName);
        }
      })

      if (this.maintenanceDateTime === null && !window.location.href.includes('/landing') && window.location.pathname !== '/launch') {
        this.onGetAnnouncements();
      }

      this.getLiveChatScript();
      this.eventEmitterService.updateCountryAndLanguageEmitter.subscribe(() => {
        localStorage.removeItem('announcements_pop');
        this.getLiveChatScript();
        this.onGetAnnouncements();
        this.getContactUs();
      })

      this.getCleanModeLiveChatScript();
      this.redirectToS3Link();
      this.onCheckConnection();
      this.toShow();
    }
  }

  ngOnDestroy() {
    this.connectionSubscription.unsubscribe();
  }

  onSwalFire(page: string, announcementPop?: any) {
    setTimeout(() => {
      var currentURL = window.location.pathname.replace(';', '').split('/');
      let Current_page = 'home';
  
      if (currentURL && this.currentURL.length > 1) {
        const result = currentURL.find(value => this.dialogLocation.some(item => item.name.toLowerCase() === value.toLowerCase().replace('wallettab=', ''))) || 'home';
        Current_page = result.replace('wallettab=', '').replace('forgot-passwordtab=', '');
      }
  
      this.announcements$ = [];
      this.announcementIds = [];
      if (announcementPop !== undefined) {
        localStorage.removeItem('popup_ids');
        JSON.parse(announcementPop).map(announcement => {
          if ((announcement.location_name === Current_page || announcement.location_name === 'all') 
            && ((announcement.seen === 0 && Current_page !== 'landing' && window.location.pathname !== 'gamelaunch') || (window.location.pathname == '' && Current_page == 'home'))) {
            this.announcements$.push(announcement.content);
            this.announcementIds.push(announcement.id);
          }
        });
        this.cdr.markForCheck();
        localStorage.setItem('popup_ids', JSON.stringify(this.announcementIds));
      }
      
    }, 500);
  }

  onUpdateAnnouncement() {
    let isSeen = 0;
    const announcementPop = [];

    JSON.parse(localStorage.getItem('announcements_pop')).forEach(announcement => {
      this.announcementIds.includes(announcement.id) ? isSeen = 1 : isSeen = 0;

      announcementPop.push({
        ...announcement,
        seen: announcement.seen == 1 ? 1 : isSeen,
      });
    });

    localStorage.setItem('announcements_pop', JSON.stringify(announcementPop));
  }

  private toShow() {
    if (this.router.url === '/landing' || this.router.url.includes('landing')) {
      this.show = false;
    } else {
      this.show = true;
    }
  }

  onGetAnnouncements() {
    const dateToday = this.datePipe.transform(this.todayDate, 'yyyy-MM-dd');

    if (localStorage.getItem('date_today') !== dateToday) {
      localStorage.setItem('date_today', dateToday);
      localStorage.removeItem('announcements_pop');
      localStorage.removeItem('marquees');
    }

    this.route.queryParams.subscribe(async (params: Params) => {
      let dialogCode = null;
      if ((params['campaign'] != undefined || params['aff'] != undefined) && params['dialog']) {
        localStorage.setItem('dialog', params['dialog']);
        window.history.replaceState({},'',`${this.router.url.replace('?dialog=' + params['dialog'], '').replace('&dialog=' + params['dialog'], '')}`);
      }
      else if (params['campaign'] == undefined && params['aff'] == undefined) {
        if (params['dialog']) {
          dialogCode = params['dialog']
          localStorage.setItem('dialog', params['dialog']);
          window.history.replaceState({},'',`${this.router.url.replace('?dialog=' + dialogCode, '').replace('&dialog=' + dialogCode, '')}`);
        }
        else {
          dialogCode = localStorage.getItem('dialog');
        }
      }

      this.announcementSubscription.unsubscribe();
      this.announcementSubscription = this.announcementHttpService.getPopUp(dialogCode).subscribe(res => {
        if (dialogCode == null) {
          let announcementPopList = JSON.parse(localStorage.getItem('announcements_pop'));
          let announcementPopCodes = res.map(function(i) {
            return i['code'];
          });
          if (announcementPopCodes.length > 0 && announcementPopList != null) {
            announcementPopList = announcementPopList.filter(x => announcementPopCodes.includes(x.code));
            localStorage.setItem('announcements_pop', JSON.stringify(announcementPopList));
          }
        }

        const announcementPop = [];
        res.map((announcement: any) => {
          if (res.length > 0) {
            announcement.content[0].session = announcement.session;
            // announcement.platform : 1 = All , 2 == Mobile , 3 == Desktop
            if (announcement.platform == 1 || announcement.platform == 3) {
              // announcement.session :  1 = All , 2 == Before login , 3 == after login
              if (localStorage.getItem('user_data') != null) {
                if (announcement.session == 1 || announcement.session == 3) {
                  let seenStatus = 0;
                  if (localStorage.getItem('announcements_pop') !== undefined && localStorage.getItem('announcements_pop') !== null) {
                    let announcementList = JSON.parse(localStorage.getItem('announcements_pop'))
                    let announcementListFilter = announcementList.filter(x => x.id == announcement.id)
                    if (announcementListFilter.length >0) {
                      if (announcementListFilter[0].always_pop == 0) {
                        announcementList = announcementList.filter(x => x.id != announcement.id)
                        localStorage.setItem('announcements_pop', JSON.stringify(announcementList))
                      } else {
                        seenStatus = announcementListFilter[0].seen == 1 ? 1 : 0;
                      }
                    }
                  } 
                  announcementPop.push({
                    content: announcement.content,
                    id: announcement.id,
                    code: announcement.code,
                    location_name: announcement.location_name,
                    seen: seenStatus,
                    settings_locale_id: announcement.settings_locale_id,
                    always_pop: announcement.always_pop,
                  });
                }
              } else {
                if (announcement.session == 1 || announcement.session == 2) {
                  let seenStatus = 0;
                  if (localStorage.getItem('announcements_pop') !== undefined && localStorage.getItem('announcements_pop') !== null) {
                    let announcementList = JSON.parse(localStorage.getItem('announcements_pop'))
                    let announcementListFilter = announcementList.filter(x => x.id == announcement.id)
                    if (announcementListFilter.length >0) {
                      if (announcementListFilter[0].always_pop == 0) {
                        announcementList = announcementList.filter(x => x.id != announcement.id)
                        localStorage.setItem('announcements_pop', JSON.stringify(announcementList))
                      } else {
                        seenStatus = announcementListFilter[0].seen == 1 ? 1 : 0;
                      }
                    }
                  } 
                  announcementPop.push({
                    content: announcement.content,
                    id: announcement.id,
                    code: announcement.code,
                    location_name: announcement.location_name,
                    seen: seenStatus,
                    settings_locale_id: announcement.settings_locale_id,
                    always_pop: announcement.always_pop,
                  });
                }
              }
            }
          }
        });

        if (announcementPop.length > 0) {
          const popUpAnnouncementsFromLocal = localStorage.getItem('announcements_pop');
          if (popUpAnnouncementsFromLocal === null || JSON.parse(popUpAnnouncementsFromLocal).length === 0) {
            if (dialogCode) {
              announcementPop.forEach(function (item, i) {
                if (item['code'] == dialogCode) {
                  announcementPop.splice(i, 1);
                  announcementPop.unshift(item);
                }
              });
            }
            localStorage.setItem('announcements_pop', JSON.stringify(announcementPop));
            this.eventEmitterService.onSwalAnnouncementEmitter(this.page);
            this.onSwalFire(this.page, JSON.stringify(announcementPop));
          } else {
            if (JSON.stringify(announcementPop) !== JSON.stringify(this.setOldAnnouncements(popUpAnnouncementsFromLocal))) { // if the announcement list update on BO this would update the list on storage
              const updatedPopUpAnnouncements = this.mergeAllAnnouncements(announcementPop.concat(JSON.parse(popUpAnnouncementsFromLocal)));
              const finalPopUpAnnouncement = this.setFinalAnnouncement(updatedPopUpAnnouncements);
              if (dialogCode) {
                finalPopUpAnnouncement.forEach(function (item, i) {
                  if (item['code'] == dialogCode) {
                    finalPopUpAnnouncement.splice(i, 1);
                    finalPopUpAnnouncement.unshift(item);
                  }
                });
              }
              localStorage.setItem('announcements_pop', JSON.stringify(finalPopUpAnnouncement));
              this.onSwalFire(this.page, JSON.stringify(finalPopUpAnnouncement));
            } else {
              this.onSwalFire(this.page ,popUpAnnouncementsFromLocal);
            }
          }
        } else {
          localStorage.removeItem('announcements_pop');
        }
      });
    });

    const marqueeslist = [];
    this.announcementHttpService.getMarquees().subscribe(res => {
      res.map((marquees: any) => {
        if (res.length > 0) {
          // here is the login marquees
          if (localStorage.getItem('user_data') != null) {
            if (marquees.session == 1 || marquees.session == 3) {
              marqueeslist.push({
                contents: marquees.contents,
                id: marquees.id,
                position: marquees.position
              });
            }
          } else {
            if (marquees.session == 1 || marquees.session == 2) {
              marqueeslist.push({
                contents: marquees.contents,
                id: marquees.id,
                position: marquees.position
              });
            }
          }
        }
      });

      if (marqueeslist.length > 0) {
        const marqueesFromLocal = localStorage.getItem('marquees');
        if (marqueesFromLocal === null || JSON.parse(marqueesFromLocal).length === 0) {
          localStorage.setItem('marquees', JSON.stringify(marqueeslist));
        } else {
          if (JSON.stringify(marqueeslist) !== JSON.stringify(JSON.parse(marqueesFromLocal))) { // if the announcement list update on BO this would update the list on storage
            localStorage.setItem('marquees', JSON.stringify(marqueeslist));
          }
        }
      } else {
        localStorage.removeItem('marquees');
      }

      this.eventEmitterService.onMarqueesEmitter();
    });
  }

  ewalletVerificationPop() {
    this.newRequestIncoming.subscribe(() => {
      this.verificationData = EwalletVerificationHttpService.verification;
      if (this.verificationData) {
        if (this.verificationData.status == 1) {
          this.onOpenVerificationDialog(EwalletVerificationApprovalComponent, this.verificationData, false);
        } else {
          this.onOpenVerificationDialog(EwalletVerificationRejectionComponent, this.verificationData, false);
        }
      }
    })
  }

  onLoginVerificationPop() {
    this.bankAccountHttpService.getUnseenVerificationRecords().pipe(
      tap(res => {
        if (res.length > 0) {
          res.forEach((item) => {
            if (item.verification_settings_field.length > 0 ) {
              if (item.status == 1) {
                this.onOpenVerificationDialog(EwalletVerificationApprovalComponent, item, true);
              } else {
                this.onOpenVerificationDialog(EwalletVerificationRejectionComponent, item, true);
              }
            }
          })
        }
      })
    ).subscribe();
  }

  onOpenVerificationDialog(componentRef: any, verificationData: any, loginPop: boolean) {
    var bank_id = loginPop == true ? verificationData.id : verificationData.member_bank_account_id;
    var dialogRef = this.dialog.open(componentRef, {
      width: 'auto',
      data: {
        bankAccountId: bank_id,
        bank_name: verificationData.bank_name,
        account_number: verificationData.account_number,
        reason: verificationData.reason,
        verificationSetting: verificationData
      } 
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.eventEmitterService.onUpdateBankList();
      this.eventEmitterService.onReloadBankList();
      if (verificationData.status == 2) {
        this.eventEmitterService.onUpdateVerificationEmitter();
      }
      this.bankAccountHttpService.updateVerificationSeen(bank_id).pipe().subscribe();
    });
  }

  onCheckConnection() {
    this.connectionSubscription.add(
      this.connectionService.monitor().pipe(
        tap((newState: ConnectionState) => {
          this.connectionState = newState;
          if (!this.connectionState.hasNetworkConnection) {
            this.dialog.open(PopupConnectionErrorComponent, { width: 'auto' });
          } else {
            this.dialog.closeAll();
          }
        })
      ).subscribe()
    );
  }

  private checkVersionNew() {
    //const currentVersion = localStorage.getItem('version');
    localStorage.setItem('version', version);
    // if ((currentVersion != null && version != currentVersion) || currentVersion == null) {
    //   localStorage.clear();
    //   sessionStorage.clear();
    //   localStorage.setItem('version', version);
    //   if (window.location.pathname !== '/') {
    //     window.location.href = '/';
    //   }
    // }
  }
  
  private getContactUs() {
    this.contactListHttpService.getContactList().subscribe(res => {
      sessionStorage.setItem('contact_us', JSON.stringify(res))
      this.eventEmitterService.onUpdateContactUs();
    });
  }

  private updateSeoFooter() {
    const providerPages = ['Slots', 'Casino', 'Sports', 'Esports'];
    if (!providerPages.includes(this.pageTitle)) {
      if (window.location.pathname === '/') {
        this.eventEmitterService.onUpdateSeoFooter('main');
      } else {
        this.eventEmitterService.onUpdateSeoFooter('');
      }
    }
  }

  private onVerifyEmail() {
    if (window.location.pathname === '/verify-email') {
      if (window.location.search === '') {
        this.router.navigate(['/']);
      } else {
        if (window.location.search.split('?token=')[1] === '') {
          this.router.navigate(['/']);
        } else {
          this.emailVerificationHttpService.verify({ token: window.location.search.split('?token=')[1] }).pipe(
            tap((res: any) => {
              localStorage.setItem('verify_email_message', JSON.stringify(res));
              this.afterOnVerification();
            })
          ).subscribe();
        }
      }
    }
  }

  private setFinalAnnouncement(updatedPopUpAnnouncements: any) { // set the final announcement before push to localstorage
    const finalPopUp = [];
    updatedPopUpAnnouncements.map((result: any) => {
      finalPopUp.push({
        ...result,
        content: result.content[0],
        location_name: result.location_name[0],
        seen: (result.settings_locale_id[0] !== result.settings_locale_id[1] || result.location_name[0] !== result.location_name[1]
          || result.content[0] !== result.content[1]) && (result.seen[0] !== result.seen[1]) ? result.seen[0] : result.seen[1],
        settings_locale_id: result.settings_locale_id[0]
      });
    });
    return finalPopUp;
  }

  private setOldAnnouncements(announcements: any) { // update localstorage seen to 0
    const popUp = [];
    JSON.parse(announcements).map(res => {
      popUp.push({
        ...res,
        seen: 0
      });
    });
    return popUp;
  }

  private mergeAllAnnouncements(allAnnouncement: any) { // merge all data but unique id
    const updatedPopUpAnnouncements = [];
    allAnnouncement.map(allAnn => {
      const existing = updatedPopUpAnnouncements.filter((v, i) => {
        return v.id === allAnn.id;
      });
      if (existing.length) {
        const existingIndex = updatedPopUpAnnouncements.indexOf(existing[0]);
        updatedPopUpAnnouncements[existingIndex].content = updatedPopUpAnnouncements[existingIndex].content.concat(allAnn.content);
        updatedPopUpAnnouncements[existingIndex].location_name = updatedPopUpAnnouncements[existingIndex].location_name.concat(allAnn.location_name);
        updatedPopUpAnnouncements[existingIndex].seen = updatedPopUpAnnouncements[existingIndex].seen.concat(allAnn.seen);
        updatedPopUpAnnouncements[existingIndex].settings_locale_id = updatedPopUpAnnouncements[existingIndex].settings_locale_id.concat(allAnn.settings_locale_id);
      } else {
        allAnn.content = [allAnn.content];
        allAnn.location_name = [allAnn.location_name];
        allAnn.seen = [allAnn.seen];
        allAnn.settings_locale_id = [allAnn.settings_locale_id];
        updatedPopUpAnnouncements.push(allAnn);
      }
    });
    return updatedPopUpAnnouncements;
  }

  private browserInit() {
    try {
      const languages = this.supportedCountry[this.currentDomain][localStorage.getItem('country_code')]['languages'];
      if (!languages.includes(localStorage.getItem('language_code').toUpperCase())) {
        localStorage.setItem('language_code', languages[0])
        this.translateService.use(languages[0].toLocaleLowerCase());
      }
    } catch (e) { }

    if (this.deviceDetectorService.isMobile()) {
      let mobilePreExt = null;
      let fullDomain = '';

      // Get the mobile pre extension for the country
      try {
        Object.keys(this.supportedCountry[this.currentDomain]).forEach(key => {
          if (this.supportedCountry[this.currentDomain][key][environment.domainEnv].includes(this.hostname)) {
            mobilePreExt = this.supportedCountry[this.currentDomain][key]['mobilePreExt'][environment.domainEnv];
            throw {};
          }
        })
      } catch (e) { }

      // Append full domain
      if (mobilePreExt === null) {
        fullDomain = (environment.https ? 'https://' : 'http://') + environment.mobileDomainName;
      } else {
        fullDomain = (environment.https ? 'https://' : 'http://') + mobilePreExt + this.hostname;
      }

      this.isMobileDetected = true;
      const marketingParams = this.authService.getUrlParams(window.location.search);
      let authSuffix = '';
      let crossLoginParam = '';
      let countryLanguageParam = '';
      // Get marketing params without pathname while it's exist
      if (Object.keys(marketingParams)) {
        authSuffix = `${(window.location.pathname)}?${Object.keys(marketingParams).map(key => key + '=' + marketingParams[key]).join('&')}`;
      }
      // Begin:: Cross device login
      if (!(window.location.pathname).startsWith('/login') && localStorage.getItem('user_token') && localStorage.getItem('user_data')) {
        crossLoginParam = (authSuffix === '' ? '?' : '&') + `userToken=${localStorage.getItem('user_token')}&userData=${localStorage.getItem('user_data')}`;
      }
      // End:: Cross device login
      countryLanguageParam = ((authSuffix.includes('?') || crossLoginParam.includes('?')) ? '&lang=' : '?lang=') + ((localStorage.getItem('country_code') ?? 'MY') + '_' + (localStorage.getItem('language_code') ?? 'EN'))
      window.location.href = fullDomain + authSuffix + crossLoginParam + countryLanguageParam;
    }
  }

  private getRouteTitle(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getRouteTitle(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  private removePopUp() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('lang') !== null) {
      const languageAndCountry = urlParams.get('lang').split('_');
      if (!languageAndCountry[1].includes('tps://')) {
        if (localStorage.getItem('country_code') !== languageAndCountry[1]) {
          localStorage.removeItem('announcements_pop')
        }
      }
    }
  }

  // This function is to check whether should navigate to landing page else set country&language code to localStorage
  private landingPage() {
    let countryCode = localStorage.getItem('country_code') ? localStorage.getItem('country_code') : null;

    Object.keys(this.supportedCountry[this.currentDomain]).forEach(key => {
      this.supportedCountry[this.currentDomain][key][environment.domainEnv].forEach(domain => {
        if ((this.supportedCountry[this.currentDomain][key]['desktopPreExt'][environment.domainEnv] + domain) == window.location.hostname) {
          countryCode = key;
        }
      });
      // if (this.supportedCountry[this.currentDomain][key][environment.domainEnv].includes(this.hostname)) {
      //   countryCode = key;
      // }
    });

    // Go to landing page
    if (
      this.hostname !== 'localhost' &&
      (environment.production && !this.hiddenDomains.includes(this.hostname)) &&
      (
        countryCode === null ||
        environment.domainName.split('|').includes(this.hostname) ||
        environment.altDomainName.split('|').includes(this.hostname) ||
        environment.altDomainNameTwo.split('|').includes(this.hostname)
      )
    ) {
      this.router.navigateByUrl('/landing');
    }
    // Not going to landing page
    else {

      const urlParams = new URLSearchParams(window.location.search);
      // If got param, this param is pass from landing page
      if (urlParams.get('lang') !== null) {
        // Eg. MY_EN
        const countryAndLanguage = urlParams.get('lang').split('_');
        localStorage.setItem('country_code', countryAndLanguage[0]);
        localStorage.setItem('language_code', countryAndLanguage[1]);
        this.translateService.use(countryAndLanguage[1].toLocaleLowerCase());
      }
      // No param
      else {
        // Production & demo env
        if (environment.production || environment.domainEnv == 'demo') {
          // MY_EN by default for VIP Hidden Domains
          if (this.hiddenDomains.includes(this.hostname)) {
            localStorage.getItem('country_code') === null ? localStorage.setItem('country_code', "MY") : '';   // Set to default value if null
            if (localStorage.getItem('language_code') === null) {
              localStorage.setItem('language_code', "EN"); // Set to default value if null
              this.translateService.use('en');
            }
          } else {
            localStorage.getItem('country_code') === null ? localStorage.setItem('country_code', countryCode) : '';   // Set to default value if null
            let languageCode = countryCode === 'ID' ? 'ID' : 'EN'; // EN by default for all country except for Indonesia

            if (localStorage.getItem('language_code') === null) {
              localStorage.setItem('language_code', languageCode);
              this.translateService.use(languageCode.toLocaleLowerCase());
            }
          }
        }

        // LocalHost or Staging
        else if (this.hostname === 'localhost' || !environment.production) {
          localStorage.getItem('country_code') === null ? localStorage.setItem('country_code', "MY") : '';   // Set to default value if null
          if (localStorage.getItem('language_code') === null) {
            localStorage.setItem('language_code', "EN"); // Set to default value if null
            this.translateService.use('en');
          }
        }
      }
      this.countryCode = localStorage.getItem('country_code');
      // Auto login part
      if (urlParams.get('userToken')) {
        const userToken = urlParams.get('userToken');
        const userData = urlParams.get('userData');
        localStorage.setItem('user_token', userToken);
        localStorage.setItem('user_data', userData);
        const newURL = location.href.split('?')[0];
        window.history.pushState('object', document.title, newURL);
      }

      this.eventEmitterService.onFlagEmitter();
    }
  }

  // private getLiveChatScript() {
  //   var language = localStorage.getItem('language_code');
  //   var country = localStorage.getItem('country_code');

  //   this.livechatHttpService.getLiveChatScript(`?language=${language}&country=${country}`).subscribe(res => {
  //     if (this.livechatHttpService.website !== null && this.livechatHttpService.website !== '') {
  //       this.isLoggedIn$.subscribe(login => {
  //         var website = this.livechatHttpService.website;

  //         // Check live chat <script> is it exists.
  //         var liveChatScriptID = "live-chat-script";
  //         var scriptIndex = website.indexOf('<script') + '<script'.length;
  //         website = website.slice(0, scriptIndex) + ' id="' + liveChatScriptID + '" ' + website.slice(scriptIndex, website.length);
  //         var scriptExists = (document.getElementById(liveChatScriptID) !== null);

  //         if (this.livechatHttpService.live_chat_platform_name == 'Live Chat') {
  //           // Remove old live chat <script> if already exists.
  //           if (scriptExists) {
  //             var scriptElement = document.getElementById(liveChatScriptID);
  //             if (scriptElement != null) {
  //               scriptElement.parentNode.removeChild(scriptElement);
  //             }
  //           }

  //           website = website.replace(/(['])/g, "\\$1");
  //           var index = website.indexOf('window.__lc.license');
  //           var username = "  ";
  //           var email = "  ";

  //           if (login) {
  //             if (localStorage.getItem('user_data') != null) {
  //               if (JSON.parse(localStorage.getItem('user_data')).username != null && JSON.parse(localStorage.getItem('user_data')).username !== '') {
  //                 username = JSON.parse(localStorage.getItem('user_data')).username;
  //               }
  //               if (JSON.parse(localStorage.getItem('user_data')).email != null && JSON.parse(localStorage.getItem('user_data')).email !== '') {
  //                 email = JSON.parse(localStorage.getItem('user_data')).email;
  //               }
  //             }
  //             if(index > 0) {
  //               website = website.slice(0, index) + "window.__lc.visitor = {name: '"+ username +"',email: '"+ email +"'};" + website.slice(index, website.length);
  //             }
  //           }
  //           else{
  //             if(index > 0) {
  //               website = website.slice(0, index) + website.slice(index, website.length);
  //             }
  //           }
  //           $(website).appendTo(document.body);

  //         } else if (this.livechatHttpService.live_chat_platform_name == 'Crisp') {

  //           // Crisp no need to remove and assign again due to after assign it require refresh only can work.
  //           if (!scriptExists || typeof window.$crisp === "undefined") {
  //             $(website).appendTo(document.body);
  //             var indexStart = website.indexOf('window.CRISP_WEBSITE_ID=')  + 'window.CRISP_WEBSITE_ID='.length;
  //             var indexEnd = website.indexOf(';(function(){d=document');
  //             var websiteID = website.slice(indexStart, indexEnd).replace(/\\/g, "").replace(/\"/g, "");

  //             Crisp.configure(websiteID);
  //           }

  //           if (login) {
  //             if (localStorage.getItem('user_data') != null) {
  //               if (JSON.parse(localStorage.getItem('user_data')).username != null && JSON.parse(localStorage.getItem('user_data')).username !== '') {
  //                 Crisp.user.setNickname(JSON.parse(localStorage.getItem('user_data')).username);
  //               }
  //               if (JSON.parse(localStorage.getItem('user_data')).email != null && JSON.parse(localStorage.getItem('user_data')).email !== '') {
  //                 Crisp.user.setEmail(JSON.parse(localStorage.getItem('user_data')).email);
  //               }
  //             }
  //           }

  //         }
  //       });
  //     }
  //   });
  // }

  private getLiveChatScript() {
    var language = localStorage.getItem('language_code');
    var country = localStorage.getItem('country_code');

    var liveChatScriptID = "live-chat-script";
    var scriptExists = (document.getElementById(liveChatScriptID) !== null);
    // if (scriptExists) {
    //   var scriptElement = document.getElementById(liveChatScriptID);
    //   if (scriptElement != null) {
    //     scriptElement.parentNode.removeChild(scriptElement);
    //     document.getElementById("chat-widget-container").parentNode.removeChild(document.getElementById("chat-widget-container"));
    //   }
    // }

    this.livechatHttpService.getLiveChatScript(`?language=${language}&country=${country}`).subscribe(res => {
      if (this.livechatHttpService.website !== null && this.livechatHttpService.website !== '') {
        this.isLoggedIn$.subscribe(login => {
          var website = this.livechatHttpService.website;

          // Check live chat <script> is it exists.
          var liveChatScriptID = "live-chat-script";
          var scriptIndex = website.indexOf('<script') + '<script'.length;
          website = website.slice(0, scriptIndex) + ' id="' + liveChatScriptID + '" ' + website.slice(scriptIndex, website.length);
          var scriptExists = (document.getElementById(liveChatScriptID) !== null);

          if (this.livechatHttpService.live_chat_platform_name == 'Live Chat') {
            // Remove old live chat <script> if already exists.
            if (scriptExists) {
              var scriptElement = document.getElementById(liveChatScriptID);
              if (scriptElement != null) {
                scriptElement.parentNode.removeChild(scriptElement);
              }
            }

            website = website.replace(/(['])/g, "\\$1");
            var index = website.indexOf('window.__lc.license');
            var username = "  ";
            var email = "  ";

            if (login) {
              if (localStorage.getItem('user_data') != null) {
                if (JSON.parse(localStorage.getItem('user_data')).username != null && JSON.parse(localStorage.getItem('user_data')).username !== '') {
                  username = JSON.parse(localStorage.getItem('user_data')).username;
                }
                if (JSON.parse(localStorage.getItem('user_data')).email != null && JSON.parse(localStorage.getItem('user_data')).email !== '') {
                  email = JSON.parse(localStorage.getItem('user_data')).email;
                }
              }
              if (index > 0) {
                website = website.slice(0, index) + "window.__lc.visitor = {name: '" + username + "',email: '" + email + "'};" + website.slice(index, website.length);
              }
            }
            else {
              if (index > 0) {
                website = website.slice(0, index) + website.slice(index, website.length);
              }
            }
            $(website).appendTo(document.body);

          } else if (this.livechatHttpService.live_chat_platform_name == 'Crisp') {

            // Crisp no need to remove and assign again due to after assign it require refresh only can work.
            if (!scriptExists || typeof window.$crisp === "undefined") {
              $(website).appendTo(document.body);
              var indexStart = website.indexOf('window.CRISP_WEBSITE_ID=') + 'window.CRISP_WEBSITE_ID='.length;
              var indexEnd = website.indexOf(';(function(){d=document');
              var websiteID = website.slice(indexStart, indexEnd).replace(/\\/g, "").replace(/\"/g, "");

              Crisp.configure(websiteID);
            }

            if (login) {
              if (localStorage.getItem('user_data') != null) {
                if (JSON.parse(localStorage.getItem('user_data')).username != null && JSON.parse(localStorage.getItem('user_data')).username !== '') {
                  Crisp.user.setNickname(JSON.parse(localStorage.getItem('user_data')).username);
                }
                if (JSON.parse(localStorage.getItem('user_data')).email != null && JSON.parse(localStorage.getItem('user_data')).email !== '') {
                  Crisp.user.setEmail(JSON.parse(localStorage.getItem('user_data')).email);
                }
              }
            }

          }
        });
      } else {
        // Remove old live chat <script> if already exists.
        if (scriptExists) {
          var scriptElement = document.getElementById(liveChatScriptID);
          if (scriptElement != null) {
            scriptElement.parentNode.removeChild(scriptElement);
            document.getElementById("chat-widget-container").parentNode.removeChild(document.getElementById("chat-widget-container"));
          }
          //this.livechatHttpService.clearCookies();
        }
      }
    });
  }

  private getCleanModeLiveChatScript() {
    if (this.mode !== 'Members Only' || this.isUserLoggedIn()) {
      return;
    }

    const language = localStorage.getItem('language_code');
    const country = localStorage.getItem('country_code');
    const countryLang = `${country}_${language}`;
    const groupIds = {
      MY_EN: 54,
      MY_ZH: 55,
      SG_EN: 56,
      SG_ZH: 57
    };

    const chatParentElem = document.createElement('div');
    chatParentElem.id = "clean-mode-live-chat-widget";
    const script = document.createElement('script');
    const noscript = document.createElement('noscript');
    script.innerHTML = `
      window.__lc = window.__lc || {};
      window.__lc.license = 10515072;
      window.__lc.group = ${groupIds[countryLang] || 54};
      ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
    `;
    noscript.innerHTML = '<a href="https://www.livechat.com/chat-with/10515072/" rel="nofollow">Chat with us</a>, powered by <a href="https://www.livechat.com/?welcome" rel="noopener nofollow" target="_blank">LiveChat</a>';
    chatParentElem.appendChild(script);
    chatParentElem.appendChild(noscript);
    document.body.append(chatParentElem);
  }

  private async checkLocation() {
    const pathname = window.location.pathname,
      countryCode = localStorage.getItem('country_code');

    if (
      window.mode != 'Members Only' &&
      (environment.production || environment.domainEnv == 'demo') &&
      (countryCode in this.supportedCountry[this.currentDomain]) &&
      pathname !== '/landing' &&
      this.hostname !== 'localhost' &&
      !environment.domainName.split('|').includes(this.hostname) &&
      !environment.altDomainName.split('|').includes(this.hostname) &&
      !environment.altDomainNameTwo.split('|').includes(this.hostname) &&
      this.supportedCountry[this.currentDomain][countryCode][environment.domainEnv].includes(this.hostname)
    ) {
      // THIS PART COMMENTED BECAUSE DONT WANT TO USE COORDINATES (GEO-LOCATION) TO GET USER LOCATION, geoLocationError FUNCTION IS USE IP ADDRESS TO GET USER LOCATION
      // if (navigator.geolocation) {
      //   const position = await this.getCoordinates();
      //   if (position['coords']) {
      //     this.geoLocationSuccess(position);
      //   } else {
      //     this.geoLocationError();
      //   }
      // } else {
      this.geoLocationError();
      // }
    } else if (
      (environment.production || environment.domainEnv == 'demo') &&
      pathname !== '/landing' &&
      !environment.domainName.split('|').includes(this.hostname) &&
      !environment.altDomainName.split('|').includes(this.hostname) &&
      !environment.altDomainNameTwo.split('|').includes(this.hostname)
    ) {
      this.isLoggedIn$.subscribe(res => {
        if (!JSON.stringify(this.supportedCountry[this.currentDomain]).includes('"' + this.hostname + '"') && !res) {
          this.alertCountryNotSupported();
        }
      });
    }
  }

  private getCoordinates() {
    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, resolve, { timeout: 5000 });
    });
  }

  private geoLocationSuccess(position) {
    const getCountryISO2 = countryISOMapping;
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    this.locationHttpService.getByCoordinates(longitude, latitude).subscribe(res => {
      const iso2CountryCode = getCountryISO2(res.CountryCode)
      if (iso2CountryCode != localStorage.getItem('country_code') && (iso2CountryCode in this.supportedCountry[this.currentDomain])) {
        this.showChangeCountryNotice = true;
        this.countryCodeToChange = iso2CountryCode;
      }
    })
  }

  private geoLocationError() {
    this.locationHttpService.getIpAddress().subscribe(res => {
      if (res['country_code']) {
        this.geolocationChange(res['country_code'].toUpperCase());
      } else {
        const xhr = new XMLHttpRequest();
        const url = `https://geolocation-db.com/json/${res['ip']}`;
        xhr.open('GET', url);
        xhr.send();
        xhr.onloadend = () => {
          if (xhr.response) {
            const address = JSON.parse(xhr.response);
            this.geolocationChange(address.country_code.toUpperCase());
          }
        }
      }
    })
  }

  private geolocationChange(country_code: string) {
    if (country_code != localStorage.getItem('country_code') && country_code in this.supportedCountry[this.currentDomain]) {
      this.showChangeCountryNotice = true;
      this.countryCodeToChange = country_code;
    }
  }

  private afterOnVerification() {
    this.isLoggedIn$.subscribe(res => {
      if (res) {
        this.router.navigate(['/member/profile']);
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  onChangeCountrySite() {
    var urlParams = new URLSearchParams(window.location.search), lang: string = this.countryCodeToChange.toUpperCase() + '_';
    urlParams.delete('lang');
    if (this.countryCodeToChange.toUpperCase() == 'ID') {
      lang += 'ID';
    } else if (this.countryCodeToChange.toUpperCase() == 'TH') {
      lang += 'TH';
    } else {
      lang += this.supportedCountry[this.currentDomain][this.countryCodeToChange.toUpperCase()]['languages'][0];
    }
    urlParams.append('lang', lang);
    window.location.assign((environment.https ? 'https://' : 'http://') + window.location.hostname + window.location.pathname + '?' + urlParams.toString());
  }

  private alertCountryNotSupported() {
    var url: string = null;
    if (this.mainDomainBlocked) {
      Object.keys(this.supportedCountry).forEach((key, index) => {
        if (key.includes(this.currentDomain)) {
          if (index == 1) {
            url = (environment.https ? 'https://' : 'http://') + environment.altDomainName.split('|')[0];
          } else {
            url = (environment.https ? 'https://' : 'http://') + environment.altDomainNameTwo.split('|')[0];
          }
        }
      })
    } else {
      url = (environment.https ? 'https://' : 'http://') + environment.domainName;
    }
    // Direct redirect to landing page
    window.location.assign(url + '/landing');
  }

  private redirectToS3Link() {
    if (this.s3RedirectURL[this.pathName]) {
      var redirectURL = this.s3RedirectURL[this.pathName] + '';
      var countryCode = localStorage.getItem('country_code');
      var languageCode = this.pathName.substr(1, 2);
      redirectURL = redirectURL.replace('#COUNTRY_LANGUAGE', countryCode + '_' + languageCode);
      window.location.href = redirectURL;
    }
  }

  private getHostname() {
    // E.g: localhost.com
    // Get localhost.com
    if (this.hostname.includes('-vip.jk188.net')) {
      this.hostname = this.hostname.substr(this.hostname.indexOf('.') - 4);
    } else {
      if (this.hostname.split('.').length > 2) {
        this.hostname = this.hostname.substr(this.hostname.indexOf('.') + 1);
      }
    }

    // Main
    if (
      JSON.stringify(this.supportedCountry[environment.domainName]).includes('"' + this.hostname + '"') ||
      environment.domainName.split('|').includes(this.hostname)
    ) {
      sessionStorage.removeItem('mainDomainBlocked');
      this.mainDomainBlocked = false;
      this.currentDomain = environment.domainName;
    }
    // First Alternative
    else if (
      JSON.stringify(this.supportedCountry[environment.altDomainName]).includes('"' + this.hostname + '"') ||
      environment.altDomainName.split('|').includes(this.hostname)
    ) {
      sessionStorage.setItem('mainDomainBlocked', 'true');
      this.mainDomainBlocked = true;
      this.currentDomain = environment.altDomainName;
    }
    // Second Alternative
    else if (
      JSON.stringify(this.supportedCountry[environment.altDomainNameTwo]).includes('"' + this.hostname + '"') ||
      environment.altDomainNameTwo.split('|').includes(this.hostname)
    ) {
      sessionStorage.setItem('mainDomainBlocked', 'true');
      this.mainDomainBlocked = true;
      this.currentDomain = environment.altDomainNameTwo;
    } else {
      sessionStorage.removeItem('mainDomainBlocked');
      this.mainDomainBlocked = false;
      this.currentDomain = environment.domainName;
    }
  }

  private getAllGameProvider() {
    if (localStorage.getItem('available_providers') == null) {
      this.gameCategoryHttpService.getAllGameProvider().subscribe(() => { this.eventEmitterService.onTopBarTabEmitter() });
    }
  }

  private checkReminder() {
    if (!this.shutdownflag && environment.sitePrefix == 'bp9') {
      this.newLink = window.location.hostname;//this.supportedCountry[this.currentDomain][localStorage.getItem('country_code')][environment.domainEnv][0];
      let doNotShowAgain = localStorage.getItem('showReminder');
      if (this.mode != 'Members Only' && (doNotShowAgain == null || doNotShowAgain == 'true')) {
        this.showReminder = true;
        localStorage.setItem('showReminder', 'true');
      } else {
        this.showReminder = false;
      }
    }
  }

  onDoNotShowAgain() {
    localStorage.setItem('showReminder', 'false');
    this.showReminder = false;
  }

  onGetBanner() {
    const dateToday = this.datePipe.transform(this.todayDate, 'yyyy-MM-dd');

    if (localStorage.getItem('date_today') !== dateToday) {
      localStorage.setItem('date_today', dateToday);
      localStorage.removeItem('banners');
    }

    const bannerslist = [];
    this.bannerHttpService.getAllBanner().subscribe(res => {
      res.map((banners: any) => {
        if (res.length > 0) {
          // After Login
          if (localStorage.getItem('user_data') != null) {
            if (banners.images[0].image_desktop !== null && (banners.session == 1 || banners.session == 3)) {
              bannerslist.push(banners);
            }
          } else {
            if (banners.images[0].image_desktop !== null && (banners.session == 1 || banners.session == 2)) {
              bannerslist.push(banners);
            }
          }
        }
      });

      if (bannerslist.length > 0) {
        const bannersFromLocal = localStorage.getItem('banners');
        if (bannersFromLocal === null || JSON.parse(bannersFromLocal).length === 0) {
          localStorage.setItem('banners', JSON.stringify(bannerslist));
        } else {
          if (JSON.stringify(bannerslist) !== JSON.stringify(JSON.parse(bannersFromLocal))) {
            localStorage.setItem('banners', JSON.stringify(bannerslist));
          }
        }
      } else {
        localStorage.removeItem('banners');
      }

      this.eventEmitterService.onBannersEmitter();
    });
  }

  isUserLoggedIn() {
    return this.authService.isUserLoggedIn;
  }

  onRTP() {
    window.location.href = 'https://rtp.bp9.xyz';
  }

  checkLivechat(){

    if(((document.getElementsByClassName('livechat') && document.getElementsByClassName('livechat').length > 0))
      ||(document.getElementById('chat-widget-container'))){
      return 'liveChat';
    }
    if((document.getElementsByClassName('sb-chat') && document.getElementsByClassName('sb-chat').length > 0)){
      return 'sbChat';
    }
    return false;
  }
}
