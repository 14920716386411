import { EventEmitterService } from '@core/services/event-emitter.service';
import { delay, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

  currentPage = window.location.pathname;
  footerHeight = 332;
  constructor(
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit() {
    this.setFooterPadding(1500);
    this.eventEmitterService.footerPaddingEmiter.subscribe((timer: number) => {
      this.setFooterPadding(timer);
    });
  }

  setFooterPadding(timer: number){
    of(null).pipe(
      delay(timer), tap(() => {
        this.footerHeight = document.getElementsByTagName('footer')[0]?.offsetHeight;
      }
    )).subscribe();
  }

}
