// RxJS
import { catchError, finalize } from 'rxjs/operators';
// Service
import { LoadingBarService } from '@ngx-loading-bar/core';
import { PortalMemberProfileHttpService } from '@core/services/portal-member-profile-http.service';
// Angular
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  notification = {
    sms_noti: false,
    push_noti: false
  };
  messages$ = this.portalMemberProfileHttpService.messages$;
  isSuccess = this.portalMemberProfileHttpService.isSuccess;
  disabledSmartTransferSwitch = false;

  constructor(
    private portalMemberProfileHttpService: PortalMemberProfileHttpService,
    public loadingBar: LoadingBarService
  ) { }

  ngOnInit() {
    this.loadingBar.start();
    this.portalMemberProfileHttpService.getProfile().subscribe((res: any) => {
      this.notification = {
        sms_noti: res.settings.sms_noti,
        push_noti: res.settings.push_noti
      };
      this.loadingBar.complete();
    })
  }

  onUpdate(type: string) {
    this.disabledSmartTransferSwitch = true;
    const data = {
      [type]: this.notification[type] ? 1 : 0
    };
    this.portalMemberProfileHttpService.updateSettings(data).pipe(
      finalize(() => this.disabledSmartTransferSwitch = false),
      catchError(err => {
        this.notification[type] = !this.notification[type];
        throw err;
      })
    ).subscribe();
  }

}
