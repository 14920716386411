// Angular
import { Injectable, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta } from '@angular/platform-browser';
// Service
import { DropdownHttpService } from './dropdown-http.service';
import { environment } from '@env/environment';
// Enums
import { OpenGraphType } from '@core/enums/open-graph-type.enum';
import { SeoSettingsHttpService } from './seo-settings-http.service';
import { catchError, tap } from 'rxjs/operators';

declare var gtag: any;
@Injectable({
    providedIn: 'root'
})
export class SeoService {
    // Renderer
    renderer: Renderer2;
    // Supported country for main domain only
    supportedCountry = this.dropdownHttpService.availableCountryAndLanguages[environment.domainName];
    // Get current session html head element
    head = this.doc.getElementsByTagName('head')[0];
    // To record gtag initialize state
    gTagState: boolean = false;
    // GTag URL
    gTagUrl = 'https://www.googletagmanager.com/gtag/js?id=';
    // SEO data
    seoData: any;
    // List of path to exclude Alternate
    alternateExcludeList = ['/landing'];

    constructor(
        @Inject(DOCUMENT) private doc: any,
        private metaService: Meta,
        private dropdownHttpService: DropdownHttpService,
        private rendererFactory: RendererFactory2,
        private seoSettingsHttpService: SeoSettingsHttpService
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    // TODO: Update brand name 
    setJsonLd() {
        // TEMPORARY FOR BP PROJECT ONLY
        if (environment.sitePrefix == 'bp77' || environment.sitePrefix == 'bp9') {
            let script = this.renderer.createElement('script'), origin = window.location.origin + '/';
            script.type = 'application/ld+json';
            script.text = `{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "BP77",
            "url": "${origin}",
            "logo": "${(document.getElementById("main-logo") as HTMLImageElement)?.src}",
            "sameAs": [
            "https://www.instagram.com/bp77.sg/",
            "https://www.facebook.com/BP77-Singapore-107506971549780/"
            ]
        }`;
            this.renderer.appendChild(this.doc.body, script);
        }
    }

    async execute(params?: { includeParam?: boolean, hardReset?: boolean }) {
        if (this.seoData !== 'No SEO Data for this domain' || params?.hardReset === true) {
            if (this.seoData == undefined || params?.hardReset) await this.getSeoData();

            const routeData = this.seoData.meta_data?.find(x => x.route == (params?.includeParam ? window.location.pathname + window.location.search : window.location.pathname)),
                // robots: string = routeData?.meta_robot,
                // data = {
                //     title: routeData?.meta_title,
                //     description: routeData?.meta_desc,
                //     keywords: routeData?.meta_keyword
                // },
                customCanonical: string = routeData?.custom_canonical,
                // og = {
                //     title: routeData?.open_graph_title,
                //     description: routeData?.open_graph_desc,
                //     type: routeData?.open_graph_type,
                //     url: routeData?.open_graph_url,
                //     img: routeData?.open_graph_img
                // },
                gTagId = this.seoData.gtag;

            routeUpdate(params?.includeParam);
            // this.updateMetaRobots(robots);
            // this.updateMetaTitleDescription(data);
            this.updateCanonicalAlternate(customCanonical, window.location.pathname);
            // this.updateOpenGraphTag(og);
            if (environment.production) {
                this.updateGtag(gTagId, params?.includeParam);
            }
        }
    }

    private getSeoData() {
        return new Promise<void>((resolve, reject) => {
            // START : TEMPORARY SOLUTION
            const data = {
                domain: window.location.hostname,
                country: null, //(localStorage.getItem('country_code')?.toLowerCase()),
                lang: null //(localStorage.getItem('language_code')?.toLowerCase())
            }
            if (localStorage.getItem('country_code') != null && localStorage.getItem('language_code') != null && localStorage.getItem('country_code') != 'null' && localStorage.getItem('language_code') != 'null') {
                data.country = localStorage.getItem('country_code').toLowerCase();
                data.lang = localStorage.getItem('language_code').toLowerCase();
            } else {
                data.country = Object.keys(this.supportedCountry)[0].toLowerCase();
                data.lang = this.supportedCountry[data.country.toUpperCase()].languages[0].toLowerCase();
            }
            // END : TEMPORARY SOLUTION

            const params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
            this.seoSettingsHttpService.getSettings(params).pipe(
                tap(res => {
                    this.seoData = res;
                    resolve();
                }),
                catchError(err => {
                    this.seoData = 'No SEO Data for this domain'; // To prevent keep calling API when this domain is not setup on BO
                    throw err;
                })
            ).subscribe();
        })
    }

    // private updateMetaRobots(robots: string) {
    //     if (robots) {
    //         this.metaService.updateTag({ name: 'robots', content: robots });
    //     } else {
    //         this.metaService.updateTag({ name: 'robots', content: 'noindex,nofollow' });
    //     }
    // }

    // private updateMetaTitleDescription(data: { title: string, description: string, keywords: string }) {

    //     const
    //         removeTitle = () => {
    //             this.metaService.removeTag("name='title'");
    //         },
    //         removeDescription = () => {
    //             this.metaService.removeTag("name='description'");
    //         },
    //         removeKeywords = () => {
    //             this.metaService.removeTag("name='keywords'");
    //         };

    //     if (data) {
    //         data.title ? this.metaService.updateTag({ name: 'title', content: data.title }) : removeTitle();
    //         data.description ? this.metaService.updateTag({ name: 'description', content: data.description }) : removeDescription();
    //         data.keywords ? this.metaService.updateTag({ name: 'keywords', content: data.keywords }) : removeKeywords();
    //     } else {
    //         removeTitle();
    //         removeDescription();
    //         removeKeywords();
    //     }
    // }

    private updateCanonicalAlternate(customCanonical: string, pathname: string) {
        // The following lines for setting alternate
        const setAlternate = (type: string, href: string, countryCode?: string, languageCode?: string) => {

            // x-default
            if (type == 'x-default') {
                let elementAlternate: HTMLLinkElement = this.doc.querySelector(`link[rel = 'alternate'][hreflang='x-default']`) || null;
                if (elementAlternate == null) {
                    elementAlternate = this.doc.createElement('link') as HTMLLinkElement;
                    this.head.appendChild(elementAlternate);
                    elementAlternate.setAttribute('rel', 'alternate');
                    elementAlternate.setAttribute('hreflang', 'x-default');
                }
                elementAlternate.setAttribute('href', href);
            }
            // default
            else {
                let hreflang = languageCode.toLowerCase() + '-' + countryCode.toLowerCase(),
                    elementAlternate: HTMLLinkElement = this.doc.querySelector(`link[rel = 'alternate'][hreflang='${hreflang}']`) || null;
                if (elementAlternate == null) {
                    elementAlternate = this.doc.createElement('link') as HTMLLinkElement;
                    this.head.appendChild(elementAlternate);
                    elementAlternate.setAttribute('rel', 'alternate');
                    elementAlternate.setAttribute('hreflang', hreflang);
                }
                elementAlternate.setAttribute('href', href + `?lang=${countryCode.toUpperCase()}_${languageCode.toUpperCase()}`);
            }
        }


        let elementCanonical: HTMLLinkElement = this.doc.querySelector(`link[rel='canonical']`) || null,
            URL = customCanonical ? customCanonical : window.location.origin + window.location.pathname;

        // If canonical not found in current HTML file, then create the element
        if (elementCanonical == null) {
            elementCanonical = this.doc.createElement('link') as HTMLLinkElement;
            this.head.appendChild(elementCanonical);
            elementCanonical.setAttribute('rel', 'canonical');
        }
        // Set canonical href
        elementCanonical.setAttribute('href', URL);

        if (!this.alternateExcludeList.includes(pathname)) {
            // Alternate for x-default
            setAlternate('x-default', URL);

            // Alternate for different country and language
            Object.keys(this.supportedCountry).forEach((countryCodeKey: any) => {
                this.supportedCountry[countryCodeKey].languages.map((languageCode: string) => {
                    let tempURL = window.location.protocol + '//' + this.supportedCountry[countryCodeKey]['desktopPreExt'][environment.domainEnv] + this.supportedCountry[countryCodeKey][environment.domainEnv][0] + window.location.pathname;
                    if (!tempURL.includes('undefined')) {
                        setAlternate('', tempURL, countryCodeKey, languageCode);
                    }
                })
            });
        }
    }

    // private updateOpenGraphTag(og: { title: string, description: string, type: number, url: string, img: string }) {
    //     let prop: any;
    //     let content: any;
    //     if (og) {
    //         Object.keys(og).forEach(key => {
    //             switch (key) {
    //                 case 'title':
    //                     prop = 'og:title';
    //                     content = og[key];
    //                     break;
    //                 case 'description':
    //                     prop = 'og:description';
    //                     content = og[key];
    //                     break;
    //                 case 'type':
    //                     prop = 'og:type';
    //                     content = OpenGraphType[(og[key])];
    //                     break;
    //                 case 'url':
    //                     prop = 'og:url';
    //                     content = og[key];
    //                     break;
    //                 case 'img':
    //                     prop = 'og:image';
    //                     content = og[key];
    //                     break;
    //             }
    //             if (og[key] && og[key] !== null && og[key] !== '') {
    //                 this.metaService.updateTag({ property: prop, content: content });
    //             } else {

    //                 this.metaService.removeTag(`property='${prop}'`);
    //             }
    //         });
    //     }
    // }

    private updateGtag(id: string, includeParam: boolean) {
        if (this.gTagState) {
            console.log('gtag event')
            gtag('event', 'page_view', {
                page_path: window.location.pathname + (includeParam ? window.location.search : '')
            });
        } else if (id) {
            const script = document.createElement('script');
            script.async = true;
            script.src = this.gTagUrl + id;
            document.head.prepend(script);
            gtag('config', id, { send_page_view: false });
            this.gTagState = true;
        }
    }
}