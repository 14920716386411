// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-receipt-modal',
  templateUrl: './receipt-modal.component.html',
  styleUrls: ['./receipt-modal.component.scss']
})
export class ReceiptModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { receipt_path: string, caption: string },
    public dialogRef: MatDialogRef<ReceiptModalComponent>
  ) { }

  ngOnInit(): void {
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  checkMedia(url){
    const videoExtensions = ['.mpg', '.mp2', '.mpeg', '.mpe', '.mpv', '.mp4'] //you can add more extensions
    const imageExtensions = ['.gif', '.jpg', '.jpeg', '.png'] // you can add more extensions
    const pdfExtensions   = ['.pdf'] // you can add more extensions
    var isImage = false;
    var isVideo = false;
    var isPdf = false;

    imageExtensions.map((e) => {
      isImage = url.includes(e);
    })
    if(isImage) return 'image';

    videoExtensions.map((e) => {
      isVideo = url.includes(e);
    })
    if(isVideo) return 'video';
    pdfExtensions.map((e) => {
      isPdf = url.includes(e);
    })
    if(isPdf) return 'pdf';

  }
}
