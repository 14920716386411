<div class="row">
    <ng-container *ngIf="provider === 'wbet'">
        <div class="col-3">
            <p class="mb-3">
                <span class="seo-title main-title">Wbet</span><br>
                One of Southeast Asia's most popular sports betting products has been redesigned from the ground up to take on today's online sports betting sector.
                Online live sports betting provider WBet prides itself on providing the finest possible experience for its clients in running an online bookmaking business with decades of combined industry knowledge from key Asian sports betting market participants.
                <br><br>
                Let's take a closer look!
            </p>
        </div>
        <div class="col-3">
            <p class="mb-3">
                <span class="seo-title">Wbet Sportsbook Software</span><br>
                WBET's sportsbook is a state-of-the-art online betting system designed for all sized operators & bettors on any mobile devices.
                The software offers a simple yet stylish design, intuitive navigation, and an attractive UX/UI to maximize ease of use and hold your clients' attention through the selection of numerous live matches! It offers full customization for your bet, branding and color scheme, sporting event coverage and odds offered, betting slip layout & styles, language translation, currency option, and much more.
                <br><br>
                Additionally, the WBet sportsbook offers a fully customizable client account area where bettors are able to view pending bets, bet slips history, deposit/withdrawal history, and other account information.
                The software also allows for easy integration of affiliate programs while providing access to relevant statistical information for updating odds dynamically & quickly.
            </p>
        </div>
        <div class="col-3">
            <p class="mb-3">
                <span class="seo-title">Top Games Selection</span><br>
                WBet's sportsbook customer service team has gone over all the top game selections, covering more than 90% of the global sports events.
                The most popular games such as Soccer, Football(AFC/UEFA), American Football (NFL), Baseball (MLB), Basketball (NBA, Europe), Ice Hockey (NHL, Europe) are all available.
                The software is constantly updated with new event schedules to ensure that WBet's customers are able to get the games they want.
            </p>
            <p class="mb-3">
                <span class="seo-title">Payment & Cashout Methods</span><br>
                The online sportsbook offers traditional bank wire transfer, credit card, e-wallet cashier systems for both deposits and payouts while also providing a smart about the function which allows users to quickly and easily cash out winning bets for a discounted rate.
            </p>
        </div>
        <div class="col-3">
            <p class="mb-3">
            <span class="seo-title">Main Promotions</span><br>
            The online sportsbook offers a wide range of promotional bonuses and ongoing cashback deals to encourage customer satisfaction.
            Loyal customers are rewarded with free bets, deposit bonuses, and reload promotions while bettors can also enjoy ongoing cashback deals for their successive bets.
            </p>
            <p class="mb-3">
            <span class="seo-title">Customer Support & Service </span><br>
            The WBet Customer service team assists customers 7 days a week with any problems or concerns.
            Customers can contact WBet using live chats, emailing directly to the customer service team, or calling Wbet's hotline number.
            </p>
            <p class="mb-3">
            <span class="seo-title">VIP Program</span><br>
            The online sportsbook offers exclusive deals and rewards to its highest volume bettors through its VIP program which gives out exciting prizes  & rewards.
            Customers can accumulate comp points to exchange for cash vouchers, free bets and enjoy other benefits in the VIP program.
            </p>
        </div>
    </ng-container>

    <ng-container *ngIf="provider === 'cmd'">
        <div class="col-3">
          <p class="mb-3">
              <span class="seo-title main-title">Cmd368</span><br>
              CMD368, an online betting and sports betting platform that was established in Cambodia back in 2016.
              Since then, CMD368 has gained popularity among Asian players and bettors.
              CMD368 is especially known in countries such as Malaysia, Vietnam, Indonesia, and Thailand.
              It became the go-to place for avid sports and e-sports fans.
              CMD368 has continuous growth thanks to its ability to turn new players into loyal and long-term members.
              Due to their success, CMD368 has secured its spot as one of the leading bookmakers in the gambling industry;
              specifically in the sports betting and e-sports betting scene in Asia.
          </p>
          <p class="mb-3">
              <span class="seo-title">Website Interface</span><br>
              The website is simple and it is straight to the point.
              The branding is consistent throughout the site.
              The blue and white used in the logo are prominent across the website.
              The layout is simple, the categories are listed along with the homepage so that it is easy to find games making it user-friendly.
              There's nothing necessary displayed on the screen which I like.
              <br><br>
              CMD368 viewable on both desktop and mobile web browsers.
              It is optimized to function on any screen size.
              You can use whatever device you may want to use it on.
              Whether you want to play using a desktop computer, a laptop, a tablet, or a mobile phone you can!
          </p>
          <p class="mb-3">
              <span class="seo-title">How to become a member at CMD368</span><br>
              To join as a member, you must visit the site and register there.
              You will not be able to access the games, and sportsbook if you are not a registered member.
              It is really easy to join. There is also a guide posted on the website to help interested users get started.
              Basically, the only qualification is that joining members must be 18 or above.
              After getting a confirmation that you are officially a registered member, you may now deposit credits into your account that you can use to bet.
              To deposit, contact customer service.
              You will need to fill up some information such as indicating the amount of cash you want to deposit.
              After depositing, don't forget to forward the transfer receipt to the customer service staff member so that they can confirm that you have completed your transaction.
              After your deposit has been verified, you can start betting or playing games.
          </p>
        </div>
        <div class="col-3">
          <p class="mb-3">
              <span class="seo-title">Sportsbook</span><br>
              CMD368's sportsbook is the main highlight of the whole platform.
              Actually, as soon as you open the website, you'll already get the sportsbook vibe and feel.
              CMD368 has sports betting, e-sports betting, and virtual betting.
              They offer almost any type of sport you could think of and that's the reason why bettors love CMD368.
              Being able to cater to a wide range of sports fans is CMD368 most important quality.
              They also offer almost every type of bet available out there.
              Such as Asian Handicaps, Total Goal, Mix Parlay, Special Outright Betting, 1x2 Fixed Odds, Over, and Under, Odd and Even, and Correct Score.
              Not only that, but bettors can also expect the most competitive odds among the online live betting industry.
              Bettors have full control over what type of bet they want to bet on and for a lot of people, that's really important and what they look for in a sportsbook.
              Another feature that CMD368 is proud to have is that they offer real-time betting.
              Bettors can place their bets as the game is happening.
              Users enjoy this because they can enjoy their game while they're having fun betting.
              They will never have to miss a game ever again with this feature present.
          </p>
          <p class="mb-3">
            <span class="seo-title">Casino Games</span><br>
            Despite being focused on sports online betting, CMD368 also offers every popular casino game you can think of.
            Their range and variety are also another reason why users gravitate towards CMD368.
            They are definitely a casino that has it all.
            Bettors and players never have to worry about getting bored because there's so much to do in CMD368.
            CMD368 has a little bit of something for everyone.
            Whether you opt for unique and modern games or classic games CMD368 has them for you.
            Whatever game you like, they probably have it.
            Having options is important when choosing a casino to play at because you will never get tired of playing the same game over and over and that's exactly why CMD368 has options.
            Surely this is a perk that both new users and seasoned gamblers love.
            <br><br>
            Aside from the quantity of the games they offer, the quality is also really good.
            All games are made sure to perform at the highest level.
            Every single part that makes a game is made sure to be well made.
            Every little part is thought of. Such as graphics, audio, themes, game mechanics, additional features, added bonuses, and so on.
            Try out a couple of games and you'll surely see how good the quality is. All are top tier.
            One of the reasons why they are able to put out such kinds of games is because they work with established developers.
            You can only expect smooth and steady gaming from CMD368.
          </p>
        </div>
        <div class="col-3">
          <p class="mb-3">
            <span class="seo-title">Offers, Promotions, and Bonuses</span><br>
            The best part about playing at online casinos is that you get access to exclusive perks such as getting promotions and bonuses.
            Such offerings aren't usually offered at traditional land-based casinos.
            By getting bonuses players are able to get the most out of their money.
            Playing is fun but winning something and getting something in return for playing makes it all worth it.
            CMD368 gives out generous promotions and bonuses.
            Upon confirmation of being a registered member, you are automatically entitled to all offerings.
            CMD368 offers a lot of bonuses that you benefit from whether you do sports live betting or play live casino games, slot games, and fishing games.
            To find out more about the bonuses just head on to the website and click on the promotions tab.
            All the available bonuses are listed there.
            You may check the details to find out about the offerings and the time limit of the bonuses.
            To get the most out of your membership in CMD368 make sure to use the bonuses available before it expires!
            You may also get referral bonuses by referring your friends to CMD368.
            <br><br>
            There is also a VIP club in CMD368 that offers exclusive promotions to the members.
            You will get special promotions, bonuses, and rebates when you're a member of the CMD368 VIP club.
            Basically, the VIP club has a unique reward system that gives the best experience to its members.
            This idea stemmed from the feedbacks and comments that CMD368 receives from its players.
            <br><br>
            The VIP club has different ranks and levels.
            The members get to upgrade their rank and level the more they play with CMD368.
            There are increased rewards and incentives for higher ranks and levels.
            So the more you play, the more you get!
          </p>
        </div>
        <div class="col-3">
          <p class="mb-3">
            <span class="seo-title">Payment Options</span><br>
            So far, CMD368 is a 5-star casino but there are just some issues with payment options.
            Some may find the payment procedure a bit hassling because every time you need to withdraw and deposit funds you need to fill up a form and you will need to wait for 5 to 15 minutes for the new balance to reflect.
            The options are also limited which some may find inconvenient.
            Hopefully, their payment options expand in the future and they make it their deposit and withdrawal process more efficient.
            Aside from this flaw, CMD368 is an amazing casino overall.
          </p>
          <p class="mb-3">
            <span class="seo-title">Security</span><br>
            Members of CM368 do not have to worry about hackings or information getting stolen because the website is secure.
            All personal information provided by the members is guaranteed to be kept in privacy.
            CMD368 values its members and their safety.
            CM368 has over 200 professional employees that handle the security and safety as well as the resolution if ever problems arise.
          </p>
          <p class="mb-3">
            <span class="seo-title">Customer Service</span><br>
            You may contact the customer support staff at any time.
            You are assisted day and night.
            There is a live chat where you can talk to the staff.
            They are available to help 24/7.
            The customer support is also available to contact via Skype, WeChat, and Telegram.
            CMD368 provided different platforms so that users can find it easy to communicate with the support team.
            Not only that there's a team of multilingual staff so that users find it comfortable to communicate.
          </p>
          <p class="mb-3">
            <span class="seo-title">Overall</span><br>
            CMD368 is a good option if you are looking for a casino to play or bet at.
            The sportsbook is too good to pass up.
            There is a huge directory of casino games that are all high quality.
            CMD368 prioritizes its users it is evident with the security and customer support they offer.
            Members are guaranteed to have a good time and fun experience. CMD368 is trustworthy, reputable, and world-known.
            Try it out today to experience the thrill and excitement that CMD368 offers.
          </p>
        </div>
    </ng-container>
</div>