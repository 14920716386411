<button title="Close (Esc)" type="button" class="mfp-close" (click)="onCloseNotification()">×</button>
<div (click)="onNavigate()" style="cursor: pointer;">
    <div class="text-blue text-uppercase font-weight-bold header">
        {{ 'You have a new message' | translate }}
    </div>
    <div class="message-box">
        <h6 class="msg-title">{{ data.title }}</h6>
        <div class="msg-content" [innerHTML]="sanitize(data.content)">

        </div>
    </div>
</div>