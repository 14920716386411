// Model
import { ApiResponse } from './../models/api-response.model';
import { ContactList } from '@core/models//contact-list.model';
// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// RxJS
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactListHttpService {

  constructor(private http: HttpClient) { }

  getContactList(): Observable<ContactList[]> {
    return this.http.get<ApiResponse>(`/contact`).pipe(
      map(res => res.data)
    );
  }

  getContactListByType(type: string): Observable<ContactList[]> {
    return this.http.get<ApiResponse>(`/contact?type=${type}`).pipe(
      map(res => res.data)
    );
  }

  getContactListByPlatform(platform: string, contact_type: string) {
    return this.http.get<ApiResponse>(`/contact?platform=${platform}&contact_type=${contact_type}`).pipe(
      map(res => res.data)
    );
  }
}
