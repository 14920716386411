import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-casino-footer',
  templateUrl: './casino-footer.component.html',
  styleUrls: ['./casino-footer.component.scss']
})
export class CasinoFooterComponent implements OnInit {

  @Input()
  countryCode: string;

  @Input()
  provider: string;

  constructor() { }

  ngOnInit(): void {
    this.countryCode = this.countryCode.toUpperCase();
  }

}
