import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { Store, select } from '@ngrx/store';
import { AppState } from './../../../store/reducers';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { TranslateService } from '@ngx-translate/core';
import { delay, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BottomBarService } from '@core/services/bottombar.service';
declare var $: any;

@Component({
  selector: 'app-bottombar',
  templateUrl: './bottombar.component.html',
  styleUrls: ['./bottombar.component.scss'],
  animations: [
    trigger('seoSlider', [
      state('in', style({
        height: '*'
      })),
      state('out', style({
        height: 'auto'
      })),
      transition('in => out', animate('250ms ease-in-out')),
      transition('out => in', animate('250ms ease-in-out'))
    ]),
  ]
})

export class BottombarComponent implements OnInit {
  contactList$ = sessionStorage.getItem('contact_us') === null ? [] : JSON.parse(sessionStorage.getItem('contact_us'));

  playtechPage = localStorage.getItem('playtechCategory') !== null ? true : false;
  version = localStorage.getItem('api_version');
  isSeoExpanded = false;
  originalHeight: number;
  countryCode = localStorage.getItem('country_code');
  isLaunchPage = window.location.pathname === '/launch' ? true : false;
  currentGameCategory = 'main';
  currentPageSeoFooter = 'main';
  countryHaveExtraFooter = ['SG', 'ID'];
  contentData = this.bottomBarService.contentData;
  isLoggedIn$: Observable<boolean>;
  extraFooterContent = false;

  constructor(
    public translateService: TranslateService,
    private eventEmitterService: EventEmitterService,
    private store: Store<AppState>,
    private router: Router,
    private bottomBarService: BottomBarService
  ) { }

  ngOnInit() {
    this.eventEmitterService.contactUsVar = this.eventEmitterService.updateContactUsEmitter.subscribe(() => {
      this.contactList$ = JSON.parse(sessionStorage.getItem('contact_us'));
    });
    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
    of(null).pipe(
      delay(1700), tap(() => this.version = localStorage.getItem('api_version')
      )).subscribe();

    this.haveExtraFooterContent();
    this.eventEmitterService.updateSeoFooterEmitter.subscribe((code: string) => {
      this.currentGameCategory = '';
      if (window.location.pathname.includes('esports')) {
        this.currentGameCategory = 'esports';
      } else if (window.location.pathname.includes('sport')) {
        this.currentGameCategory = 'sports';
      } else if (window.location.pathname.includes('casino')) {
        this.currentGameCategory = 'casino';
      } else if (window.location.pathname.includes('slots')) {
        this.currentGameCategory = 'slots';
      } else if (window.location.pathname.includes('/')) {
        this.currentGameCategory = 'main';
      }
      if (this.countryHaveExtraFooter.includes(this.countryCode))
        this.onChangeCurrentPageSeoFooter(code);
    });

    this.isLoggedIn$.subscribe(res => {
      of(null).pipe(
        delay(500), tap(() => this.countryCode = localStorage.getItem('country_code')
        )).subscribe();
    });
  }

  getCurrentYear() {
    return new Date().getFullYear()
  }

  onContact(link: string) {
    if (link !== undefined && link !== null) {
      if (link.includes('external')) {
        this.router.navigate([]).then(() => { window.open(link, '_blank'); });
      }
      else {
        window.open(link, "_blank");
      }
    }
  }

  onSlideSeo() {
    this.isSeoExpanded = !this.isSeoExpanded;
  }

  onChangeCurrentPageSeoFooter(code: string) {
    this.isSeoExpanded = false;
    this.currentPageSeoFooter = '';
    this.currentPageSeoFooter = code.toLocaleLowerCase();

    if (window.location.pathname === '/') {
      this.currentPageSeoFooter = 'main';
    }
    this.haveExtraFooterContent();
  }

  haveExtraFooterContent() {
    // TEMPORARY FOR BP PROJECT ONLY
    if (environment.sitePrefix == 'bp9' || environment.sitePrefix == 'bp77') {
      this.extraFooterContent = (this.contentData[this.countryCode] &&
        this.contentData[this.countryCode] &&
        this.contentData[this.countryCode][this.currentGameCategory] &&
        this.contentData[this.countryCode][this.currentGameCategory][this.currentPageSeoFooter]) instanceof Object;
    }
  }

}
