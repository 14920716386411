import { delay, tap, catchError } from 'rxjs/operators';
// Service
import { LoadingBarService } from '@ngx-loading-bar/core';
import { PromotionContentHttpService } from '../../../promotion/services/promotion-content-http.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { of } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { MemberPromotion } from '@core/enums/member-promotion.enum';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-promotion-cancel-modal',
  templateUrl: './promotion-cancel-modal.component.html',
  styleUrls: ['./promotion-cancel-modal.component.scss']
})
export class PromotionCancelModalComponent implements OnInit {

  messages$ = this.promotionContentHttpService.messages$;
  isSuccess = this.promotionContentHttpService.isSuccess;
  amountText: String = '';
  bonusAmountText: String = '';
  matTooltipText: String = '';
  memberPromotionEnum = MemberPromotion;

  gameProviderDropDownSetting = {
    enableSearchFilter: true,
    singleSelection: true,
    text: "Please Select",
    primaryKey: 'id',
    labelKey: 'game_provider_code',
    autoPosition: true,
    showCheckbox: false,
    tagToBody: false,
  };

  selectedGameProvider = [];
  buttonDisabled = false;
  gameSelected: boolean = true;

  promotionName: string = '';
  @ViewChild('falseClick') falseClick: ElementRef<HTMLElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { detail: any; amount: any, gameWalletBalance: any, activePromotionList: any[] },
    public dialogRef: MatDialogRef<PromotionCancelModalComponent>,
    private promotionContentHttpService: PromotionContentHttpService,
    private loadingBar: LoadingBarService,
    public gameCategoryHttpService: GameCategoryHttpService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.promotionName = this.data.detail.promotion_name;
    this.selectedGameProvider = this.data.activePromotionList.filter(x => x.id == this.data.detail.id);
    this.initDisplayText(false);
  }

  onCloseDialog(value = false) {
    sessionStorage.removeItem('promotionSession');
    this.dialogRef.close(value);
  }

  onSave() {
    const data = {
      member_promotion_id: this.data.detail.id,
      bonus_amount: this.data.amount.bonus_amount,
      win_amount: this.data.amount.win_amount,
      game_wallet_amount: this.data.gameWalletBalance
    }
    this.loadingBar.start();
    this.promotionContentHttpService.selfCancelPromotion(data).pipe(
      catchError(error => {
        throw error;
      })
    )
    .subscribe((res) => {
      this.loadingBar.complete();
      this.isSuccess = this.promotionContentHttpService.isSuccess;
    });
    
  }

  initDisplayText(fromDeselect = false) {
    if (!fromDeselect) {
      const amount = Number(this.data.gameWalletBalance);
      const decimalPipe = new DecimalPipe('en-US');
      const formattedAmount = decimalPipe.transform(amount, '1.2-2');
      this.amountText = this.data.amount.currency_code + ' ' + formattedAmount;
      this.bonusAmountText = this.data.amount.currency_code + ' ' + decimalPipe.transform(Number(this.data.amount.bonus_amount) + Number(this.data.amount.win_amount), '1.2-2');
      this.matTooltipText = this.translateService.instant('Bonus Amount') + ':' + ' ' + this.data.amount.currency_code + ' ' + decimalPipe.transform(this.data.amount.display_bonus_amount, '1.2-2') + '\n' + this.translateService.instant('Win Amount') + ':' + ' ' + this.data.amount.currency_code + ' ' + decimalPipe.transform(this.data.amount.win_amount, '1.2-2');
    } else {
      this.amountText = '';
      this.bonusAmountText = '';
      this.matTooltipText = '';
    }
    
  }

  async onSelectGameProvider(event: any) {
    if (this.selectedGameProvider.length > 0) {
      this.buttonDisabled = true;
      this.gameSelected = false;
      this.data.detail = null;
      this.data.amount = null;
      this.data.gameWalletBalance = null;
      this.data.detail = event;
      try {
        const res = await this.promotionContentHttpService.getBonusWinAmount(event.id).toPromise();
        const gameres = await this.gameCategoryHttpService.getProviderBalanceBy(event.game_provider_code).toPromise();
  
        this.data.amount = res;
        this.data.gameWalletBalance = gameres;
  
        this.buttonDisabled = false;
        this.initDisplayText(false);
        this.gameSelected = true;
      } catch (error) {
      }
    }
  }

  OnDeSelectGameProvider(event: any) {
    if (this.selectedGameProvider.length == 0) {
      this.buttonDisabled = true;
      this.gameSelected = false;
      this.initDisplayText(true)
      this.triggerFalseClick();
    }
  }

  OnDeSelectAllGameProvider(event: any) {
    this.buttonDisabled = true;
    this.gameSelected = false;
    this.initDisplayText(true)
    this.triggerFalseClick();
  }

  triggerFalseClick() {
    let el: HTMLElement = this.falseClick.nativeElement;
    el.click();
  }

  translateStatus(value?: string) {
    return this.translateService.instant(value);
  }
}
