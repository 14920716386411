
<ng-container *ngIf="!onDialog">
  <div class="row">
    <!-- Token -->
    <div class="col-md-6 form-group pr-0">
      <label>{{ 'Token' | translate }} </label>
      <div class="p-r-15">
        <div>
          <angular2-multiselect [data]="filteredTokens" [(ngModel)]="selectedToken" [settings]="tokensDropdownSettings"
            (onSelect)="onSelectToken(selectedToken[0].token)" (onDeSelect)="onDeSelectToken()">
          </angular2-multiselect>

        </div>
      </div>
    </div>
    <!-- Network -->
    <div *ngIf="networkDropdownList.length > 1" class="col-md-6 form-group pr-0">
      <label>{{ 'Network' | translate }}: </label>
      <div class="p-r-15">
        <div>
          <angular2-multiselect [data]="networkDropdownList" [(ngModel)]="selectedNetwork" [settings]="networkDropdownSettings"
            (onSelect)="onSelectNetwork(selectedNetwork[0])" (onDeSelect)="onDeSelectNetwork()">
          </angular2-multiselect>
        </div>
      </div>
    </div>
  </div>
  <form class="row" [formGroup]="form" id="crypto-modal-form" name="crypto-modal-form">
    <div class="col-6 form-group">
      <label for="">{{ 'Wallet Address' | translate }}</label>
      <div class="row">
        <div class="col">
          <input class="form-control" type="text" [readonly]="selectedNetwork?.length == 0 || selectedToken?.length == 0" formControlName="wallet_address"
            placeholder="{{ 'Enter Your Wallet Address' | translate }}">
          <div *ngIf="isLoading" class="loading-container mt-2">
            <mat-progress-spinner [diameter]="20" mode="indeterminate"></mat-progress-spinner>
            <span>{{ 'Validating Wallet Address' | translate }}</span>
          </div>
          <div *ngIf="!isLoading && !validated && showError" class="loading-container mt-2 text-danger">
            <span>{{ 'Invalid Wallet Address' | translate }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6 form-group">
      <label for="">{{ 'Nickname' | translate }}</label>
      <div class="row">
        <div class="col">
          <input class="form-control" type="text" [readonly]="selectedNetwork.length == 0 || selectedToken.length == 0" formControlName="wallet_nickname" placeholder="{{ 'Enter Your Wallet Nickname' | translate }}">
        </div>
      </div>
    </div>

    <div class="col-12 text-center">
      <button class="button-blue" [ngClass]="{'disabled': disabledButton || !form.valid || !validated}" [disabled]="disabledButton || !form.valid || !validated" (click)="onSave()">{{ 'Save' | translate }}</button>
    </div>
  </form>

  <div class="row" *ngIf="cryptoWallets$.length > 0">
    <hr>
    <div class="col-md-12">
      <label for="">{{ 'Crypto Wallets' | translate | uppercase }}</label>
    </div>
    <div class="col-md-6 m-b-20" *ngFor="let row of cryptoWallets$">
      <div class="bankDetails-input">
        <div class="row mx-auto">
          <div class="col-4 p-0">
            <img [src]="row.desktop_image" [alt]="row.token + ' icon'">
          </div>
          <div class="col-6">
            <div class="row h-50 align-items-center">
              <p class="text-nickname mt-1">{{ row.wallet_nickname ? row.wallet_nickname : '-'}}</p>
            </div>
            <div class="row h-50 align-items-center">
              <p class="text-address mt-1">{{ row.wallet_address }}</p>
            </div>
          </div>
          <div class="col-2 pr-0 pl-3">
            <button class="button-blue edit-button-button" (click)="onEdit(row)">
              <img src="assets/images/edit.png" class="edit-button" alt="Button Icon">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="onDialog">
  <div class="block mx-auto modal-box">
    <div class="modal1" id="modal-claim-reward">
      <button title="Close (Esc)" type="button" class="mfp-close mt-1 m-r-15" (click)="onCloseDialog()">×</button>
        <div class="p-4">
          <div class="fancy-title">
            <h4> {{ 'Add Wallet' | translate }}</h4>
          </div>
          <hr>
          <div class="row">
          <div class="col-12">
            <div class="content">
            <div class="col-12 form-group p-0">
              <label>{{ 'Token' | translate }}</label>
              <div>
                <angular2-multiselect [data]="filteredTokens" [(ngModel)]="selectedToken" [settings]="tokensDropdownSettings"
                  (onSelect)="onSelectToken(selectedToken[0].token)" (onDeSelect)="onDeSelectToken()">
                </angular2-multiselect>
              </div>
            </div>
            <div class="col-12 form-group p-0" *ngIf="networkDropdownList.length > 1">
              <label>{{ 'Network' | translate }}</label>
              <div>
                <angular2-multiselect [data]="networkDropdownList" [(ngModel)]="selectedNetwork" [settings]="networkDropdownSettings"
                  (onSelect)="onSelectNetwork(selectedNetwork[0])" (onDeSelect)="onDeSelectNetwork()">
                </angular2-multiselect>
              </div>
            </div>

            <form class="row" [formGroup]="form" id="bank-modal-form" name="bank-modal-form">
                <div class="col-12 form-group">
                  <label>{{ 'Wallet Address' | translate }}</label>
                  <div class="row">
                    <div class="col">
                      <input class="form-control" type="text" [readonly]="selectedNetwork?.length == 0 || selectedToken?.length == 0" formControlName="wallet_address"
                        placeholder="{{ 'Enter Your Wallet Address' | translate }}">
                      <div *ngIf="isLoading" class="loading-container mt-2">
                        <mat-progress-spinner [diameter]="20" mode="indeterminate"></mat-progress-spinner>
                        <span>{{ 'Validating Wallet Address' | translate }}</span>
                      </div>
                      <div *ngIf="!isLoading && !validated && showError" class="loading-container mt-2 text-danger">
                        <span style="color:#dc3545;">{{ 'Invalid Wallet Address' | translate }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 form-group">
                  <label>{{ 'Nickname' | translate }}</label>
                  <div class="row">
                    <div class="col">
                      <input class="form-control" type="text" [readonly]="selectedNetwork.length == 0 || selectedToken.length == 0" formControlName="wallet_nickname" placeholder="{{ 'Enter Your Wallet Nickname' | translate }}">
                    </div>
                  </div>
                </div>
                <div class="col-12 form-group">
                  <label for="fitness-form-name">{{ 'OTP' | translate }}</label>
                    <input class="form-control required" type="text" formControlName="otp" placeholder="{{ 'OTP' | translate }}">
                    <button type="button" class="button-blue m-0 p-0 verify-button form-control" (click)="onRequestOTP()" [disabled]="counter > 0">
                      {{ counter > 0 ? ('OTP SENT' | translate) : ('REQUEST OTP' | translate) }}<span *ngIf="counter > 0" class="ml-1 text-white"> ({{counter}})</span>
                    </button>
                </div>

              <div class="col-12 form-group m-t-20">
                <button class="button-blue w-100" [ngClass]="{'disabled': disabledButton || !form.valid || !validated}" [disabled]="disabledButton || !form.valid || !validated" (click)="onSave()">{{ 'Save' | translate }}</button>
              </div>
            </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<app-swal-alert [success]="isSuccess" [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></app-swal-alert>
<app-swal-alert [success]="isSuccess" [message]="requestOtpMessages$ | async"></app-swal-alert>
