<!-- TODO: Static values for testing -->

<!--Modal-->
<div class="block ma-box boxshadow swal2-modal">
  <div class="maintenance-modal">
    <img src="/assets/images/maintenance.png" alt="{{ 'Maintenance' | translate }}" class=" maintenance-icon m-b-20">
    <p> {{data.name}} {{'is undergoing maintenance' | translate}} <br>
      <app-count-down *ngIf="data.time !== '' " [dateEnd]="data.dateEnd" [contentHash]="contentHash">
      </app-count-down>
    </p>
    <div class="m-t-20">
      <button class="swal2-confirm" (click)="onCloseDialog()">OK</button>
    </div>
  </div>
</div>