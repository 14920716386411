import { EventEmitterService } from '@core/services/event-emitter.service';
import { BankWrapperModalComponent } from '@shared/bank-wrapper-modal/bank-wrapper-modal.component';
import { map, tap, delay, catchError } from 'rxjs/operators';
import { loggedUser } from '@core/store/auth/auth.selectors';
import { AppState } from '@store/reducers';
import { Store, select } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { BankAccountHttpService } from '@core/services/bank-account-http.service';
import { EwalletVerificationModalComponent } from '../ewallet-verification-modal/ewallet-verification-modal.component';
import { AuthData } from '@core/models/auth-data';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Input, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, of, throwError } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { MatDialog } from '@angular/material/dialog';
import svgIconList from 'assets/icons.json';
import { BankTypes } from '@core/enums/bank-type.enum';

@Component({
  selector: 'app-bank-modal',
  templateUrl: './bank-modal.component.html',
  styleUrls: ['./bank-modal.component.scss']
})
export class BankModalComponent implements OnInit {

  @ViewChildren('focusInput') focusInput: QueryList<ElementRef>;
  @ViewChildren('focusInput2') focusInput2: QueryList<ElementRef>;
  svg: any = svgIconList;

  bankFieldVisible = false;
  form: FormGroup;
  bankAccounts$ = [];
  dataSub$ = new BehaviorSubject<any>(null);
  isSuccess = this.bankAccountHttpService.isSuccess;
  dropDownbanks: any;
  userName = localStorage.getItem('profile');
  messages$ = this.bankAccountHttpService.messages$;
  user$: Observable<AuthData>;
  private subscription = new Subscription();
  checkValidity = false;
  allBank = JSON.parse(sessionStorage.getItem('all_bank'));
  memberBankAccounts = JSON.parse(sessionStorage.getItem('member_bank_accounts'));
  disabledButton = false;
  isVerificationSetting: boolean;
  isVerificationSettingForReupload: boolean;

  @Input()
  onDialog = false;

  @Input()
  existing_bank_name: any;

  @Input()
  is_deposit: boolean = false;

  @Input()
  is_transfer: boolean = false;

  @Input()
  is_withdraw: boolean = false;

  @Input()
  bank_type: any;

  bankDropdownList = [];
  selectedBank = [];
  paymentMethod = [];
  availablePaymentMethod = [];
  selectedPaymentMethod = [];
  selectedBankType = 'Bank';
  bankDropdownSettings = {
    enableSearchFilter: true,
    singleSelection: true,
    text: this.translateService.instant("Please Select"),
    primaryKey: 'id',
    labelKey: 'name',
    autoPosition: true,
    showCheckbox: false,
    tagToBody: false
  };

  paymentMethodDropdownSettings = {
    singleSelection: true,
    text: this.translateService.instant("Select Payment Method"),
    primaryKey: 'id',
    labelKey: 'name',
    showCheckbox: false,
    disabled: false
  };

  bankDropdownDialogSettings = {
    enableSearchFilter: true,
    singleSelection: true,
    text: this.translateService.instant("Please Select"),
    primaryKey: 'id',
    labelKey: 'name',
    showCheckbox: false,
  };
  countryCode = localStorage.getItem('country_code');
  userData = JSON.parse(localStorage.getItem('user_data'));
  selectedBankCode: string;
  payNowType = [
    { code: 'mobile', name: this.translateService.instant('Mobile Number') },
    { code: 'nric', name: this.translateService.instant('NRIC/FIN') },
    { code: 'uen', name: this.translateService.instant('Unique Entity Name (UEN)') },
    { code: 'vpa', name: this.translateService.instant('Virtual Payment Address (VPA)') },
  ];
  selectedType: any;

  // To check SG PayNow
  formValid = true;
  
  constructor(
    public dialogRef: MatDialogRef<BankWrapperModalComponent>,
    private bankAccountHttpService: BankAccountHttpService,
    private dropdownHttpService: DropdownHttpService,
    private store: Store<AppState>,
    public loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService,
    private translateService: TranslateService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.formInit();
    this.reload();
    this.user$ = this.store.pipe(select(loggedUser));
    this.user$.subscribe();
    this.eventEmitterService.updateBankListEmitter.subscribe(() => {
      this.onGetBankAccount(null);
    });
    if (this.bank_type != undefined) {
      this.onSelectPaymentMethod(this.bank_type);

      if (this.bank_type == 1) {
        this.selectedPaymentMethod.push({ id: 1, name: this.translateService.instant('Bank') });
      }
      else if (this.bank_type == 3) {
        this.selectedPaymentMethod.push({ id: 3, name: this.translateService.instant('E-Wallet') });
      }
      this.paymentMethodDropdownSettings.disabled = true;
    }
    if (this.onDialog) {
      of(null).pipe(
        delay(200), tap(() => this.focusInput2.first?.nativeElement.focus()
        )).subscribe();
    }
  }

  onGetBankAccount(memberBankAccounts: any, reload: boolean = false) {
    if (reload || memberBankAccounts === null || memberBankAccounts.length == 0) {
      this.bankAccountHttpService.getAll().pipe(
        tap(res => {
          sessionStorage.setItem('member_bank_accounts', JSON.stringify(res));
          this.memberBankAccounts = res;
          this.dataSub$.next(res);
          this.setBankDetails(res, null);
        })
      ).subscribe();
    } else {
      this.setBankDetails(memberBankAccounts, this.allBank);
    }
  }

  onCloseDialog(event?: Event) {
    if (this.onDialog) {
      this.dialogRef.close();
      this.eventEmitterService.onReloadBankList();
      this.eventEmitterService.onUpdateProfileName();
      this.onGetBankAccount(null);
    } else {
      this.onGetBankAccount(null);
      this.formInit();
      this.selectedBank = [];
      this.resetPayNowField();
    }
  }

  onSelectBank(value: any) {
    const bank_type = value.id;

    this.form.patchValue({
      account_number: null
    });
    this.checkValidity = false;
    this.selectedBankCode = bank_type === BankTypes.OTHERS ? 'OTHER' : value.code;
    of(null).pipe(
      delay(200), tap(() => {
        if (this.onDialog) {
          this.focusInput2.first?.nativeElement.focus();
        } else {
          this.focusInput.first?.nativeElement.focus();
        }
      }
      )).subscribe();
    if (bank_type === BankTypes.OTHERS) {
      this.bankFieldVisible = true;
      this.form.get('bank_name').setValidators([Validators.required, Validators.pattern(/^[a-zA-Z0-9\s]+$/)]);
      this.form.get('bank_name').updateValueAndValidity();
      this.form.patchValue({
        bank_id: BankTypes.OTHERS,
        account_name: this.userName
      });
    } else {
      this.bankFieldVisible = false;
      this.form.get('bank_name').clearValidators();
      this.form.get('bank_name').updateValueAndValidity();
      this.form.patchValue({
        bank_id: bank_type,
        account_name: this.userName
      });
    }
    if (this.selectedType !== undefined) {
      this.resetPayNowField();
    }
    if (!(value.code === 'PAYNOW' && this.countryCode === 'SG')) {
      this.form.get('account_number').setValidators([Validators.required, Validators.pattern(/^[a-zA-Z0-9\s]+$/)]);
      this.form.get('account_number').updateValueAndValidity();
    }
  }

  onSelectType(item: any) {
    this.resetPayNowField();
    this.selectedType = item;
    this.setPayNowValidity();
  }

  OnDeSelectBank() {
    this.selectedBankCode = undefined;
    this.selectedBank = [];
    this.form.patchValue({
      bank_id: null,
      account_number: null
    });
    this.bankFieldVisible = false;
  }

  OnDeSelectPaymentMethod() {
    this.bankDropdownList = [];
    this.selectedBankCode = undefined;
    this.selectedBank = [];
    this.form.patchValue({
      bank_type: null,
      bank_id: null,
      account_number: null
    });
    this.bankFieldVisible = false;
    this.setSelectedBankTypeBank();
  }

  onSave() {
    this.form.markAllAsTouched();
    this.checkValidity = true;
    if (this.form.valid) {
      this.loadingBar.start();
      this.disabledButton = true;
      const data = {
        ...this.form.value
      };
      Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
      this.bankAccountHttpService.addBank(data).pipe(
        catchError(err => {
          this.loadingBar.complete();
          this.checkValidity = false;
          this.disabledButton = false;
          return throwError(err);
        })
      ).subscribe(() => {
        if (localStorage.getItem('profile') === null) {
          this.userName = data.account_name;
          localStorage.setItem('profile', data.account_name);
          this.form.patchValue({ account_name: data.account_name })
        }
        this.loadingBar.complete();
        this.checkValidity = false;
        this.disabledButton = false;
        this.isSuccess = this.bankAccountHttpService.isSuccess;
        this.selectedBankCode = undefined;
        this.bankDropdownList = this.dropDownbanks.filter(x => x.id != this.form.get('bank_id').value && x.bank_type == this.form.get('bank_type').value);
        if (this.bankDropdownList.length == 0 && this.form.get('bank_type').value != 1) {
          this.availablePaymentMethod = this.availablePaymentMethod.filter(x => x.id != this.form.get('bank_type').value);
        }
        this.bankDropdownList = [];
        this.selectedPaymentMethod = null;
        this.setSelectedBankTypeBank();
      });
    }
  }

  private setBankDetails(memberBankAccounts: any, allBank: any) {
    this.bankAccounts$ = memberBankAccounts;
    this.dataSub$.next(memberBankAccounts);
    let existingBanks = [];
    memberBankAccounts.map(bank => {
      existingBanks.push(bank.bank_name);
    });
    this.existing_bank_name = existingBanks;
    this.filterBankList(allBank);
  }

  private reload() {
    this.onGetBankAccount(this.memberBankAccounts, true);
  }

  private filterBankList(allBank: any) {
    if (allBank === null) {
      this.dropdownHttpService.banks.pipe(
        map((res) => {
          this.allBank = res;
          sessionStorage.setItem('all_bank', JSON.stringify(res));
          this.setPaymentMethodDropdown(res);
          this.setBankDropdown(res);
          return res.filter((item) => {
            return !this.existing_bank_name.includes(item.name);
          })
        })
      ).subscribe((res) => this.dropDownbanks = res);
    } else {
      //for bank details
      this.setPaymentMethodDropdown(allBank.filter((item) => {
        return !this.existing_bank_name.includes(item.name);
      }));
      //for withdraw banks dropdown
      this.setBankDropdown(allBank);
      this.dropDownbanks = allBank.filter((item) => {
        return !this.existing_bank_name.includes(item.name);
      });
    }
  }

  private setBankDropdown(data: any) {
    data.push({ id: BankTypes.OTHERS, name: "Others", bank_type: 1, bank_type_name: "Bank" });
  }

  private setPaymentMethodDropdown(data: any) {
    if (this.paymentMethod.length == 0) {
      data = data.filter(item => item.bank_type !== 6);

      data?.push({ id: BankTypes.OTHERS, name: "Others", bank_type: 1, bank_type_name: "Bank" });

      // Get single data with different payment method
      let filteredPaymentMethod = data.filter(
        (item, i, arr) => arr.findIndex(x => x.bank_type === item.bank_type) === i
      );

      // Get different payment method
      for (let i = 0; i < filteredPaymentMethod.length; i++) {
        this.paymentMethod.push({ id: filteredPaymentMethod[i].bank_type, name: this.translateService.instant(filteredPaymentMethod[i].bank_type_name) });
      }

      // Sort data
      this.availablePaymentMethod = this.paymentMethod.sort(function (a, b) {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      });
    }
  }

  private formInit() {
    this.form = new FormGroup({
      bank_id: new FormControl(null, [Validators.required]),
      bank_type: new FormControl(null, [Validators.required]),
      account_number: new FormControl(null, [Validators.required]),
      bank_name: new FormControl(null, [Validators.pattern(/^[a-zA-Z0-9\s]+$/)]),
      account_name: new FormControl(null, [Validators.required]),
      mobile_number: new FormControl(null),
      nric_fin: new FormControl(null),
      unique_entity_name: new FormControl(null),
      virtual_payment_address: new FormControl(null)
    });
  }

  private setPayNowValidity() {
    if (this.selectedBankCode === 'PAYNOW' && this.countryCode === 'SG') {
      this.form.get('account_number').clearValidators();
      if (this.selectedType.code === 'mobile') {
        this.form.controls.mobile_number.setValidators(Validators.required);
        this.form.controls.nric_fin.clearValidators();
        this.form.controls.unique_entity_name.clearValidators();
        this.form.controls.virtual_payment_address.clearValidators();
        this.form.patchValue({
          account_number: null,
          mobile_number: this.userData.phone,
        });
      } else if (this.selectedType.code === 'nric') {
        this.form.controls.nric_fin.setValidators(Validators.required);
        this.form.controls.mobile_number.clearValidators();
        this.form.controls.unique_entity_name.clearValidators();
        this.form.controls.virtual_payment_address.clearValidators();
      } else if (this.selectedType.code === 'uen') {
        this.form.controls.unique_entity_name.setValidators(Validators.required);
        this.form.controls.mobile_number.clearValidators();
        this.form.controls.nric_fin.clearValidators();
        this.form.controls.virtual_payment_address.clearValidators();
      } else if (this.selectedType.code === 'vpa') {
        this.form.controls.virtual_payment_address.setValidators(Validators.required);
        this.form.controls.mobile_number.clearValidators();
        this.form.controls.nric_fin.clearValidators();
        this.form.controls.unique_entity_name.clearValidators();
      }
    } else {
      this.resetPayNowField();
    }
    this.updateFormValidity();
  }

  private resetPayNowField() {
    this.selectedType = undefined;
    this.form.patchValue({
      mobile_number: null,
      nric_fin: null,
      unique_entity_name: null,
      virtual_payment_address: null,
    });
    this.form.controls.mobile_number.clearValidators();
    this.form.controls.nric_fin.clearValidators();
    this.form.controls.unique_entity_name.clearValidators();
    this.form.controls.virtual_payment_address.clearValidators();
    this.updateFormValidity();
  }

  private updateFormValidity() {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).updateValueAndValidity();
    });
  }

  toggleBankField(value: any) {
    if (value === '0') {
      this.bankFieldVisible = true;
      this.form.get('bank_name').setValidators([Validators.required, Validators.pattern(/^[a-zA-Z0-9\s]+$/)]);
      this.form.get('bank_name').updateValueAndValidity();
    } else {
      this.bankFieldVisible = false;
      this.form.get('bank_name').clearValidators();
      this.form.get('bank_name').updateValueAndValidity();
    }
  }

  onSelectPaymentMethod(bank_type: any) {
    this.bankDropdownList = this.dropDownbanks.filter(x => x.bank_type == bank_type);

    if (bank_type == BankTypes.BANK) {
      this.setSelectedBankTypeBank();
    }
    else if (bank_type == BankTypes.EWALLET) {
      this.setSelectedBankTypeEwallet();
    }

    this.form.reset();
    this.selectedBank = [];
    this.selectedBankCode = null;
    this.form.patchValue({
      bank_type: bank_type,
    });
    this.bankFieldVisible = false;
  }

  setSelectedBankTypeBank() {
    this.selectedBankType = 'Bank';
    this.bankDropdownSettings = {
      enableSearchFilter: true,
      singleSelection: true,
      text: this.translateService.instant("Select Bank"),
      primaryKey: 'id',
      labelKey: 'name',
      autoPosition: true,
      showCheckbox: false,
      tagToBody: false
    };
  }

  setSelectedBankTypeEwallet() {
    this.selectedBankType = 'E-Wallet';
    this.bankDropdownSettings = {
      enableSearchFilter: true,
      singleSelection: true,
      text: this.translateService.instant("Select E-Wallet"),
      primaryKey: 'id',
      labelKey: 'name',
      autoPosition: true,
      showCheckbox: false,
      tagToBody: false
    };
  }

  onOpenDialog(reupload?: boolean, bankAccount?: any, verificationSetting?: any) {
    if (reupload == true) {
      this.openDialogBy(EwalletVerificationModalComponent, reupload, bankAccount);
    } else {
      this.openDialogBy(EwalletVerificationModalComponent, reupload, null, this.form.value, verificationSetting);
    }
  }

  private openDialogBy(componentRef: any, reupload?: boolean, bankAccount?: any, formValue?: any, verificationSetting?: any) {
    if (reupload == true) {
      var dialogRef = this.dialog.open(componentRef, {
        width: 'auto',
        data: {
          reupload: reupload,
          bankAccountId: bankAccount.id,
          reason: bankAccount.reason,
          verificationSetting: bankAccount
        } 
      });
    } else {
      var dialogRef = this.dialog.open(componentRef, {
        width: 'auto',
        data: {
          reupload: reupload,
          account_name: formValue.account_name,
          account_number: formValue.account_number,
          bank_id: formValue.bank_id,
          bank_type: formValue.bank_type,
          verificationSetting: verificationSetting
        } 
      });
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.formInit();
        this.selectedBank = [];
        this.bankDropdownList = [];
        this.selectedPaymentMethod = null;
        this.setSelectedBankTypeBank();
        this.checkValidity = false;
        this.resetPayNowField();
        this.eventEmitterService.onReloadBankList();
        if (this.onDialog) {
          this.dialogRef.close();
        }
      }
    });
  }

  onSubmit(selectedBank?: any, reupload?: boolean, bankAccount?: any) {
    // Normal add bank
    if (selectedBank) {
      this.isVerificationSetting = selectedBank[0]['verification_setting_id'] != null && selectedBank[0]['verification_settings_field'].length > 0 ? true : false;
    }

    // Reupload
    if (bankAccount) {
      this.isVerificationSettingForReupload = bankAccount.status != null ? true : false;
    }

    if (this.isVerificationSetting || this.isVerificationSettingForReupload) {
      if (reupload == true) {
        this.onOpenDialog(reupload, bankAccount);
      } else {
        this.form.markAllAsTouched();
        this.checkValidity = true;
        if (this.form.valid) {
          this.onOpenDialog(reupload, null, selectedBank);
        }
      }
    } else {
      this.onSave(); 
    }
  }
}

