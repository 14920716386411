<div class="block mx-auto ma-box modal-box">
    <div class="modal1" id="modal-login">
       <div class="demomode-notice">
           <div class="notice-body">
               <p class="icon-wrapper"><span class="icon">i</span></p>
               <h6>{{ 'VPN REQUIRED' | translate }}</h6>
               <p>{{ 'Game(s) under this provider are not accessible from your country by default. Please connect to a VPN should you wish to play them.' | translate }}</p>

               <!-- No need for translation -->
               <button class="button-blue w-100 m-b-10" (click)="onConfirm()">OK</button>
           </div>
           <p class="do-not-show-again d-flex justify-content-center my-2" (click)="onNotShowAgain()">{{ 'Do not show again' | translate }}</p>
       </div>
    </div>
 </div>