import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { WebsocketService } from '@core/services/websocket.service';

@Component({
  selector: 'app-push-notification-cta',
  templateUrl: './push-notification-cta.component.html',
  styleUrls: ['./push-notification-cta.component.scss']
})
export class PushNotificationCTAComponent implements OnInit {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBarRef: MatSnackBarRef<PushNotificationCTAComponent>,
    private router: Router,
    private sanitizer: DomSanitizer,
    private websocketService: WebsocketService,
  ) { }

  ngOnInit(): void {
  }

  onCloseNotification() {
    this.snackBarRef.dismiss();
  }
  
  onNavigate() {
    this.onCloseNotification(); // close the snack first
    const path = this.websocketService.getCTAPath(this.data.CTA);
    this.router.navigate([path]);
  }

  sanitize(content: any) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

}
