import { Component, OnInit } from "@angular/core";

@Component({
  selector: 'app-signup-form-wrapper',
  templateUrl: './signup-form-wrapper.component.html',
  styleUrls: ['./signup-form-wrapper.component.scss']
})
export class SignupFormWrapperComponent implements OnInit {
  
  mode: string = window.mode;

  ngOnInit() {}
}