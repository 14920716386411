<div class="row crs-expand hide gutter-10">
  <div class="col-5">
    <div class="slots-game-app">
      <a [href]="miniURL">
        <button class="btn-download btn-download mt-0 mb-3">
            <div class="download-icon">
                <a [href]="miniURL">
                  <span [innerHTML]="svg.androidIcon | safeHtml"></span>
                  <span [innerHTML]="svg.appleIcon | safeHtml"></span>
                  <div class="download-title">
                      <h4> {{ 'Download' | translate | uppercase }} <br>
                          <span class="download-title-small">{{ 'iOS' | translate }} / {{ 'Android' | translate }}</span>
                      </h4>
                  </div>
                </a>
            </div>
        </button>
      </a>
      <h2 class="m-b-0">{{ '3 EASY STEPS TO DOWNLOAD' | translate }}</h2>
      <hr>
      <ol class="p-l-15 m-b-0">
        <li>{{ 'Click the download button to download [gameProvider] if you have not downloaded.' | translate : { 'gameProvider' : translateService.instant(''+gameCode) } }}</li>
        <li>{{ 'Proceed to install the App and follow the on screen instructions.' | translate }}</li>
        <li>{{ 'Launch the application and enter the following details to login!' | translate }}</li>
      </ol>
    </div>
  </div>
  <div class="col-7">
    <div class="slots-game-id">
      <form [formGroup]="form" class="transfer-form m-b-10">
        <div class="row">
          <div class="col-6">
              <h5>{{ 'Game Wallet Balance' | translate }}</h5>
              <div class="input-group bg-disable">
                <span class="input-group-append">
                  <span class="input-rounded-left currency-box custom-text"> {{ currency.code }}</span>
                </span>
                <input class="form-control custom-text" type="text" [value]="appBalance | number:'1.2'" disabled="">
              </div>
          </div>
            <div class="col-6">
                <h5>{{ 'Transfer Amount' | translate }}</h5>
                <div class="input-transfer">
                  <div class="input-group">
                    <span class="input-group-append">
                      <span class="input-rounded-left currency-box color-cf custom-text"> {{ currency.code }}</span>
                    </span>
                    <input class="form-control transfer-form-text transfer-input" type="text" formControlName="amount" digitOnly decimal="true" decimalSeparator="." pattern="^\d+(\.\d{1,2})?$">
                    <span class="button-blue" (click)="onTransfer()">
                      {{ 'Transfer' | translate | uppercase }}
                    </span>
                  </div>
                </div>
            </div>
        </div>
      </form>
      <div class="row">
        <div class="col-6">
            <h5>{{ 'Username' | translate }}</h5>
            <a class="btn-copy"[cdkCopyToClipboard]="username" (cdkCopyToClipboardCopied)="onCopyText('username')">
              <span [innerHTML]="svg.copyIcon | safeHtml" class="copy-icon"  (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation()"
              mat-raised-button #tooltipUsername="matTooltip" [matTooltip]="'Copied' | translate " matTooltipPosition="above"></span>
            </a>
            <input class="form-control custom-text" type="text" [value]="username" disabled="">
        </div>
        <div class="col-6">
            <h5>{{ 'Password' | translate }}</h5>
            <a class="game-password-btn" (click)="changeGamePassword()">{{ 'Change Game Password' | translate }}</a>
            <a class="btn-copy" [cdkCopyToClipboard]="password" (cdkCopyToClipboardCopied)="onCopyText('password')">
              <span [innerHTML]="svg.copyIcon | safeHtml" class="copy-icon" (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation()"
              mat-raised-button #tooltipPassword="matTooltip" [matTooltip]="'Copied' | translate " matTooltipPosition="above"></span>
            </a>
            <input class="form-control custom-text" type="text" [value]="password" disabled="">
        </div>
      </div>
    </div>
  </div>
</div>
<app-swal-alert [success]="isSuccess" [message]="messages$ | async" (confirmed)="onResetForm()"></app-swal-alert>
