<header id="header">
  <div id="header-wrap">
    <div class="topbar-color">
      <div class="row vertical-row height-90 max-width-1320">
        <!----LOGO---->
        <div class="col-6 p-l-0">
          <ng-container *ngIf="isLaunchGamePage == false">
            <div class="d-flex align-items-center">
              <!----SEASONAL THEME LOGO---->
              <ng-container *ngIf="showTheme; else noTheme">
                <ng-container *ngFor="let theme of themes | keyvalue: returnZero">
                  <a *ngIf="theme.value.started && !theme.value.end" routerLink="/">
                    <img [src]="theme.value.logo" [alt]="altName" class="theme-logo" id="theme-logo">
                  </a>            
                </ng-container>
              </ng-container>
              <!----NORMAL LOGO---->
              <ng-template #noTheme>
                <a routerLink="/" data-dark-logo="/assets/images/logo-dark.png">
                  <img src="/assets/images/logo-white.png" [alt]="altName" class="main-logo" id="main-logo">
                </a>
              </ng-template>
              <h5 class="text-center text-white font-weight-normal m-0" *ngIf="sitePrefix.toLowerCase() == 'bp77' || sitePrefix.toLowerCase() == 'bp9'">
                <span class="vertical-bar"> | </span>
                <i *ngIf="sitePrefix.toLowerCase() != 'bp9'" class="vertical-super">{{ 'Now known as BP9' | translate }}</i>
                <i *ngIf="sitePrefix.toLowerCase() == 'bp9'" class="vertical-super">{{ 'Formerly known as BP77' | translate }}</i>
              </h5>
            </div>
          </ng-container>
        </div>
        <div *ngIf="!onMaintenancePage"class="col-6 p-r-0">
          <nav class="primary-menu order-lg-3 px-0" style="position:inherit;">
            <ul class="menu-container justify-content-lg-end">
              <ng-container *ngIf="(isLoggedIn$ | async) === false || null || undefined; else elseMember">
                <ng-container>
                  <li *ngIf="mode != 'Members Only'" class="menu-item">
                    <a class="button-blue-outline m-r-10" (click)="onOpenDialog('login')">
                      {{ 'Login' | translate }}
                    </a>
                  </li>
                  <li *ngIf="mode != 'Members Only'" class="menu-item">
                    <a class="button-orange" (click)="onOpenDialog('signup')">
                      {{ 'Sign Up' | translate }}
                    </a>
                  </li>
                  <li class="menu-item languages-selection">
                    <a (click)="onOpenDialog('language')">
                      <img [src]="userFlag" width="25px">
                      {{ currentLangCode }}
                    </a>
                  </li>
                </ng-container>
              </ng-container>

              <ng-template #elseMember>
                <li class="menu-item">
                  <a class="menu-link" routerLink="/member/wallet" title="My Wallet">
                    <span [innerHTML]="svg.walletIcon | safeHtml" class="topbar-svg"></span>
                    <span *ngIf="depositData != null" class="badge-btn top-bar-badge no-content-badge" matBadge="-" matBadgeColor="warn"></span>
                  </a>
                </li>
                <li class="menu-item"> 
                  <a class="menu-link" routerLink="/member/profile" title="My Account" (mouseenter)="onHover('portal', true)" (mouseleave)="onHover('portal')">
                    <span [innerHTML]="svg.profileIcon | safeHtml" class="topbar-svg"></span>
                    <span *ngIf="verificationRejected == true" class="badge-btn top-bar-badge no-content-badge" matBadge="-" matBadgeColor="warn"></span>
                  </a>
                </li>
                <li class="menu-item m-r-10">
                  <a id="top-message-trigger" routerLink="/member/message" class="mr-2 message-link">
                    <span [innerHTML]="svg.messageIcon | safeHtml" class="topbar-svg"></span>
                    <ng-container *ngIf="!isTokenExpired">

                    </ng-container>
                    <ng-container *ngIf="(unreadMessages$ | async) as unread">
                      <span class="top-cart-number" [ngClass]="{ 'bounce': newMessage }">
                        {{ unread }}
                      </span>
                    </ng-container>
                  </a>
                </li>
                <li class="menu-item"> <a class="button-blue-outline m-r-10"
                    (click)="onLogout()">{{ 'Logout' | translate }}</a> </li>
                <li class="menu-item"> <a [routerLink]="['/member/wallet', {tab: 'deposit'}]"
                    class="button-orange">{{ 'Deposit' | translate }}</a> </li>
                <li class="menu-item languages-selection p-r-0"><a (click)="onOpenDialog('language')"><img
                      [src]="userFlag">{{ currentLangCode }}</a></li>
              </ng-template>

            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="(mode != 'Members Only' || isUserLoggedIn())" id="top-bar-menu" class="top-nav" [ngClass]="fixed ? 'position-fixed' : 'position-absolute'">
    <div class="top-navigation-bar">
      <div class="row vertical-row height-50 max-width-1320 space-between">
        <ul class="flex-container w-100 navigation-menu" id="navigation-menu">
          <li class="menu-item">
            <div class="device-switch m-r-10" routerLinkActive="current" [routerLinkActiveOptions]="{ exact: true }">
              <a routerLink="/">
              <span [innerHTML]="svg.UPViewIcon | safeHtml"></span></a>
            </div>
            <div class="device-switch">
              <a [href]="mobileDomain">
                <span [innerHTML]="svg.MUPViewIcon | safeHtml"></span></a>
            </div>
          </li>
          <ng-container *ngFor="let menuList of menuLists; let i = index">
            <ng-container *ngIf="i < menuMaxItems">
              <ng-container *ngIf="menuList.link == '/casino' || menuList.link == '/casino-game' || menuList.link == '/slots' || menuList.link == '/slots-game' || menuList.link == '/fishing' || menuList.link == '/sports' || menuList.link == '/esports' || menuList.link == '/lottery' || menuList.link == '/crash-game' || menuList.link == '/table'; else othersMenu">
                <li [ngClass]="[this.router.url.includes('/casino') ? 'current' : '',menuList.new ? 'position-relative' : '']" *ngIf="menuList.link == '/casino' && availableProvider !== null &&  availableProvider.LC?.length > 0">
                  <div *ngIf="menuList.new" class="prediction-notification"></div>
                  <a routerLink="/casino">
                    <div>{{ menuList.name }}</div>
                  </a>
                </li>
                <li [ngClass]="[this.router.url.includes('/casino') ? 'current' : '',menuList.new ? 'position-relative' : '']"  *ngIf="menuList.link == '/casino-game' && availableProvider !== null && availableProvider.LC?.length > 0">
                  <div *ngIf="menuList.new" class="prediction-notification"></div>
                  <a routerLink="/casino-game">
                    <div>{{ menuList.name }}</div>
                  </a>
                </li>
                <li [ngClass]="[this.router.url.includes('/slots') ? 'current' : '',menuList.new ? 'position-relative' : '']" *ngIf="menuList.link == '/slots' && availableProvider !== null && availableProvider.SL?.length > 0">
                  <div *ngIf="menuList.new" class="prediction-notification"></div>
                  <a routerLink="/slots">
                    <div>{{ menuList.name }}</div>
                  </a>
                </li>
                <li [ngClass]="[this.router.url.includes('/slots') ? 'current' : '',menuList.new ? 'position-relative' : '']"  *ngIf="menuList.link == '/slots-game' && availableProvider !== null && availableProvider.SL?.length > 0">
                  <div *ngIf="menuList.new" class="prediction-notification"></div>
                  <a routerLink="/slots-game">
                    <div>{{ menuList.name }}</div>
                  </a>
                </li>
                <li [ngClass]="{'position-relative': menuList.new }" routerLinkActive="current" *ngIf="menuList.link == '/fishing' && availableProvider !== null &&  availableProvider.FS?.length > 0">
                  <div *ngIf="menuList.new" class="prediction-notification"></div>
                  <a routerLink="/fishing">
                    <div>{{ menuList.name }}</div>
                  </a>
                </li>
                <li [ngClass]="{'position-relative': menuList.new }" routerLinkActive="current" *ngIf="menuList.link == '/sports' && availableProvider !== null && availableProvider.SP?.length > 0">
                  <div *ngIf="menuList.new" class="prediction-notification"></div>
                  <a routerLink="/sports">
                    <div>{{ menuList.name }}</div>
                  </a>
                </li>
                <li [ngClass]="{'position-relative': menuList.new }" routerLinkActive="current" *ngIf="menuList.link == '/esports' && availableProvider !== null &&  availableProvider.ES?.length > 0">
                  <div *ngIf="menuList.new" class="prediction-notification"></div>
                  <a routerLink="/esports">
                    <div>{{ menuList.name }}</div>
                  </a>
                </li>
                <li [ngClass]="{'position-relative': menuList.new }" routerLinkActive="current" *ngIf="menuList.link == '/lottery' && availableProvider !== null &&  availableProvider.LT?.length > 0">
                  <div *ngIf="menuList.new" class="prediction-notification"></div>
                  <a routerLink="/lottery">
                    <div>{{ menuList.name }}</div>
                  </a>
                </li>
                <li [ngClass]="{'position-relative': menuList.new }" routerLinkActive="current" *ngIf="menuList.link == '/crash-game' && availableProvider !== null &&  availableProvider.CG?.length > 0">
                  <div *ngIf="menuList.new" class="prediction-notification"></div>
                  <a routerLink="/crash-game">
                    <div>{{ menuList.name }}</div>
                  </a>
                </li>
                <li [ngClass]="{'position-relative': menuList.new }" routerLinkActive="current" *ngIf="menuList.link == '/table' && availableProvider !== null &&  availableProvider.TB?.length > 0">
                  <div *ngIf="menuList.new" class="prediction-notification"></div>
                  <a routerLink="/table">
                    <div>{{ menuList.name }}</div>
                  </a>
                </li>
              </ng-container>
              <ng-template #othersMenu>
                <li *ngIf="menuList.link" routerLinkActive="current" [ngClass]="{'position-relative': menuList.link == '/prediction' || menuList.new }">
                  <div *ngIf="(menuList.link == '/prediction' && !isPredictionTabClicked) || menuList.new" class="prediction-notification"></div>
                  <a [routerLink]="menuList.link" (click)="navigateTo(menuList.link)">
                    <div>{{ menuList.name }}</div>
                  </a>
                </li>
              </ng-template>
            </ng-container>
          </ng-container>

          <!-- More -->
          <li (click)="toggleMoreDropdown()" (clickOutside)="toggleMoreDropdown(true)" *ngIf="menuLists && menuLists.length > menuMaxItems" class="menu-bar-more">
            <a>
              <div *ngIf="(predictionMenuHidden && !isPredictionTabClicked) || newMenuHidden" class="prediction-notification" id="prediction-notification-more"></div>
              <div class="user-select-none">{{ 'More' | translate }}<img src="/assets/images/down-arrow.png" class="more-down-arrow" id="more-down-arrow"></div>
            </a>
            <div class="more-dropdown d-none" id="more-dropdown">
              <ng-container *ngFor="let menuList of menuLists; let i = index">
                <ng-container *ngIf="i >= menuMaxItems">
                  <ng-container *ngIf="menuList.link == '/casino' || menuList.link == '/slots' || menuList.link == '/fishing' || menuList.link == '/sports' || menuList.link == '/esports' || menuList.link == '/lottery' || menuList.link == '/crash-game' || menuList.link == '/table'; else othersMenu2">
                    <div routerLinkActive="current" class="more-dropdown-item" *ngIf="(menuList.link == '/casino' && availableProvider !== null && availableProvider.LC?.length > 0) ||
                      (menuList.link == '/slots' && availableProvider !== null && availableProvider.SL?.length > 0) ||
                      (menuList.link == '/fishing' && availableProvider !== null && availableProvider.FS?.length > 0) ||
                      (menuList.link == '/sports' && availableProvider !== null && availableProvider.SP?.length > 0) ||
                      (menuList.link == '/esports' && availableProvider !== null && availableProvider.ES?.length > 0) ||
                      (menuList.link == '/lottery' && availableProvider !== null && availableProvider.LT?.length > 0) ||
                      (menuList.link == '/crash-game' && availableProvider !== null && availableProvider.CG?.length > 0) ||
                      (menuList.link == '/table' && availableProvider !== null && availableProvider.TB?.length > 0)"
                      [ngClass]="{'position-relative': menuList.new }">
                      <div *ngIf="menuList.new" class="prediction-notification"></div>
                      <a [routerLink]="menuList.link" (click)="navigateTo(menuList.link)">
                        <div>{{ menuList.name }}</div>
                      </a>
                    </div>
                  </ng-container>
                  <ng-template #othersMenu2>
                    <div routerLinkActive="current" class="more-dropdown-item" [ngClass]="{'position-relative': menuList.link == '/prediction' || menuList.new }">
                      <div *ngIf="(menuList.link == '/prediction' && !isPredictionTabClicked) || menuList.new" class="prediction-notification"></div>
                      <a [routerLink]="menuList.link" (click)="navigateTo(menuList.link)">
                        <div>{{ menuList.name }}</div>
                      </a>
                    </div>
                  </ng-template>
                </ng-container>
              </ng-container>
            </div>
          </li>

          <!-- <li routerLinkActive="current" *ngIf="availableProvider !== null &&  availableProvider.LC?.length > 0">
            <a routerLink="/casino">
              <div>{{ 'Casino' | translate }}</div>
            </a>
          </li>
          <li routerLinkActive="current" *ngIf="availableProvider !== null && availableProvider.SL?.length > 0">
            <a routerLink="/slots">
              <div>{{ 'Slots' | translate }}</div>
            </a>
          </li>
          <li routerLinkActive="current" *ngIf="availableProvider !== null &&  availableProvider.LT?.length > 0">
            <a routerLink="/lottery">
              <div>{{ 'Lottery' | translate }}</div>
            </a>
          </li>
          <li routerLinkActive="current" *ngIf="availableProvider !== null && availableProvider.SP?.length > 0">
            <a routerLink="/sports">
              <div>{{ 'Sports' | translate }}</div>
            </a>
          </li>
          <li routerLinkActive="current" *ngIf="availableProvider !== null &&  availableProvider.FS?.length > 0">
            <a routerLink="/fishing">
              <div>{{ 'Fishing' | translate }}</div>
            </a>
          </li>
          <li routerLinkActive="current" *ngIf="availableProvider !== null &&  availableProvider.ES?.length > 0">
            <a routerLink="/esports">
              <div>{{ 'Esports' | translate }}</div>
            </a>
          </li>
          <li routerLinkActive="current" *ngIf="availableProvider !== null &&  availableProvider.CG?.length > 0">
            <a routerLink="/crash-game">
              <div>{{ 'Crash Game' | translate }}</div>
            </a>
          </li>
          <li routerLinkActive="current" *ngIf="availableProvider !== null &&  availableProvider.TB?.length > 0">
            <a routerLink="/table">
              <div>{{ 'Table' | translate }}</div>
            </a>
          </li>
          <li routerLinkActive="current">
            <a routerLink="/promotion">
              <div>{{ 'Promotion' | translate }}</div>
            </a>
          </li>
          <li routerLinkActive="current" class="position-relative">
            <div *ngIf="!isPredictionTabClicked" class="prediction-notification"></div>
            <a routerLink="/prediction">
              <div>{{ 'Prediction' | translate }}</div>
            </a>
          </li>
          <li routerLinkActive="current">
            <a routerLink="/vip" title="VIP">
              <div>{{ 'VIP' | translate }}</div>
            </a>
          </li>
          <li routerLinkActive="current">
            <a routerLink="/referral">
              <div>{{ 'Referral' | translate }}</div>
            </a>
          </li>
          <li>
            <a (click)="navToAff()">
              <div>{{ 'Affiliate' | translate }}</div>
            </a>
          </li> -->
        </ul>
      </div>
    </div>
    <ng-container *ngIf="(isLoggedIn$ | async) && showKYCGateKeeper && memberKYC">
      <div *ngIf="memberKYC !== 'pro'" class="top-navigation-bar d-flex justify-content-center gate-keeper-top-bar">
        <div class="row vertical-row height-50 max-width-1320">
          <a routerLink="/member/profile" *ngIf="memberGroup !== 'PRO-VIP'">
            {{ 'This feature is only available on Pro account.' | translate }}
            {{ 'Verify now to unlock!' | translate }}
          </a>
          <a *ngIf="memberGroup == 'PRO-VIP'">
            {{ 'This feature is not available for your member group.' | translate }}
          </a>
        </div>
      </div>
    </ng-container>
  </div>
</header>
<app-swal-alert [message]="messages$ | async" [success]="true" [flashStyle]="true" [tick]="false" color="#0067c6" [timer]="300000000"></app-swal-alert>
