// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Component
import { MemberComponent } from './member.component';
import { WalletComponent } from './pages/wallet/wallet.component';
import { TransferComponent } from './pages/transfer/transfer.component';
import { DepositComponent } from './pages/deposit/deposit.component';
import { WithdrawComponent } from './pages/withdraw/withdraw.component';
import { HistoryComponent } from './pages/history/history.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { GamePasswordComponent } from './pages/game-password/game-password.component';
import { BankComponent } from './pages/bank/bank.component';
import { MessageComponent } from './pages/message/message.component';
import { ReferralsComponent } from './pages/referrals/referrals.component';
import { RewardsComponent } from './pages/rewards/rewards.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { VerificationComponent } from './pages/verification/verification.component';
// Component - Dialog
import { VerificationModalComponent } from './dialog/verification-modal/verification-modal.component';
import { MemberStatusModalComponent } from './dialog/member-status-modal/member-status-modal.component';
import { ClaimRewardModalComponent } from './dialog/claim-reward-modal/claim-reward-modal.component';
import { ReceiptModalComponent } from './dialog/receipt-modal/receipt-modal.component';
import { UploadReceiptModalComponent } from './dialog/upload-receipt-modal/upload-receipt-modal.component';
import { TransactionReceiptModalComponent } from './dialog/transaction-receipt-modal/transaction-receipt-modal.component';
import { ViewReceiptModalComponent } from './dialog/view-receipt-modal/view-receipt-modal.component';
// Module
import { SharedModule } from '@shared/shared.module';
import { ViewsModule } from '@views/views.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
// Other
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PromotionCancelModalComponent } from './dialog/promotion-cancel-modal/promotion-cancel-modal.component';
import { CryptoWalletDetailsModalComponent } from './dialog/crypto-wallet-details-modal/crypto-wallet-details-modal.component';
import { PaymentDropdownComponent } from './pages/deposit/payment-dropdown/payment-dropdown.component';
import { WalletDeactivateGuard } from '@core/guards/wallet-deactivate.guard';
import { CopyInputComponent } from './pages/deposit/copy-input/copy-input.component';

const routes: Routes = [
  {
    path: '',
    component: MemberComponent,
    children: [
      {
        path: 'wallet',
        component: WalletComponent,
        data: {
          title: 'My Wallet'
        },
        canDeactivate: [WalletDeactivateGuard],
      },
      {
        path: 'transfer',
        component: TransferComponent,
        data: {
          title: 'Transfer'
        }
      },
      {
        path: 'deposit',
        component: DepositComponent,
        data: {
          title: 'Deposit'
        }
      },
      {
        path: 'withdraw',
        component: WithdrawComponent,
        data: {
          title: 'Withdraw'
        }
      },
      {
        path: 'history',
        component: HistoryComponent,
        data: {
          title: 'History'
        }
      },
      {
        path: 'profile',
        component: ProfileComponent,
        data: {
          title: 'Profile'
        }
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        data: {
          title: 'ChangePassword'
        }
      },
      {
        path: 'game-password',
        component: GamePasswordComponent,
        data: {
          title: 'GamePassword'
        }
      },
      {
        path: 'bank',
        component: BankComponent,
        data: {
          title: 'Bank'
        }
      },
      {
        path: 'message',
        component: MessageComponent,
        data: {
          title: 'Message'
        }
      },
      {
        path: 'referrals',
        component: ReferralsComponent,
        data: {
          title: 'Referrals'
        }
      },
      {
        path: 'reward',
        component: RewardsComponent,
        data: {
          title: 'Reward'
        }
      },
      {
        path: 'settings',
        component: SettingsComponent,
        data: {
          title: 'Settings'
        }
      },
      {
        path: 'verification',
        component: VerificationComponent,
        data: {
          title: 'Verification'
        }
      },
      {
        path: '**', redirectTo: '/member/wallet;tab=deposit'
      }
    ]
  }
];

@NgModule({
  declarations: [
    MemberComponent,
    WalletComponent,
    TransferComponent,
    DepositComponent,
    WithdrawComponent,
    HistoryComponent,
    ProfileComponent,
    ChangePasswordComponent,
    GamePasswordComponent,
    BankComponent,
    MessageComponent,
    ReferralsComponent,
    RewardsComponent,
    SettingsComponent,
    VerificationComponent,
    PaymentDropdownComponent,
    CopyInputComponent,

    // Dialog
    VerificationModalComponent,
    MemberStatusModalComponent,
    ClaimRewardModalComponent,
    ReceiptModalComponent,
    UploadReceiptModalComponent,
    TransactionReceiptModalComponent,
    ViewReceiptModalComponent,
    PromotionCancelModalComponent,
    CryptoWalletDetailsModalComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    ViewsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatNativeDateModule,
    MatTooltipModule,
    NgxDaterangepickerMd.forRoot()
  ],
  exports: [RouterModule],
  entryComponents: [
    VerificationModalComponent,
    MemberStatusModalComponent,
    ClaimRewardModalComponent,
    ReceiptModalComponent,
    UploadReceiptModalComponent,
    TransactionReceiptModalComponent,
    ViewReceiptModalComponent,
    PromotionCancelModalComponent,
    CryptoWalletDetailsModalComponent,
  ],
  providers: [
    // DatePipe,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ]
})
export class MemberModule {
  // constructor(
  //   private eds: EntityDefinitionService,
  //   private entityDataService: EntityDataService,
  //   private historyHttpService: HistoryHttpService,
  //   private rewardsHttpService: RewardsHttpService
  // ) {
  //   eds.registerMetadataMap(entityMetadata);
  //   entityDataService.registerService('BankAccount', bankAccountHttpService);
  //   entityDataService.registerService('History', historyHttpService);
  //   entityDataService.registerService('Rewards', rewardsHttpService);
  // }
}
