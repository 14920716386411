<!-- Popup -->
<div [ngClass]="{ 'kyc-notification-dialog-div': !minimizeDialog }">
  <div
    id="kyc-notification-dialog"
    class="modal-box kyc-notification-dialog"
    [ngClass]="{ 'kyc-notification-dialog-minimize': minimizeDialog }"
  >
    <div class="modal1">
      <div class="kyc-notification-dialog-content">
        <button
          title="Close (Esc)"
          type="button"
          class="mfp-close"
          (click)="onTogglePopup()"
        >
          ×
        </button>
        <span [innerHTML]="svg.vipStarIcon | safeHtml"></span>
        <h4>
          {{ "YOUR VIP BRONZE UPGRADE IS UNCLAIMED" | translate | uppercase }}
        </h4>
        <p class="description-text">
          {{ "Verify your details to PRO status & get upgraded automatically!" | translate }}
        </p>

        <div class="d-flex justify-content-around">
          <button
            type="button"
            class="button-orange w-50 ml-1 bottom-action-btn"
            (click)="onRedirect()"
          >
            {{ "VERIFY NOW" | translate | uppercase }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Ballon -->
<div
  *ngIf="showBallon"
  id="kyc-noti-floating-bubble"
  class="kyc-noti-floating-div d-flex justify-content-center align-items-center"
  [ngClass]="{
    'hide-ballon': !showBallon || !minimizeDialog
  }"
>
  <span class="badge-btn" matBadge="×" matBadgeColor="warn" (click)="onCloseBallon()" matBadgeOverlap="true"></span>
  <div class="kyc-noti-floating-content" (click)="onTogglePopup()">
    <div class="spin" [innerHTML]="svg.kycVipBubbleIcon | safeHtml"></div>
  </div>
</div>
