import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-daily-limit-otp',
  templateUrl: './popup-daily-limit-otp.component.html',
  styleUrls: ['./popup-daily-limit-otp.component.scss']
})
export class PopupDailyLimitOtpComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PopupDailyLimitOtpComponent>,
  ) { }

  ngOnInit(): void {
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }


}
