// Angular
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
// Service
import { DeviceDetectorService } from 'ngx-device-detector';
// RxJS
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileGuard implements CanActivate {
  constructor(
    private deviceDetectorService: DeviceDetectorService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.deviceDetectorService.isMobile()) {
      return false;
    }
    return true;
  }

}
