<!-- Modal -->
<div class="block mx-auto modal-box">
  <div class="modal1" id="modal-signup">
    <button title="Close (Esc)" type="button" class="mfp-close" (click)="onCloseDialog()">×</button>
    <div class="block mx-auto ma-box boxshadow">
      <div class="row">
        <div class="col-12">
          <div class="content bank-modal-content">
            <app-bank-modal [onDialog]="true" [existing_bank_name]="data.existing_bank_name"
              [is_deposit]="data.is_deposit"
              [is_transfer]="data.is_transfer"
              [is_withdraw]="data.is_withdraw"
              [bank_type]="data.bank_type">
            </app-bank-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
