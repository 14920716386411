<!-- Modal -->
<div class="modal1" id="modal-signup">
  <button title="Close (Esc)" type="button" class="mfp-close" (click)="onCloseDialog()">×</button>
  <div class="block mx-auto ma-box boxshadow">
    <div class="row">
      <div class="col-12">
        <div class="content">
          <app-transfer-modal [isPerProvider]="true"></app-transfer-modal>
        </div>
      </div>
    </div>
  </div>
</div>

