// Service
import { PortalMemberProfileHttpService } from '@core/services/portal-member-profile-http.service';
// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordMsgResolver implements Resolve<boolean> {
  constructor(
    private portalMemberProfileHttpService: PortalMemberProfileHttpService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | any {
    const userToken = localStorage.getItem('user_token');
    const skipUrls = ['/member/change-password'];

    if (userToken && !skipUrls.includes(state.url)) {
      return this.portalMemberProfileHttpService.getProfile();
    }
  }
}
