<!-- Modal -->
<div class="block mx-auto modal-box">
   <div class="modal1" id="modal-signup">
      <button title="Close (Esc)" type="button" class="mfp-close m-t-15 m-r-15" (click)="onCloseDialog()">×</button>
      <div class="row">
         <div class="col-lg-12">
            <div class="p-20">
               <div class="fancy-title">
                  <span [innerHTML]="svg.registerIcon  | safeHtml" class="title-image"></span>
                  <h4>{{ 'Registration' | translate }}</h4>
               </div>
               <hr>
               <form class="row mb-0" [formGroup]="form" (ngSubmit)="submit(this.form.value)" id="signup-modal-form" name="signup-modal-form">
                  <div class="col-12 form-group">
                     <label>{{ 'Username' | translate }}</label>
                     <!-- Added id and username for browser to collect credentials correctly to save. Tested on : Chrome, Edge and Firefox -->
                     <input 
                        type="text"
                        class="form-control data-hj-allow"
                        formControlName="username"
                        (keyup)="onCheckUsernameFormat(form.value.username);"
                        (input)="toLowerCaseInput('username', $event); resetServerValidation()"
                        (focusout)="onServerSideUsernameValidation(form.value.username)"
                        name="username"
                        id="username"
                        [ngClass]="{'custom-field-valid': (form.value.username.length > 5 && form.value.username.length < 17) && (!usernameFormatValid.special && form.value.username.length > 0) && usernameFormatValid.consist && serverSideValidations.username, 'invalid-input': (serverSideValidated && !serverSideValidations.username) }"
                        #focusInput
                     />
                     <ng-container>
                       <p *ngIf="(serverSideValidated && !serverSideValidations.username) else elseTemplate" class="d-flex justify-content-between mb-0 err-msg text-danger">
                          <small for="register-form-username">{{ "Username is already taken" | translate }}</small>
                       </p>
                     </ng-container>
                     <ng-template #elseTemplate>
                        <ng-container *ngIf="(checkValidity && form.controls.username.errors) || (!form.get('username').pristine && !form.get('username').valid)">
                           <p class="d-flex justify-content-between mb-0 err-msg" [ngClass]="form.value.username.length > 5 && form.value.username.length < 17 ? 'text-success' : 'text-danger'">
                              <small for="register-form-username">{{ 'Between 6 to 16 characters' | translate }}</small>
                              <i *ngIf="form.value.username.length > 5 && form.value.username.length < 17" class="fa fa-check position-relative text-success"></i>
                           </p>
                           <p class="d-flex justify-content-between mb-0 err-msg" [ngClass]="!usernameFormatValid.special && form.value.username.length > 0 ? 'text-success' : 'text-danger'">
                              <small for="register-form-username">{{ 'No special characters allowed' | translate }}</small>
                              <i *ngIf="!usernameFormatValid.special && form.value.username.length > 0" class="fa fa-check position-relative text-success"></i>
                           </p>
                           <p class="d-flex justify-content-between mb-0 err-msg" [ngClass]="usernameFormatValid.consist ? 'text-success' : 'text-danger'">
                              <small for="register-form-username">{{ 'Consists of letters and numbers OR letters only' | translate }}</small>
                              <i *ngIf="usernameFormatValid.consist" class="fa fa-check position-relative text-success"></i>
                           </p>
                        </ng-container>
                     </ng-template>
                  </div>
                  <div class="col-12 form-group">
                     <label for="register-form-password">{{ 'Phone Number' | translate }} </label>
                     <div class="row">
                        <div class="col-3">
                           <mat-select [(value)]="selected" class="form-control p-0" [ngClass]="selected" formControlName="country_code" disableOptionCentering>
                              <mat-option *ngFor="let dropDownCountry of dropDownCountries" [value]="dropDownCountry.value" (click)="onChangeCountry(dropDownCountry.value)">
                                 <img src='{{dropDownCountry.img}}'>
                              </mat-option>
                           </mat-select>
                        </div>
                        <div class="col-9 p-l-0">
                           <!-- Removed the type value to prevent Firefox get phone numbers as username to save -->
                           <input type="" class="form-control" formControlName="phone_number" (keypress)="numberOnly($event)"  [ngClass]="{'custom-field-valid': form.get('phone_number').valid}"/>
                           <ng-container *ngIf="(checkValidity && form.controls.phone_number.errors) || (!form.get('phone_number').pristine && !form.get('phone_number').valid)">
                              <p class="d-flex justify-content-between mb-0 text-danger">
                                <small for="register-form-phone-number">{{ 'Invalid phone number format' | translate }}</small>
                              </p>
                           </ng-container>
                        </div>
                     </div>
                  </div>
                  <div class="col-12 form-group">
                     <label for="register-form-password">{{ 'Password' | translate }} </label>
                     <div class="input-icons-password ">
                        <i class="icon-eye-password" (click)="onPasswordMask('password')" [innerHTML]="passwordInput.icon | safeHtml"></i>
                        <!-- Added id and username for browser to collect credentials correctly. Tested on : Chrome, Edge and Firefox -->
                        <input [type]="passwordInput.type" class="form-control" formControlName="password" [ngClass]="{'password-invalid': checkValidity && form.controls.password.errors, 'custom-field-valid': form.get('password').valid }" (keyup)="onCheckPassword()" id="password" name="password"/>
                        <ng-container *ngIf="(checkValidity && form.controls.password.errors) || (!form.get('password').pristine && !form.get('password').valid)">
                           <p class="d-flex justify-content-between mb-0 text-danger">
                              <small for="register-form-password">{{ 'At least 6 characters' | translate }}</small>
                           </p>
                        </ng-container>
                     </div>
                  </div>
                  <div class="col-12 form-group">
                     <label for="register-form-password">{{ 'Confirm Password' | translate }} </label>
                     <div class="input-icons-password ">
                        <i class="icon-eye-password" (click)="onPasswordMask('confirmPassword')" [innerHTML]="confirmPasswordInput.icon | safeHtml"></i>
                        <input [type]="confirmPasswordInput.type" class="form-control" formControlName="confirm_password" [ngClass]="{'password-invalid': checkValidity && form.controls.confirm_password.errors, 'custom-field-valid': passwordMatched && form.controls.confirm_password.valid }" (keyup)="onCheckPassword()"/>
                        <ng-container *ngIf="!passwordMatched || (checkValidity && form.controls.confirm_password.errors)">
                           <p class="d-flex justify-content-between mb-0 text-danger">
                              <small for="register-form-confirm-password">{{ 'Password not matched' | translate }}</small>
                           </p>
                         </ng-container>
                     </div>
                  </div>
                  <div class="col-12 form-group" *ngIf="showRef">
                     <label>{{ 'Referral' | translate }} ({{ 'Optional' | translate }})</label>
                     <input type="" class="form-control" formControlName="ref" [readonly]="isRefSet"/>
                  </div>
                  <div class="col-12 form-group" *ngIf="showAgent">
                     <label>{{ 'Agent' | translate }} ({{ 'Optional' | translate }})</label>
                     <input type="" class="form-control" formControlName="agent" [readonly]="true">
                   </div>
                  <div class="col-12 form-group" *ngIf="showAff">
                     <label>{{ 'Affiliate' | translate }} ({{ 'Optional' | translate }})</label>
                     <input type="" class="form-control" formControlName="aff" [readonly]="true">
                   </div>
                  <div class="col-12 form-group">
                     <p [innerHTML]="'By clicking Join, I confirm that I am at least 18 years of age and that I accept the <a class=\'tnc-link\'>Terms & Conditions</a>.' | translate"></p>
                     <button type="submit" class="button-blue w-100" value="" >{{ 'Join' | translate }}</button>
                  </div>
                  <div class="row m-l-0 m-r-0 w-100">
                     <div class="col-lg-12 text-center p-r-0">
                        <span>{{ 'Existing Member?' | translate }}
                           <a routerLink="/login" class="auth-text" (click)="onCloseDialog()">{{ 'Login Now' | translate }}</a>
                        </span>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </div>
   </div>
</div>
<!--End modal-->
<app-swal-alert [message]="messages$ | async" [success]="true" [register]="true"></app-swal-alert>