<div class="block mx-auto ma-box boxshadow">
    <div class="modal1">
        <button title="Close (Esc)" type="button" class="mfp-close m-t-15 m-r-15" (click)="onCloseDialog()">×</button>
        <div class="row">
            <div class="col-lg-12">
                <div class="p-20">
                    <div class="fancy-title m-t-10 m-b-10">
                        <h4>{{ 'CONNECTION ERROR' | translate }}</h4>
                    </div>
                    <hr>
                    <label class="mt-2 mb-4">{{ 'There is no internet connection. Please check your internet connection.' | translate }}</label>
                    <div class="col-12 form-group p-0">
                        <button class="button-red w-100" (click)="onReload()">{{ 'Try Again' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>