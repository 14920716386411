import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Currency } from '@core/models/currency.model';
import { Observable, Subscription, zip, of, Subject, forkJoin } from 'rxjs';
import { PortalPromotion } from '@core/models/portal-promotion.model';
import { PortalTransferHttpService } from '@core/services/portal-transfer-http.service';
import { Store, select } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { loggedUser } from '@core/store/auth/auth.selectors';
import { map, tap, catchError } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TransferWrapperModalComponent } from '@shared/transfer-wrapper-modal/transfer-wrapper-modal.component';
import { DashboardHttpService } from '@core/services/dashboard-http.service';
import { Wallet } from '@core/models/wallet.model';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';


@Component({
  selector: 'app-transfer-modal',
  templateUrl: './transfer-modal.component.html',
  styleUrls: ['./transfer-modal.component.scss']
})
export class TransferModalComponent implements OnInit, OnDestroy {

  form: FormGroup;
  currency: Currency;
  providersDropdown = [];
  providersList = []; // with Balance
  promotions$: Observable<PortalPromotion[]>;
  promoList = [{
    name: 'No Promotion',
    id: 0
  }];

  wallet$: Observable<Wallet>;
  isDisabled: boolean = false;
  showLoading: boolean = false;
  count: number;
  walletBalance = this.dashboardHttpService.memberBalance$;
  balance: any;
  providerBalance = new Subscription();
  currentGameCode: string = null;
  Currency: string;
  disableButton = false;
  amountButton = [50, 100, 300, 500];

  messages$ = this.transferService.messages$;
  isSuccess = this.transferService.isSuccess;
  isAllin = false;

  currencyCode = localStorage.getItem('country_code');
  showIDRRate = new Subject<boolean>();
  providerName: string;

  @ViewChild('promotionRef')
  promotionRef: ElementRef;

  @Input()
  isPerProvider = false;

  noPromotion = true;
  submitted = false;
  transferToFromValidate = false;
  gameCategoryList: { rows: [any], total_game_balance: 0 }; // With balance
  selectedPromotion = null;

  private subscriptions: Subscription[] = [];
  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { provider: any, gameUrl: string, balance?: number },
    public dialogRef: MatDialogRef<TransferWrapperModalComponent>,
    private dashboardHttpService: DashboardHttpService,
    private transferService: PortalTransferHttpService,
    private store: Store<AppState>,
    public loadingBar: LoadingBarService,
    private router: Router,
    private eventEmitterService: EventEmitterService,
    private gameCategoryHttpService: GameCategoryHttpService,
    private walletHttpService: WalletHttpService,
    private gameProviderHttpService: GameProviderHttpService,
  ) {
    // Subscribe to get push update for games balance
    this.subscriptions.push(
      this.gameCategoryHttpService.gameProvidersBalance.subscribe(res => {
        this.gameCategoryList = res;
        if (this.gameCategoryList.rows.length > 0) {
          this.setProviderList(this.gameCategoryList);
        }
      })
    );
  }

  ngOnInit() {
    this.store.pipe(select(loggedUser), map(res => res.currency)).subscribe(res => this.currency = res);
    this.formInit();

    if (this.data.balance) {
      this.balance = this.data.balance;
      this.form.patchValue({
        ...this.form,
        amount: this.data.balance
      });
    } else {
      this.subscriptions.push(
        this.walletHttpService.memberBalance.subscribe(res => {
          this.balance = Number.parseFloat(res.balance + "").toFixed(2);
          this.form.patchValue({
            ...this.form,
            amount: this.balance
          });
        })
      );
    }

    if (this.isPerProvider) {
      this.transferService.getPromotionsByCode(this.data.provider.game_provider_code).subscribe(res => {
        this.promoList = res;
      });
    } else {
      this.dropdownInit(this.gameCategoryList);
    }

    this.subscriptions.push(
      this.eventEmitterService.buttonEmitter.subscribe(() => {
        this.disableButton = false;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  onChangeAmount(amount: number) {
    this.form.patchValue({ amount });
  }

  onMultiplicand(formAmount: any, selectedPromo: any) {
    var finalBonus = this.onBonusAmount(formAmount, selectedPromo);
    var targetTypeValue = selectedPromo['target_type_name'].includes('incl') ? formAmount : 0; // 0 for exclude
    const totalTransferBonusAmount = ((Number(targetTypeValue) + Number(finalBonus) + Number(this.isPerProvider ? this.data.provider.balance : this.gameCategoryList['rows'].find(x => +x.id === +this.data.provider.game_provider_id)['balance'])).toFixed(3).slice(0, -1));
    var finalTotalTransferBonusAmount = Number(totalTransferBonusAmount);
    return Number(finalTotalTransferBonusAmount);
  }

  onTotalTurnOver(transferAmount: number, selectedPromo: any) {
    const finalTotalTransferBonusAmount = this.onMultiplicand(transferAmount, selectedPromo);
    return Number(finalTotalTransferBonusAmount) * Number(selectedPromo.target_multiplier);
  }

  onBonusAmount(formAmount: any, selectedPromo: any) {
    const totalBonus = (formAmount * selectedPromo.bonus_rate).toFixed(3).slice(0, -1);
    var finalBonus = +totalBonus;
    if (+selectedPromo['max_bonus'] < +totalBonus && +selectedPromo['max_bonus'] > 0) {
      finalBonus = +selectedPromo['max_bonus'];
    }
    return finalBonus;
  }

  onSelectPromotion(event: Event) {
    const promoId = +(event.target as HTMLSelectElement).value;
    if (promoId === 0) {
      this.selectedPromotion = null;
    } else {
      this.selectedPromotion = this.promoList.find(x => +x.id === promoId);
    }
  }

  submit() {
    this.submitted = true;
    if (this.form.valid && this.transferToFromValidate && this.form.get('amount').value >= 0) {
      //this.disableButton = true;
      this.loadingBar.start();
      this.transfer().subscribe((res) => {
        this.isSuccess = res.success;
        this.messages$.next(res.message);
        this.loadingBar.complete();
        //this.disableButton = false;
        this.submitted = false;
        if (res.success && this.data.gameUrl !== null && this.data.gameUrl !== undefined) {
          if (this.data.gameUrl === '/launch') {
            this.router.navigate([]).then(() => { window.open('/launch', '_blank'); });
          } else {
            localStorage.setItem('playtechCategory', 'singleGame');
            localStorage.setItem('gameURL', this.data.gameUrl);
            this.router.navigate([]).then(() => { window.open('/launch', '_blank'); });
          }
        }
      });
    }
  }

  onAllIn(provider: number) {
    this.disableButton = true;
    this.loadingBar.start();
    const transfers = [];
    this.providersList.map(row => {
      if (row.balance > 0.00) {
        transfers.push(this.transferService.transferBy(row.id, 'all', 2));
      }
    });
    if (transfers.length > 0) {
      zip(...transfers).subscribe(() => {
        this.onTransferType(true, provider);
        this.disableButton = false;
      });
    } else {
      this.onTransferType(true, provider);
      this.disableButton = false;
    }
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onTransferChange(event?: any, type?: string) {
    if (type === 'from') {
      const provider = this.providersDropdown.find(e => e.id === +event.srcElement.value);
      this.form.patchValue({
        amount: provider ? provider.balance : this.balance
      });
    } else {
      if ((+this.form.value.transfer_to === 6 || +this.form.value.transfer_to === 4) && this.currencyCode === 'ID') {
        this.showIDRRate.next(true);
        this.providerName = this.providersDropdown.find(e => e.id === +event.srcElement.value).name;
      } else {
        this.showIDRRate.next(false);
      }
    }
    const transferTo = +this.form.value.transfer_to;
    if (transferTo === 0) {
      this.form.patchValue({ promotion_id: 0 });
      this.noPromotion = true;
    } else {
      this.transferService.getPromotions(+this.form.value.transfer_to).subscribe(res => {
        this.promoList = res;
      });
      this.form.patchValue({ promotion_id: 0 });
      this.noPromotion = false;
    }
  }

  private transfer() {
    this.onTransferType();
    const data = (this.isAllin) ? { ...this.form.value, amount: 'all' } : this.form.value;
    return this.transferService.transfer(data).pipe(
      tap(res => {
        this.isSuccess = res.success;
        this.messages$.next(res.message);
        this.loadingBar.complete();
        this.selectedPromotion = null;
        if (res.success && !this.isPerProvider) {
          this.dropdownInit(null);
          this.noPromotion = true;
          this.walletHttpService.getMemberBalance().subscribe(
            (wallet) => this.dashboardHttpService.refreshMemberBalance(wallet.balance)
          );
          this.isAllin = false; // reset
        }
      }),
      catchError((error) => {
        this.dropdownInit(null);
        this.noPromotion = true;
        this.walletHttpService.getMemberBalance().subscribe(
          (wallet) => this.dashboardHttpService.refreshMemberBalance(wallet.balance)
        );
        this.isAllin = false; // reset
        throw error;
      })
    );
  }

  private onTransferType(allIn?: boolean, allInProvider?: number) {
    if (allIn) {
      this.isAllin = true;
      this.form.patchValue({
        promotion_id: 0,
        transfer_from: 0,
        transfer_to: allInProvider
      });
      return this.transfer().subscribe();
    }
  }

  private dropdownInit(gameCategoryList: any) {
    this.providersList = []; // Reset
    this.gameCategoryHttpService.getGameCategoryListWithPromo().subscribe();
  }

  private setProviderList(gameCategoryList: any) {
    this.providersDropdown = gameCategoryList.rows;
    gameCategoryList.rows.map(provider => {
      this.providersList.push({
        id: provider.id,
        code: provider.code,
        image_path: provider.image_path,
        balance: provider.balance,
        status: provider.status
      });
    });
  }

  private formInit() {
    if (this.isPerProvider) {
      this.transferToFromValidate = true;
      this.form = new FormGroup({
        amount: new FormControl(null),
        promotion_id: new FormControl(0),
        transfer_from: new FormControl(0),
        transfer_to: new FormControl(this.data.provider.game_provider_id),
      });
    } else {
      this.form = new FormGroup({
        transfer_from: new FormControl(0, [Validators.required]),
        transfer_to: new FormControl(0, [Validators.required]),
        amount: new FormControl(null, Validators.compose([Validators.required, Validators.min(0)])),
        promotion_id: new FormControl(0)
      });
      this.form.valueChanges.subscribe(res => {
        if (res.transfer_from === res.transfer_to) {
          this.transferToFromValidate = false;
        } else {
          this.transferToFromValidate = true;
        }
      });
    }
  }

  clearForm(event?: Event) {
    this.formInit();
  }

}
