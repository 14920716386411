// Model
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// RxJS
import { map, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Module
import { PredictionModule } from '../pages/prediction/prediction.module';

@Injectable({
  providedIn: 'root'
})
export class PredictionHttpService {
  messages$ = new Subject<any[]>();
  isSuccess: boolean;
  pagination: Pagination;
  constructor(private http: HttpClient) { }

  getEventInfo(pageParam: string) {
    return this.http
      .get<ApiResponse>(`/event/getinfo${pageParam}`).pipe(
        tap(res => {
          if (res.data.my_predictions) {
            this.paginationInit(res);
          }
        }),
        map((res) => res.data));
  }

  getTeams(data: { eventId?: any, eventCode?: string }) {
    return this.http
      .get<ApiResponse>(`/event/teams?${data.eventId != undefined ? 'event_id=' + data.eventId : 'event_code=' + data.eventCode}&paginate=false`)
      .pipe(map((res) => res.data.rows));
  }

  makeAPrediction(prediction: any) {
    return this.http
      .post<ApiResponse>(`/event/makeprediction`, prediction)
      .pipe(map(res => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      }))
  }

  getEventResult(data: { eventId?: number, eventCode?: string }) {
    return this.http
      .get<ApiResponse>(`/event/winners?${data.eventId != undefined ? 'event_id=' + data.eventId : 'event_code=' + data.eventCode}&paginate=false`)
      .pipe(map((res) => res.data));
  }

  getEventList(member_account_id) {
    let params = ''
    if (member_account_id !== null) {
      params = `?member_account_id=${member_account_id}`;
    }
    return this.http
      .get<ApiResponse>(`/eventlist${params}`)
      .pipe(map((res) => res.data.rows));
  }

  recordMemberClick(member_account_id: number, data: { event_id?: any, event_code?: string }) {
    let params = { member_account_id: member_account_id };
    data?.event_id != undefined ? params['event_id'] = data.event_id : params['event_code'] = data.event_code;
    return this.http.post<ApiResponse>(`/event/click`, params)
  }

  checkMemberPredictionTab(member_account_id: number) {
    return this.http
      .get<ApiResponse>(`/event/tab?member_account_id=${member_account_id}`)
      .pipe(map((res) => res.data.rows));
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.my_predictions.paginations;
    }
  }
}
