<div class="block mx-auto ma-box modal-box">
    <div class="modal1" id="modal-login">
       <div class="demomode-notice">
           <div class="notice-body">
               <p class="icon-wrapper"><span class="icon">i</span></p>
               <h6>{{ 'Demo Mode' | translate }}</h6>
               <p>{{ 'Demo Mode allows you to try out our slot games for free. Any wins or losses while in demo mode will not affect your Main Wallet‘s balance.' | translate }}</p>

               <!-- No need for translation -->
               <button class="button-blue w-100 m-b-10" (click)="onConfirm(isShowAgain)">OK</button>
           </div>
           <p *ngIf="data.onSwitch" class="do-not-show-again d-flex justify-content-center my-2" (click)="onNotShowAgain()">{{ 'Do not show again' | translate }}</p>
       </div>
    </div>
 </div>