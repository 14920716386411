import { Component, OnInit, AfterViewInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { AppState } from '@store/reducers';
import { Store, select } from '@ngrx/store';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { tap, map } from 'rxjs/operators';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';
import { PortalTransferHttpService } from '@core/services/portal-transfer-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { formatNumber } from '@angular/common';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { WalletHttpService } from '@core/services/wallet-http.service';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-sub-slot',
  templateUrl: './sub-slot.component.html',
  styleUrls: ['./sub-slot.component.scss']
})
export class SubSlotComponent implements OnInit {
  svg: any = svgIconList;

  routeSub = new Subscription();
  providerCode: string;
  slotCode: string;
  gameType: string;
  isLogged: boolean;
  form: FormGroup;

  @Input()
  gameCode: string;

  @Input()
  header: string;

  @Input()
  logo: string;

  @Input()
  miniURL: string;

  @Input()
  username: string;

  @Input()
  password: string;

  @Input()
  appId: string;

  @Input()
  appFileName: string;

  @Input()
  appBalance: number;

  @Input()
  appGameProvider: number;

  isSuccess = false;
  messages$ = this.transferService.messages$;
  transferedAmount: number;

  walletBalance: number;

  currency = JSON.parse(localStorage.getItem('user_data')).currency;
  private subscription = new Subscription();

  @ViewChild('tooltipUsername') tooltipUsername: MatTooltip;
  @ViewChild('tooltipPassword') tooltipPassword: MatTooltip;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    public translateService: TranslateService,
    private transferService: PortalTransferHttpService,
    public loadingBar: LoadingBarService,
    private gameCategoryHttpService: GameCategoryHttpService,
    private walletHttpService: WalletHttpService,
  ) { }

  ngOnInit() {
    this.pageInit();
    this.formInit();
  }

  onCopyText(type: string) {
    if (type === 'username') {
      this.tooltipUsername.show();
      setTimeout(() => this.tooltipUsername.hide(), 2000);
    } else {
      this.tooltipPassword.show();
      setTimeout(() => this.tooltipPassword.hide(), 2000);
    }
  }

  changeGamePassword() {
    this.router.navigate(['/member/game-password'], { state: { appId: this.appId, appFileName: this.appFileName, appUsername: this.username, gameCode: this.gameCode } });
  }

  onTransfer() {
    this.transferedAmount = +this.form.value.amount;
    this.loadingBar.start();
    this.transferService.transfer(this.form.value).pipe(
      tap(res => {
        this.isSuccess = res.success;
        this.messages$.next(res.message);
        this.loadingBar.complete();

        this.gameCategoryHttpService.getGameCategoryListWithPromo(true).subscribe();
        this.walletHttpService.getMemberBalance().subscribe();
      })
    ).subscribe();
  }

  onResetForm() {
    const walletBalance = this.walletHttpService.memberBalance.value.balance;
    this.walletBalance = walletBalance;
    this.appBalance = Number(this.appBalance) + this.transferedAmount;
    this.form.patchValue({
      amount: walletBalance
    });
  }

  formInit() {
    this.form = new FormGroup({
      transfer_from: new FormControl(0),
      transfer_to: new FormControl(0, [Validators.required]),
      amount: new FormControl(null, Validators.compose([Validators.required, Validators.min(0)])),
      promotion_id: new FormControl(0)
    });

    this.form.patchValue({
      amount: this.appGameProvider['wallet_balance'],
      transfer_to: this.appGameProvider['game_provider_id']
    });
    this.walletBalance = this.appGameProvider['wallet_balance'];
    this.loadingBar.complete();
  }

  private pageInit() {
    this.store.pipe(select(isLoggedIn))
      .pipe(
        tap(res => {
          if (!res) {
            this.router.navigateByUrl('/');
          }
        })
      ).subscribe();
    window.scroll(0, 300);

    // TODO: Hold for now
    // this.routeSub = this.route.params.subscribe((params) => {
    //   this.providerCode = params.providerCode;
    //   this.slotCode = params.code;
    // });
  }

}
