import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-esports-footer',
  templateUrl: './esports-footer.component.html',
  styleUrls: ['./esports-footer.component.scss']
})
export class EsportsFooterComponent implements OnInit {

  @Input()
  provider: string;
  constructor() { }

  ngOnInit(): void {
  }

}
