// RxJS
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Model
import { Pagination } from '@core/models/pagination.model';
import { PortalMemberProfile } from '@core/models/portal-member-profile.model';
import { ProfilePassword } from '@core/models/profile-password.model';
import { PortalVerification } from './../models/portal-verification.model';
import { ApiResponse } from '@core/models/api-response.model';

@Injectable({
  providedIn: 'root'
})
export class PortalMemberProfileHttpService {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  isSuccess: boolean;

  constructor(private http: HttpClient) { }

  getAll(): Observable<PortalMemberProfile[]> {
    return this.http.get<ApiResponse>('/profile').pipe(
      map(res => res.data),
      tap(res => {
        this.updateLocalUserData(res.rows);
      })
    );
  }

  getWithQuery(pageParam: string): Observable<PortalMemberProfile[]> {
    return this.http.get<ApiResponse>(`/profile?paginate=false${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data)
    );
  }

  getProfile(): Observable<PortalMemberProfile> {
    return this.http.get<ApiResponse>('/profile').pipe(
      map(res => res.data.rows),
      tap(res => {
        this.updateLocalUserData(res);
      })
    );
  }

  updateProfile(profile: PortalMemberProfile): Observable<PortalMemberProfile> {
    return this.http.put<ApiResponse>(`/profile`, profile).pipe(
      tap(res => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      }),
      map(res => res.data.rows)
    );
  }

  updatePassword(profilePassword: ProfilePassword) {
    return this.http.post<ApiResponse>(`/changepassword`, profilePassword).pipe(
      tap(res => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      }),
      map(res => res.data)
    );
  }

  requestEmail(email: string) {
    return this.http.get<ApiResponse>(`/otp/email/request?email=${email}`);
  }

  updateICVerification(verification: PortalVerification) {
    return this.http.put<ApiResponse>(`/profile/verification`, verification).pipe(
      tap(res => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      }),
      map(res => res.data)
    );
  }

  updateSmartTransfer() {
    return this.http.post<ApiResponse>(`/transfer/smartTransfer`, null).pipe(
      tap(res => {
        this.messages$.next(res.message);
        const userData = JSON.parse(localStorage.getItem('user_data'));
        const newUserData = {
          ...userData,
          smart_transfer: res.data.rows.smart_transfer
        };
        localStorage.setItem('user_data', JSON.stringify(newUserData));
      }),
      map(res => res.data.rows)
    );
  }

  updateSettings(data: any) {
    return this.http.post<ApiResponse>(`/settings`, data).pipe(
      tap(res => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      }),
    );
  }

  updateLocalUserData(res: any) {
    const userData = JSON.parse(localStorage.getItem('user_data'));
    if (userData !== null) {
      const newUserData = {
        ...userData,
        KYC: res.KYC,
        otp_status: res.otp_status,
        email: res.email,
        email_status: res.email_status,
        email_status_name: res.email_status_name,
        date_of_birth: res.date_of_birth,
        member_group: res.member_group,
        mobile: res.mobile,
        mobile_status: res.mobile_status,
        mobile_status_name: res.mobile_status_name

      };
      localStorage.setItem('user_data', JSON.stringify(newUserData));
    }
  }

  updateShowRewards(data: any) {
    return this.http.post<ApiResponse>(`/showreward`, data).pipe(
      tap(res => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      }),
    );
  }

  getRewardDetails() {
    return this.http.get<ApiResponse>(`/getrewarddetails`).pipe(
      map(res => res.data),
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}
