<button title="Close (Esc)" type="button" class="mfp-close" (click)="onCloseNotification()">×</button>
<div style="cursor: pointer;">
    <div *ngIf="data.title" class="text-blue text-uppercase font-weight-bold header">
        {{ data.title | translate }}
    </div>

    <div class="message-box">
        <h6 *ngIf="data.subtitle" class="msg-title">{{ data.subtitle }}</h6>
        <div class="msg-content" [innerHTML]="sanitize(data.message || data.content)"></div>

        <div *ngIf="data.CTA" class="m-t-10 d-flex flex-column align-items-center">
            <button class="btn button-blue" style="width: 80%" (click)="onNavigate()">{{ data.buttonConfirm | titlecase | translate }}</button>
            <p class="msg-cancel" (click)="onCloseNotification()">{{ data.buttonCancel | translate }}</p>
        </div>
    </div>
</div>