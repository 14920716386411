import * as CryptoJS from 'crypto-js';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AesService {
  constructor() {}

  /**
   * Wrapper for decrypt(), with reqSignKey as secret key.
   *
   * @param encryptedData The encrypted data that contains the encrypted string and IV
   * @returns The decrypted string.
   */
  decryptWithReqSignKey(encryptedData: string | number): string {
    const secretKey = environment.reqSignKey; // Make sure this matches backend APP_REQ_SIGN_KEY

    return this.decrypt(encryptedData, secretKey);
  }

  /**
   * Decrypts data encrypted by CMS using AES CBC mode.
   *
   * @param encryptedData The encrypted data that contains the encrypted string and IV
   * @param key The secret key used for decryption.
   * @returns The decrypted string.
   */
  decrypt(encryptedData: string | number, key: string): string {
    if (!encryptedData || encryptedData == 0 || !key) {
      return '';
    }

    try {
      const encryptedDataWithIV = CryptoJS.enc.Base64.parse(encryptedData);

      // extract the first 16 bytes (128 bits) for the IV
      const iv = CryptoJS.lib.WordArray.create(encryptedDataWithIV.words.slice(0, 4));

      // extract the remaining bytes as the ciphertext
      const encryptedCipherText = CryptoJS.lib.WordArray.create(encryptedDataWithIV.words.slice(4));

      // decrypt the message using AES-256-CBC
      const decrypted = CryptoJS.AES.decrypt(
          { ciphertext: encryptedCipherText },
          CryptoJS.SHA256(key),
          {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
          }
      );

      return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error("OS decryption failed: ", error);
      return ''; // Return empty string or handle as needed
    }
  }
}
