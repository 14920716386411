// Angular
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
// Enum
import { MessageStatus } from '@core/enums/message-status.enum';
import { RewardsStatus } from '@core/enums/rewards-status.enum';
// Model
import { Pagination } from '@core/models/pagination.model';
import { Rewards } from '@core/models/rewards.model';
// Service
import { LoadingBarService } from '@ngx-loading-bar/core';
import { RewardsHttpService } from '../../services/rewards-http.service';
// Component
import { ClaimRewardModalComponent } from '../../dialog/claim-reward-modal/claim-reward-modal.component';
// RxJS
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EventEmitterService } from '@core/services/event-emitter.service';

@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss']
})
export class RewardsComponent implements OnInit {

  status = MessageStatus;
  rewards$: Observable<Rewards[]>;

  dataSub$ = new BehaviorSubject<any>(null);
  pagination: Pagination;
  pageSize = 7;
  page = 1;
  maxSize = 5;
  params = '';
  activeTab = 1;
  rewardStatus = RewardsStatus

  constructor(
    private loadingBar: LoadingBarService,
    private rewardDataService: RewardsHttpService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private eventEmitterService: EventEmitterService,
  ) { }

  ngOnInit() {
    this.reload();
    this.pagination = this.rewardDataService.pagination;
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    return this.getRewards(page, pageSize, params);
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClaim(row: Rewards) {
    if(this.activeTab){
      const dialogRef = this.dialog.open(ClaimRewardModalComponent, {
        width: 'auto',
        data: {
          reward: row
        }
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        if(result === true) {
          this.onViewPageBy(this.page); 
          //update reward bubble
          this.eventEmitterService.onUpdateRewardBubble();
        }
      });
    }
  }

  onSelectTab(tab: number) {
    this.activeTab = tab;
    this.getRewards(1, this.pageSize, this.params)
  }

  private getRewards(page: number, pageSize: number, params: string) {
    this.loadingBar.start();
    params = '&active=' + this.activeTab;
    this.rewards$ = this.rewardDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        res.forEach(item => {
          item.expiry_datetime = this.datePipe.transform(item.expiry_datetime, 'yyyy-MM-dd HH:mm');
        });
        this.pagination = this.rewardDataService.pagination;
        this.dataSub$.next(res);
        this.loadingBar.complete();
      })
    );
  }

  private reload() {
    this.onViewPageBy(this.page);
  }

}
