// Service
import { SystemSettingHttpService } from '../services/system-setting-http.service';
// Angular
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceResolver implements Resolve<boolean> {

  constructor(
    private systemSettingHttpService: SystemSettingHttpService
  ) { }

  resolve(): Observable<any> | any {
    return this.systemSettingHttpService.getMaintenance();
  }
}
