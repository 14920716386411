// Model
import { ApiResponse } from '@core/models/api-response.model';
import { BankAccount } from '@core/models/bank-account.model';
// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BankAccountHttpService {

  messages$ = new Subject<any[]>();
  isSuccess: boolean;

  constructor(private http: HttpClient) {
  }

  getAll(): Observable<BankAccount[]> {
    return this.http.get<ApiResponse>(`/bankaccount`).pipe(
      map(res => res.data.rows)
    );
  }


  getWithQuery(pageParam: string): Observable<BankAccount[]> {
    return this.http.get<ApiResponse>(`/bankaccount${pageParam}`).pipe(
      map(res => res.data.rows)
    );
  }

  addBank(bankAccount: BankAccount) {
    return this.http.post<ApiResponse>(`/bankaccount`, bankAccount).pipe(
      map(res => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      })
    );
  }

  deleteBank(bankAccountId: number): Observable<BankAccount> {
    return this.http.delete<ApiResponse>(`/bankaccount/${bankAccountId}`).pipe(
      tap((res: any) => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      })
    );
  }

  reupload(bankAccountId: number, document_path?: string): Observable<BankAccount> {
    return this.http.put<ApiResponse>(`/bankaccount/reupload/${bankAccountId}`, document_path).pipe(
      tap((res: any) => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      })
    );
  }

  getverificationDetails(): Observable<any> {
    return this.http.get<ApiResponse>(`/bankaccount/verificationdetails`).pipe(
      map(res => res.data)
    );
  }

  getUnseenVerificationRecords(): Observable<any> {
    return this.http.get<ApiResponse>(`/bankaccount/unseenverificationrecords`).pipe(
      map(res => res.data)
    );
  }

  updateVerificationSeen(bankAccountId: number) {
    return this.http.put<ApiResponse>(`/bankaccount/updateseen/${bankAccountId}`, null).pipe();
  }
}
