import { catchError, delay, tap } from 'rxjs/operators';
import { ViewChildren, QueryList, ElementRef } from '@angular/core';
// Component
import { VerificationModalComponent } from '../../dialog/verification-modal/verification-modal.component';
import { BankWrapperModalComponent } from '@shared/bank-wrapper-modal/bank-wrapper-modal.component';
import { EwalletVerificationRejectionComponent } from '@shared/ewallet-verification-rejection/ewallet-verification-rejection.component';
// Service
import { MemberBankHttpService } from '@core/services/member-bank-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { WithdrawtHttpService } from '@views/modules/member/services/wtihdraw-http.service';
import { DashboardHttpService } from '@core/services/dashboard-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TranslateService } from '@ngx-translate/core';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { MemberDepositWithdrawLastOptionHttpService } from '@core/services/member-deposit-withdraw-last-option-http.service';
// Angular
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// RxJS
import { Subscription, of } from 'rxjs';
// Third Party
import Swal from 'sweetalert2';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { BankHttpService } from '@core/services/bank-http.service';
import { BankMaintenanceHourService } from '@core/services/bank-maintenance-hour.service';
import { CryptoWalletHttpService } from '@core/services/crypto-wallet-http.service';
import { CryptoWalletModalComponent } from '@shared/crypto-wallet-modal/crypto-wallet-modal.component';
import svgIconList from 'assets/icons.json';
import { BankTypes } from '@core/enums/bank-type.enum';

@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.scss']
})
export class WithdrawComponent implements OnInit, OnDestroy {
  svg: any = svgIconList;

  @ViewChildren('focusInput') focusInput: QueryList<ElementRef>;
  memberBanks: any;
  bankAccount = {
    name: '',
    number: null,
    mobile_number: '',
    nric_fin: '',
    unique_entity_name: '',
    virtual_payment_address: '',
    token: '',
    network: '',
    wallet_nickname: '',
    wallet_address: '',
  };
  checkValidity = false;

  currencyCode = '$';
  countryCode = localStorage.getItem('country_code');
  //amountButton = [50, 100, 200, 300, 500, 1000];
  amountPercentageButton = ['25%', '50%', '75%', 'MAX'];
  amountButton = [];
  amountPercentage = [0.25, 0.50, 0.75, 1];
  IDRAmount: string;

  form: FormGroup;
  messages$ = this.withdrawtHttpService.messages$;
  isSuccess = this.withdrawtHttpService.isSuccess;
  withdrawalLimit$ = {};
  existing_bank_name = [];
  selectedBank: any = null;
  memberBankAccounts = JSON.parse(sessionStorage.getItem('member_bank_accounts'));
  memberCryptoWallets = JSON.parse(sessionStorage.getItem('member_crypto_accounts'));;
  selectedCryptoWallet = null;
  cryptoExchangeRate = [];

  disableButton = false;

  bankMaintenanceHour: any = [];
  allBank = JSON.parse(sessionStorage.getItem('all_bank'));
  gmt: any;
  currentTime: any;
  currentDayOfWeek: any;
  currentDate: any;
  bank_ids = [];
  yesterdayTime: any;
  yesterday: any;
  yesterdayDate: any;
  paymentMethod = [
    { id: BankTypes.BANK, name: 'Bank' },
    { id: BankTypes.EWALLET, name: 'Ewallet' },
    { id: BankTypes.CRYPTO, name: 'Crypto' }
  ];
  selectedMethodName: any;
  selectedMethodId: any;
  selectedBankType: any = 'Bank';
  amount: number;
  
  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private withdrawtHttpService: WithdrawtHttpService,
    public loadingBar: LoadingBarService,
    private dashboardHttpService: DashboardHttpService,
    private router: Router,
    public dialog: MatDialog,
    private eventEmitterService: EventEmitterService,
    private memberBankHttpService: MemberBankHttpService,
    private translateService: TranslateService,
    private walletHttpService: WalletHttpService,
    private withdrawLastOptionService: MemberDepositWithdrawLastOptionHttpService,
    private bankHttpService: BankHttpService,
    private bankMaintenanceHourService: BankMaintenanceHourService,
    private cryptoWalletHttpService: CryptoWalletHttpService
  ) { }

  async ngOnInit() {
    if (JSON.parse(localStorage.getItem('user_data')).currency !== null) {
      this.currencyCode = JSON.parse(localStorage.getItem('user_data')).currency.code;
      this.countryCode = localStorage.getItem('country_code');
    }
    this.formInit();
    //this.balance = Number.parseFloat(this.walletHttpService.memberBalance.value.balance + "").toFixed(2);

    this.getBankAccount();
    this.getCryptoWallets();
    if (this.eventEmitterService.bankListVar === undefined) {
      this.eventEmitterService.bankListVar = this.eventEmitterService.reloadBankListEmitter.subscribe((name: string) => {
        this.memberBankHttpService.getMemberBanks().subscribe(res => {
          this.memberBankAccounts = res;
          localStorage.setItem('member_bank_accounts', this.memberBankAccounts);
          sessionStorage.setItem('member_bank_accounts', JSON.stringify(this.memberBankAccounts));
          
          let filteredMemberBank = [];
          this.existing_bank_name = [];

          if (this.memberBankAccounts) {
            Object.keys(this.memberBankAccounts).forEach(key1 => {
              if (this.memberBankAccounts[key1]['bank_code'] == null) {
                this.existing_bank_name.push(this.memberBankAccounts[key1]['bank_name']);
                this.memberBankAccounts[key1]['showBank'] = true;
                filteredMemberBank.push(this.memberBankAccounts[key1]);
              }
              else {
                Object.keys(this.allBank).forEach(key2 => {
                  if (this.memberBankAccounts[key1]['bank_code'] == this.allBank[key2]['code']) {
                    this.existing_bank_name.push(this.memberBankAccounts[key1]['bank_name']);
                    this.memberBankAccounts[key1]['showBank'] = this.allBank[key2]['showBank'];
                    filteredMemberBank.push(this.memberBankAccounts[key1]);
                  }
                })
              }
            });

            this.memberBankAccounts = filteredMemberBank;
            this.memberBanks = this.memberBankAccounts.filter(x => x.bank_type == this.selectedMethodId);
          }

          this.onCloseDialog();
        })
      });
    }

    this.eventEmitterService.openPhoneVerificationEmitter.subscribe((name: string) => {
      this.openDialogBy(VerificationModalComponent, null, null, JSON.parse(localStorage.getItem('user_data')).otp_status);
    });

    this.checkVerification();

    if (this.allBank == null || this.allBank.length == 0) {
      this.bankHttpService.getAllBanks().subscribe(async res => {
        this.allBank = res;
        sessionStorage.setItem('all_bank', JSON.stringify(res))

        await this.assignMemberBank();
      });
    } else {
      await this.assignMemberBank();
    }

    //not displaying e-wallet payment method when there is no active e-wallet
    let hasEWallet = this.allBank && this.allBank.some(bank => bank.bank_type === BankTypes.EWALLET);
    if (!hasEWallet) {
      this.paymentMethod = this.paymentMethod.filter(method => method.id !== BankTypes.EWALLET);
    }

    //not displaying crypto payment method when there is no active crypto token
    let hasCrypto = this.allBank && this.allBank.some(bank => bank.bank_type === BankTypes.CRYPTO);
    if (!hasCrypto) {
      this.paymentMethod = this.paymentMethod.filter(method => method.id !== BankTypes.CRYPTO);
    }

    //display last option for user
    this.withdrawLastOptionService.getLastOption(2).subscribe((lastOption) => {
      //if last option exist
      if (lastOption && hasEWallet) {
        let indexOfLastMemberBankOption = this.memberBanks.findIndex(item => item.id === lastOption.member_bank_account_id)

        if (indexOfLastMemberBankOption !== -1) {
          if (this.memberBanks[indexOfLastMemberBankOption].bank_type == BankTypes.BANK) {
            this.onSelectPaymentMethod({ id: BankTypes.BANK, name: 'Bank' }, true);
          }
          else {
            this.onSelectPaymentMethod({ id: BankTypes.EWALLET, name: 'Ewallet' }, true);
          }
          this.onSelectBank(this.memberBanks[indexOfLastMemberBankOption]);
          this.memberBanks = this.memberBankAccounts.filter(x => x.bank_type == this.memberBanks[indexOfLastMemberBankOption].bank_type);
        }
        else {
          this.onSelectPaymentMethod({ id: BankTypes.BANK, name: 'Bank' });
        }
      }
      else {
        this.onSelectPaymentMethod({ id: BankTypes.BANK, name: 'Bank' });
      }

      // Get and set withdrawal limit
      this.checkLimit();
    })

    document.getElementById('withdrawal-amount-3').style.background = '#374A65';
  }

  // await this function to finish first then only set the bank dropdown to avoid unfilter bank dropdown bug
  assignMemberBank(): Promise<void> {
    return new Promise((resolve) => {
      JSON.parse(sessionStorage.getItem('all_bank')).forEach(merchant_banks => {
        if (merchant_banks['id']) {
          this.bank_ids.push(merchant_banks['id']);
        }
      });
      this.bankMaintenanceHourService.getBankMaintenanceHours(this.bank_ids).subscribe(res => {
        localStorage.setItem('bank_maintenance_hour', JSON.stringify(res));
        this.bankMaintenanceHour = JSON.parse(localStorage.getItem('bank_maintenance_hour'))['rows'];
        let filteredMemberBank = [];
        // Update bank maintenance hours
        this.allBank.forEach(item => {
          let maintenanceHour = this.bankMaintenanceHour.find(x => x.bank_id == item.id);
          this.gmt = '';
          if (maintenanceHour != undefined) {
            item['enable_all_day'] = maintenanceHour['enable_all_day'];
            item['enable_monday'] = maintenanceHour['enable_monday'];
            item['enable_tuesday'] = maintenanceHour['enable_tuesday'];
            item['enable_wednesday'] = maintenanceHour['enable_wednesday'];
            item['enable_thursday'] = maintenanceHour['enable_thursday'];
            item['enable_friday'] = maintenanceHour['enable_friday'];
            item['enable_saturday'] = maintenanceHour['enable_saturday'];
            item['enable_sunday'] = maintenanceHour['enable_sunday'];
            item['all_day'] = maintenanceHour['all_day'];
            item['monday'] = maintenanceHour['monday'];
            item['tuesday'] = maintenanceHour['tuesday'];
            item['wednesday'] = maintenanceHour['wednesday'];
            item['thursday'] = maintenanceHour['thursday'];
            item['friday'] = maintenanceHour['friday'];
            item['saturday'] = maintenanceHour['saturday'];
            item['sunday'] = maintenanceHour['sunday'];
            item['display'] = maintenanceHour['display'];
            item['availability_control'] = maintenanceHour['availability_control'];
            this.gmt = maintenanceHour['gmt'];
          }
          this.isBankMaintenance(item, this.gmt);
        });
        sessionStorage.setItem('all_bank', JSON.stringify(this.allBank));
        if (this.memberBankAccounts) {
          Object.keys(this.memberBankAccounts).forEach(key1 => {
            if (this.memberBankAccounts[key1]['bank_code'] == null) {
              this.memberBankAccounts[key1]['showBank'] = true;
              filteredMemberBank.push(this.memberBankAccounts[key1]);
            } else {
              Object.keys(this.allBank).forEach(key2 => {
                if (this.memberBankAccounts[key1]['bank_code'] == this.allBank[key2]['code']) {
                  this.memberBankAccounts[key1]['showBank'] = this.allBank[key2]['showBank'];
                  filteredMemberBank.push(this.memberBankAccounts[key1]);
                }
              });
            }
          });
          this.memberBankAccounts = filteredMemberBank;
          this.memberBanks = this.memberBankAccounts;
        }
        resolve();
      })
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
  }

  onSelectBank(value: any) {
    if (value.showBank != false) {
      this.selectedBank = value;

      if (value.bank_name.toLowerCase() === 'paynow') {
        this.bankAccount = {
          name: this.selectedBank?.account_name,
          number: null,
          mobile_number: this.selectedBank?.mobile_number,
          nric_fin: this.selectedBank?.nric_fin,
          unique_entity_name: this.selectedBank?.unique_entity_name,
          virtual_payment_address: this.selectedBank?.virtual_payment_address,
          token: '',
          network: '',
          wallet_nickname: '',
          wallet_address: '',
        };
      } else {
        this.bankAccount = {
          name: this.selectedBank?.account_name,
          number: this.selectedBank?.account_number,
          mobile_number: '',
          nric_fin: '',
          unique_entity_name: '',
          virtual_payment_address: '',
          token: '',
          network: '',
          wallet_nickname: '',
          wallet_address: '',
        };
      }
      this.form.patchValue({
        bank_account_id: value.id,
      });
    }
  }

  onAddBank(bank_type: any) {
    this.openDialogBy(BankWrapperModalComponent, this.existing_bank_name, bank_type);
  }

  onAddCryptoWallet() {
    this.openDialogBy(CryptoWalletModalComponent, undefined, undefined, undefined, true).then((result) => {
      if (result === true) {
        this.getCryptoWallets();
      }
    });
  }

  onSelectAmount(i: number) {
    document.getElementById('withdrawal-amount-0').style.background = '';
    document.getElementById('withdrawal-amount-1').style.background = '';
    document.getElementById('withdrawal-amount-2').style.background = '';
    document.getElementById('withdrawal-amount-3').style.background = '';
    document.getElementById('withdrawal-amount-0').style.color = '';
    document.getElementById('withdrawal-amount-1').style.color = '';
    document.getElementById('withdrawal-amount-2').style.color = '';
    document.getElementById('withdrawal-amount-3').style.color = '';
    let selectedAmount = this.amountButton[i];
    if (this.countryCode.toUpperCase() === 'ID') {
      this.IDRAmount = selectedAmount.toString();
    }
    this.form.patchValue({ amount: selectedAmount });
    document.getElementById('withdrawal-amount-' + i).style.background = 'var(--mainColor1)';
    document.getElementById('withdrawal-amount-' + i).style.color = 'var(--mainColor26)';
  }

  onSave() {
    if (localStorage.getItem('verified_number') === 'false') {
      this.openDialogBy(VerificationModalComponent, null, null, JSON.parse(localStorage.getItem('user_data')).otp_status);
    } else {
      this.checkValidity = true;
      if (this.form.valid) {
        this.disableButton = true;
        this.loadingBar.start();
        const data = {
          ...this.form.value
        };
        Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
        data.account_type = this.selectedMethodName != "Crypto" ? 1 : 2;

        this.withdrawtHttpService.add(data).pipe(
          catchError(e => {
            this.disableButton = false;
            this.loadingBar.complete();
            this.checkValidity = false;
            throw e;
          })
        ).subscribe(() => {
          this.disableButton = false;
          this.loadingBar.complete();
          this.checkValidity = false;
          this.isSuccess = this.withdrawtHttpService.isSuccess;
          if (this.isSuccess) {
            // withdraw tab to history = 2
            const tab = 1;
            this.router.navigate(['/member/history', { tab }]);
            this.walletHttpService.getMemberBalance().subscribe(
              (wallet) => this.dashboardHttpService.refreshMemberBalance(wallet.balance)
            );
          }
        });
      }
    }
  }

  onCloseDialog() {
    this.formInit();
    this.selectedBank = null;
    this.bankAccount = {
      name: '',
      number: null,
      mobile_number: '',
      nric_fin: '',
      unique_entity_name: '',
      virtual_payment_address: '',
      token: '',
      network: '',
      wallet_nickname: '',
      wallet_address: '',
    };
  }

  private getBankAccount() {
    if (this.memberBankAccounts === null) {
      this.dropdownHttpService.memberBanks.subscribe(res => {
        sessionStorage.setItem('member_bank_accounts', JSON.stringify(res));
        this.memberBankAccounts = res;
        this.setBankAccount(res);
      });
    } else {
      this.setBankAccount(this.memberBankAccounts);
    }
  }

  private getCryptoWallets() {
    this.cryptoWalletHttpService.getAll().pipe(
      tap(res => {
        this.memberCryptoWallets = res;
      })
    ).subscribe();
  }

  private setBankAccount(account: any) {
    if (account.length === 0 && this.memberCryptoWallets.length === 0 && localStorage.getItem('verified_number') !== 'false') {
      Swal.fire({
        html: '<div class="msg-icon">' + this.svg.dangerIcon + '</div>' +
          '<div class="text-center m-t-20">' +
          '<ul><li>' + this.translateService.instant('Please add bank account') + '</li></ul>' +
          '</div>',
        confirmButtonText: this.translateService.instant('Okay'),
      }).then(result => {
        if (result.value) {
          this.dialog.open(BankWrapperModalComponent, {
            width: 'auto',
            data: {
              is_withdraw: true
            }
          });
        } else {
          this.router.navigate(['/member/wallet', { tab: 'deposit' }]);
        }
      });
    }
    account.forEach((item) => {
      this.existing_bank_name.push(item.bank_name);
    })
    this.memberBanks = account;
  }

  private checkVerification() {
    if (localStorage.getItem('verified_number') === 'false') {
      this.openDialogBy(VerificationModalComponent, null, null, JSON.parse(localStorage.getItem('user_data')).otp_status);
    }
  }

  private openDialogBy(componentRef: any, existing_bank_name?: any, bank_type?: any, can_request_otp?: boolean, onDialog?: boolean, verification?: boolean, verificationDetails?: any) {
    if (verification == true) {
      var dialogRef = this.dialog.open(componentRef, {
        width: 'auto',
        data: {
          bankAccountId: verificationDetails.id,
          bank_name: verificationDetails.bank_name,
          account_number: verificationDetails.account_number,
          reason: verificationDetails.reason,
          verificationSetting: verificationDetails
        }
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.eventEmitterService.onUpdateBankList();
          this.eventEmitterService.onReloadBankList();
          this.eventEmitterService.onUpdateVerificationEmitter();
        }
        this.onCloseDialog();
      });
    } else {
      return new Promise<boolean>((resolve, reject) => {
        const dialogRef = this.dialog.open(componentRef, {
          width: 'auto',
          data: {
            can_request_otp: can_request_otp,
            existing_bank_name: existing_bank_name,
            bank_type: bank_type,
            onDialog: onDialog
          }
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result === true) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    }
  }

  private formInit() {
    this.form = new FormGroup({
      bank_account_id: new FormControl(null, [Validators.required]),
      amount: new FormControl(null, Validators.compose([Validators.required, Validators.min(0.01)]))
    });
    of(null).pipe(
      delay(0), tap(() => this.focusInput.first.nativeElement.focus()
      )).subscribe();
  }

  isBankMaintenance(item: any, gmt: any) {
    item['showBank'] = true;

    if (gmt != '' && (item['availability_control'] == 2 || item['availability_control'] == 3)) {
      let hours_minutes = gmt.split(':');
      let posOrNeg = (hours_minutes[0][0] == '-') ? '-' : '+'; // get positive or negative
      let hours = hours_minutes[0];
      let minutes = hours_minutes[1];

      // get the user current time
      this.currentTime = moment.utc().add(parseInt(hours), 'hours').add(parseInt(posOrNeg + minutes), 'minutes');
      this.currentTime = moment(this.currentTime.format('HH:mm'), 'HH:mm');
      this.currentDayOfWeek = this.currentTime.clone().locale('en').format('dddd').toLowerCase();
      this.currentDate = this.currentTime.clone().locale('en').format('YYYY-MM-DD').toLowerCase();

      // get the user yesterday time
      this.yesterdayTime = moment.utc().add(parseInt(hours), 'hours').add(parseInt(posOrNeg + minutes), 'minutes');
      this.yesterdayTime = moment(this.yesterdayTime.format('HH:mm'), 'HH:mm').subtract(1, "days");
      this.yesterday = this.yesterdayTime.clone().locale('en').format('dddd').toLowerCase();
      this.yesterdayDate = this.yesterdayTime.clone().locale('en').format('YYYY-MM-DD').toLowerCase();

      // if enable_all_day is on
      if (item['enable_all_day'] == true) {
        for (let i = 0; i < item['all_day'].length; i++) {
          // get the maintenance hour time (from & to)
          let all_day = item['all_day'][i];

          // since all day, check yesterday overlap case
          // eg: today is 2024-02-02 00:02
          // it compare 2024-02-01 23:00 -> 2024-02-02 06:00
          let yesterday_from = moment(moment.utc(`${this.yesterdayDate} ${all_day.from}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
          let yesterday_to = moment(moment.utc(`${this.yesterdayDate} ${all_day.to}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');

          // get tomorrow date for to if to time is smaller than from time
          if (yesterday_to < yesterday_from) {
            yesterday_to = yesterday_to.add(1, 'days');
          }

          console.log(yesterday_from.toString())
          console.log(yesterday_to.toString());

          // check if the current bank is in maintenance
          // check if the current bank is in maintenance
          if (this.currentTime.isBetween(yesterday_from, yesterday_to) || this.currentTime.isSame(yesterday_from) || this.currentTime.isSame(yesterday_to)) {
            item['showBank'] = false;
            break;
          }

          // since all day, check today overlap case
          // eg: today is 2024-02-02 00:02
          // it compare 2024-02-02 23:00 -> 2024-02-03 06:00
          let today_from = moment(moment.utc(`${this.currentDate} ${all_day.from}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
          let today_to = moment(moment.utc(`${this.currentDate} ${all_day.to}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');

          // get tomorrow date for to if to time is smaller than from time
          if (today_to < today_from) {
            today_to = today_to.add(1, 'days');
          }

          // check if the current bank is in maintenance
          if (this.currentTime.isBetween(today_from, today_to) || this.currentTime.isSame(today_from) || this.currentTime.isSame(today_to)) {
            item['showBank'] = false;
            break;
          }
        }
      }

      // if yesterday checkbox is enabled
      if (item['enable_' + this.yesterday] == true) {
        for (let i = 0; i < item[this.yesterday].length; i++) {
          // get the maintenance hour time (from & to)
          let dayOfWeek = item[this.yesterday][i];

          let from = moment(moment.utc(`${this.yesterdayDate} ${dayOfWeek.from}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
          let to = moment(moment.utc(`${this.yesterdayDate} ${dayOfWeek.to}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');

          // get tomorrow date for to if to time is smaller than from time
          if (to < from) {
            to = to.add(1, 'days');
          }

          // check if the current bank is in maintenance
          if (this.currentTime.isBetween(from, to) || this.currentTime.isSame(from) || this.currentTime.isSame(to)) {
            item['showBank'] = false;
            break;
          }
        }
      }

      // if today checkbox is enabled
      if (item['showBank'] == true && item['enable_' + this.currentDayOfWeek] == true) {
        for (let i = 0; i < item[this.currentDayOfWeek].length; i++) {
          // get the maintenance hour time (from & to)
          let dayOfWeek = item[this.currentDayOfWeek][i];

          let from = moment(moment.utc(`${this.currentDate} ${dayOfWeek.from}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
          let to = moment(moment.utc(`${this.currentDate} ${dayOfWeek.to}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');

          // get tomorrow date for to if to time is smaller than from time
          if (to < from) {
            to = to.add(1, 'days');
          }

          // check if the current bank is in maintenance
          if (this.currentTime.isBetween(from, to) || this.currentTime.isSame(from) || this.currentTime.isSame(to)) {
            item['showBank'] = false;
            break;
          }
        }
      }
    }
  }

  onSelectPaymentMethod(value: any, lastOption?: boolean) {
    if (lastOption != true) {
      this.memberBanks = this.memberBankAccounts.filter(x => x.bank_type == value.id);
    }
    this.selectedMethodName = value.name;
    this.selectedMethodId = value.id;

    if (value.id == BankTypes.BANK) {
      this.selectedBankType = 'Bank';
    }
    else if (value.id == BankTypes.EWALLET) {
      this.selectedBankType = 'E-Wallet';
    } else if (value.id == BankTypes.CRYPTO) {
      this.selectedBankType = 'Crypto Wallet';
    }

    this.selectedBank = null;
    this.selectedCryptoWallet = null;
    this.bankAccount = {
      name: '',
      number: null,
      mobile_number: '',
      nric_fin: '',
      unique_entity_name: '',
      virtual_payment_address: '',
      token: '',
      network: '',
      wallet_nickname: '',
      wallet_address: '',
    };

    this.form.patchValue({
      bank_account_id: null,
      amount: null,
    });
  }

  onSelectCryptoWallets(value) {
    this.selectedCryptoWallet = value;
    this.bankAccount = {
      name: '',
      number: null,
      mobile_number: '',
      nric_fin: '',
      unique_entity_name: '',
      virtual_payment_address: '',
      token: value.token,
      network: value.network,
      wallet_nickname: value.wallet_nickname ? value.wallet_nickname : '-',
      wallet_address: value.wallet_address,
    };
    this.form.patchValue({
      bank_account_id: value.id,
    });
  }

  getCryptoCurrencyRate(limit, type = 1): number {
    if (!this.cryptoExchangeRate) {
      return 0;
    }
    const exchangeRateEntry = this.cryptoExchangeRate.find(entry => entry.Token === this.selectedCryptoWallet?.token);
    if (exchangeRateEntry) {
      if (type == 1) {
        return limit / exchangeRateEntry.Estimate_Withdrawal_Credit;
      } else {
        return exchangeRateEntry.Estimate_Withdrawal_Credit;
      }
    }
    return 0;
  }

  showToken(selectedCryptoWallet) {
    const filteredWallets = this.allBank.filter(bank => {
      return bank.bank_type === 6 && bank.name.toLowerCase() === selectedCryptoWallet.token.toLowerCase();
    });

    if (filteredWallets.length > 1) {
      return `${selectedCryptoWallet.token} - ${selectedCryptoWallet.network}`;
    } else if (filteredWallets.length === 1) {
      return selectedCryptoWallet.token;
    } else {
      return "";
    }
  }

  onSelect(value?: any) {
    value.status != null && value.verification_settings_field.length > 0 && value.status == 2 ? this.openDialogBy(EwalletVerificationRejectionComponent, null, null, null, null, true, value) : this.onSelectBank(value); 
  }

  checkLimit() {
    this.withdrawtHttpService.getLimit().subscribe(res => {
      this.withdrawalLimit$ = res;

      // update amount validator
      const minWithdrawal = +this.withdrawalLimit$['min'];
      const maxWithdrawal = +this.withdrawalLimit$['max'];

      const validators = [
        Validators.required,
        ...(minWithdrawal !== 0 ? [Validators.min(minWithdrawal)] : []),
        ...(maxWithdrawal !== 0 ? [Validators.max(maxWithdrawal)] : [])
      ];
      this.form.controls['amount'].setValidators(validators);
      this.form.controls['amount'].updateValueAndValidity();

      this.cryptoExchangeRate = res.cr_exchange_rate;

      this.walletHttpService.getMemberBalance().subscribe(res => {
        this.amount = res.balance;
        if( +this.amount >= minWithdrawal ) {
          if( maxWithdrawal > 0 && +this.amount > maxWithdrawal ) {
            this.amount = maxWithdrawal;
          }
          this.form.patchValue({
            amount: +this.amount
          });
        } else {
          this.form.patchValue({
            amount: null
          });
        }

        if (this.amount != 0 && +this.amount > +this.withdrawalLimit$['min']) {
          this.amountPercentage.forEach((percentage) => {
            if (Math.round(this.amount * percentage) > +this.amount) {
              this.amountButton.push(this.amount);
            } else {
              this.amountButton.push(Math.round(this.amount * percentage));
            }
          });
        }
        this.amount = res.balance;
      });
    });
  }
}
