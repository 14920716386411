
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-count-down',
  templateUrl: './count-down.component.html',
  styleUrls: ['./count-down.component.scss']
})
export class CountDownComponent implements OnInit, OnDestroy {

  @Input()
  dateStart: string;

  @Input()
  dateEnd: string;

  @Input()
  name: string;

  @Input()
  display: any;

  @Input()
  gameProvider: string;

  @Input()
  maintenanceTime: any;

  @Input()
  contentHash: string;

  @Output()
  complete = new EventEmitter<any>();


  millisecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  secondsInAMinute = 60;

  startDateInSeconds: number;
  endDateInSeconds: number
  currentTimeInSeconds: number;

  currentTime: Date;
  timediff: number;

  seconds: number;
  minutes: number;
  hours: number;
  days: number;

  durationData: {
    days: string;
    hours: string;
    minutes?: string;
    seconds?: string;
  };

  private subscription: Subscription;

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.subscription = interval(1000).subscribe(x => { this.getTimeDifference(); });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private getTimeDifference() {
    this.startDateInSeconds = Date.parse((this.dateStart));
    this.endDateInSeconds = Date.parse((this.dateEnd));
    this.currentTimeInSeconds = new Date().getTime();

    if (this.currentTimeInSeconds < this.startDateInSeconds) {
      this.timediff = this.startDateInSeconds - this.currentTimeInSeconds;
    } else {
      this.timediff = this.endDateInSeconds - this.currentTimeInSeconds;
    }

    this.allocateTimeUnits(this.timediff);
  }

  isDurationEnded() {
    if (this.currentTimeInSeconds > this.endDateInSeconds) {
      return true;
    }
    return false;
  }

  isValidDuration() {
    if (this.days > 0 && this.hours > 0 && this.minutes > 0 && this.seconds > 0) {
      return true;
    }
    return false;
  }

  private allocateTimeUnits(timeDifference) {
    this.seconds = Math.floor((timeDifference) / (this.millisecondsInASecond) % this.secondsInAMinute);
    this.minutes = Math.floor((timeDifference) / (this.millisecondsInASecond * this.minutesInAnHour) % this.secondsInAMinute);
    this.hours = Math.floor((timeDifference) / (this.millisecondsInASecond * this.minutesInAnHour * this.secondsInAMinute) % this.hoursInADay);
    this.days = Math.floor((timeDifference) / (this.millisecondsInASecond * this.minutesInAnHour * this.secondsInAMinute * this.hoursInADay));

    this.durationData = {
      'days': this.translateService.instant('' + this.days),
      'hours': this.translateService.instant('' + this.hours),
    }

    if (this.display !== 'promotion') {
      this.durationData = {
        ...this.durationData,
        'minutes': this.translateService.instant('' + this.minutes),
        'seconds': this.translateService.instant('' + this.seconds)
      }
    }

    if (this.days <= 0 && this.hours <= 0 && this.minutes <= 0 && this.seconds <= 0) {
      this.subscription.unsubscribe();
      this.complete.emit(true);
    }
  }

  onFormatTimer(num) {
    var digits = num.toString().padStart(2, '0').split('');
    return (digits[0].replace(digits[0], `<span class="digits">${digits[0]}</span>`)) + (digits[1].replace(digits[1], `<span class="digits digits-2">${digits[1]}</span>`));
  }
}
