import { delay, tap } from 'rxjs/operators';
// Service
import { LoadingBarService } from '@ngx-loading-bar/core';
import { PortalMemberProfileHttpService } from '@core/services/portal-member-profile-http.service';
// Angular
import { Component, OnInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit {
  svg: any = svgIconList;
  @ViewChildren('focusInput') focusInput: QueryList<ElementRef>;

  passwordForm: FormGroup;
  messages$ = this.portalMemberProfileHttpService.messages$;
  isSuccess = this.portalMemberProfileHttpService.isSuccess;
  checkValidity = false;
  passwordMatched = true;
  passwordInput = {
    isMasked: true,
    type: 'password',
    icon: this.svg.hidePasswordIcon
  };
  userData = JSON.parse(localStorage.getItem('user_data'));
  constructor(
    public dialog: MatDialog,
    private portalMemberProfileHttpService: PortalMemberProfileHttpService,
    public loadingBar: LoadingBarService,
  ) {
  }

  ngOnInit() {
    this.passwordFormInit();
  }

  onPasswordMask() {
    this.passwordInput.isMasked = !this.passwordInput.isMasked;
    this.passwordInput.type = this.passwordInput.isMasked ? 'password' : 'text';
    this.passwordInput.icon = this.passwordInput.isMasked ? this.svg.hidePasswordIcon : this.svg.viewPasswordIcon;
  }

  onCheckPassword() {
    this.passwordMatched = true;
    if (this.passwordForm.value.password_confirmation !== null && this.passwordForm.value.password !== null) {
      if (this.passwordForm.value.password !== this.passwordForm.value.password_confirmation) {
        this.passwordMatched = false;
      }

      if (this.passwordForm.value.password.length === 0 || this.passwordForm.value.password_confirmation.length === 0) {
        this.passwordMatched = true;
      }
    }
  }

  onSave() {
    this.checkValidity = true;
    if (this.passwordForm.valid) {
      if (this.passwordForm.valid && this.passwordMatched === true) {
        const data = {
          ...this.passwordForm.value
        };
        Object.keys(data).forEach((key) => (data[key] == null || data[key] === ''));
        this.portalMemberProfileHttpService.updatePassword(data).subscribe(() => {
          this.isSuccess = this.portalMemberProfileHttpService.isSuccess
          this.userData.change_password = 0;
          localStorage.setItem('user_data', JSON.stringify(this.userData));
        }
        );
        this.checkValidity = false;
      }
    }
  }

  private passwordFormInit() {
    this.passwordForm = new FormGroup({
      password: new FormControl(null, Validators.compose([Validators.required, Validators.minLength(6)])),
      password_confirmation: new FormControl(null, [Validators.required, Validators.minLength(6)]),
    });

    of(null).pipe(
      delay(200), tap(() => this.focusInput.first.nativeElement.focus()
      )).subscribe();
  }

  onConfirm() {
    this.passwordFormInit();
  }
}
