<div class="block mx-auto modal-box app-download-section-modal">
  <div class="" id="modal-app-download">
    <img class="dice-img" src="/assets/images/app-download/modal/dice-1.png">
    <button title="Close (Esc)" type="button" class="mfp-close" (click)="onCloseDialog()">×</button>
    <div class="p-20">
      <div class="row">
        <div class="col-md-7">
            <span class="left-arrow-button" [innerHTML]="svg.leftArrowIcon | safeHtml" (click)="stepControl(-1)"></span>
          <div class="m-l-10">
            <div class="app-download-section-title"> <ng-container *ngIf="data.platform == 'ios'; else android">
                {{ '[sitename] App IOS Download and Install Guide' | translate:{'sitename': siteName} }}
              </ng-container>
              <ng-template #android>
                {{ '[sitename] App Android Download and Install Guide' | translate:{'sitename': siteName} }}
              </ng-template>
            </div>
            <div class="app-download-section-instruction">
              <ng-container *ngIf="data.platform == 'ios'">
                <div *ngIf="step == 1" @fadeInOut>
                  <span>{{ 'Step' | translate }} {{ step }}</span>
                  <p>{{ 'Click "Apple iOS" download button from [sitename] site.' | translate:{'sitename': siteName} }}
                  </p>
                </div>
                <div *ngIf="step == 2" @fadeInOut>
                  <span>{{ 'Step' | translate }} {{ step }}</span>
                  <p>{{ 'A new tab will open up and you will be redirected to an app preview page.' | translate }}</p>
                  <p>{{ 'Click "OPEN" on the app preview page.' | translate }}</p>
                </div>
                <div *ngIf="step == 3" @fadeInOut>
                  <span>{{ 'Step' | translate }} {{ step }}</span>
                  <p>{{ 'You will be redirected to a "Hangout Party" App on AppStore instead of [sitename] App.' |
                    translate:{'sitename': siteName} }}</p>
                  <p>{{ 'Click "GET" to install "Hangout Party" App.' | translate }}</p>
                </div>
                <div *ngIf="step == 4" @fadeInOut>
                  <span>{{ 'Step' | translate }} {{ step }}</span>
                  <p>{{ 'Open "Hangout Party" App then click "Allow Paste" to launch the [sitename] App.' |
                    translate:{'sitename': siteName} }}</p>
                </div>
                <div *ngIf="step == 5" @fadeInOut>
                  <span>{{ 'Step' | translate }} {{ step }}</span>
                  <p>{{ 'You will be redirected to [sitename] App and enjoy your game!' | translate:{'sitename':
                    siteName} }}</p>
                  <p>{{ '*Direct search of "Hangout Party" App in AppStore will not grant your access to [sitename]
                    App.' | translate:{'sitename': siteName} }}</p>
                </div>
              </ng-container>
              <ng-container *ngIf="data.platform == 'android'">
                <div *ngIf="step == 1" @fadeInOut>
                  <span>{{ 'Step' | translate }} {{ step }}</span>
                  <p>{{ 'Click "Android" download button from [sitename] site.' | translate:{'sitename': siteName} }}
                  </p>
                </div>
                <div *ngIf="step == 2" @fadeInOut>
                  <span>{{ 'Step' | translate }} {{ step }}</span>
                  <p>{{ 'Download and install the app.' | translate }}</p>
                </div>
                <div *ngIf="step == 3" @fadeInOut>
                  <span>{{ 'Step' | translate }} {{ step }}</span>
                  <p>{{ 'Launch the app and enjoy your game!' | translate }}</p>
                </div>
              </ng-container>
            </div>
            <hr class="m-t-30">
            <div class="d-flex align-items-center">
              <qrcode class="app-download-section-qrcode" [qrdata]="downloadLink" width="150"
              [errorCorrectionLevel]="'M'"></qrcode>
              <span class="app-download-section-qrcode-instruction">{{ 'Scan here to download' | translate }} </span>
            </div>

          </div>
        </div>
        <div class="col-md-5 d-flex justify-content-center">
            <span class="right-arrow-button" [innerHTML]="svg.rightArrowIcon | safeHtml" (click)="stepControl(1)"></span>
          <div class="phone-image-container">
            <ng-container *ngFor="let image of loop; let i = index">
              <img *ngIf="step == i + 1" class="phoneImage" @fadeInOut
                [src]="'/assets/images/app-download/' + this.data.platform + '/' + 'step' + (i + 1) +'.png'">
            </ng-container>
          </div>
          <img class="coin1-img coin1" src="/assets/images/app-download/modal/coin-1.png" [ngClass]="this.data.platform">
          <img class="coin2-img coin2" src="/assets/images/app-download/modal/coin-2.png" [ngClass]="this.data.platform">
          <ng-container *ngIf="step == 1 ">
            <img src="/assets/images/app-download/modal/mouse-pointer.png" class="step1-mouse-pointer"
              [ngClass]="this.data.platform" @fadeInOut>
          </ng-container>
          <ng-container *ngIf="this.data.platform == 'ios' && step != 1 && step != 5">
            <img src="/assets/images/app-download/modal/blue-mouse-pointer.png" class="step2-mouse-pointer ios"
              [@cursorAnimation]="getStep()">
          </ng-container>
          <ng-container *ngIf="this.data.platform == 'android' && step == 2">
            <img src="/assets/images/app-download/modal/blue-mouse-pointer.png" class="step2-mouse-pointer android"
              @fadeInOut>
          </ng-container>
        </div>
        <div class="col-md-12 d-flex justify-content-center">
          <div class="row pagination-indicator-container" [ngClass]="this.data.platform">
            <div class="pagination-indicator" [ngClass]="{'active' : step == 1}"></div>
            <div class="pagination-indicator" [ngClass]="{'active' : step == 2}"></div>
            <div class="pagination-indicator" [ngClass]="{'active' : step == 3}"></div>
            <div *ngIf="data.platform == 'ios'" class="pagination-indicator" [ngClass]="{'active' : step == 4}"></div>
            <div *ngIf="data.platform == 'ios'" class="pagination-indicator" [ngClass]="{'active' : step == 5}"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
