<div class="block mx-auto ma-box boxshadow">
  <div class="modal1">
    <button title="Close (Esc)" type="button" class="mfp-close m-t-15 m-r-15" (click)="onCloseDialog()">×</button>
    <div class="row">
      <div class="col-lg-12">
        <div class="p-20">
            <div class="fancy-title m-t-10 m-b-10">
                <h4>{{ 'PHONE NUMBER VERIFICATION' | translate }}</h4>
            </div>
            <hr>
            <label class="mt-2 mb-4 text-center">{{ 'You have reach the daily limit of requesting OPT, please contact support for help.' | translate }}</label>
            <div class="col-12 form-group">
                <button class="button-blue w-100" (click)="onCloseDialog()">{{ 'Okay' | translate }}</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>

