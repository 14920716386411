// Angular
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-member-status-modal',
  templateUrl: './member-status-modal.component.html',
  styleUrls: ['./member-status-modal.component.scss']
})
export class MemberStatusModalComponent implements OnInit {
  svg: any = svgIconList;

  defaultTab = 0;

  KYCStage = {
    'basic': 0,
    'advanced': 1,
    'pro': 2
  };

  status = [
    'Basic', 'Advanced', 'Pro'
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      member_data: any
    },
    public dialogRef: MatDialogRef<MemberStatusModalComponent>,
    ) { }

  ngOnInit() {
    this.defaultTab = this.KYCStage[this.data.member_data.KYC];
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

}
