// Angular
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Model
import { ApiResponse } from '@core/models/api-response.model';
import { Rewards } from '@core/models/rewards.model';
// RxJS
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardHttpService {

  totalGameBalance$ = new Subject<number>();
  memberBalance$ = new Subject<number>();
  memberUsername$ = new Subject<string>();

  subsVar: Subscription;

  constructor(
    private http: HttpClient
  ) { }

  refreshMemberBalance(amount?: number) {
    this.memberBalance$.next(amount);
  }
  
  refreshMemberName(username: string) {
    this.memberUsername$.next(username);
  }

  getActiveRewards(): Observable<Rewards[]> {
    return this.http.get<ApiResponse>(`/reward?active=1`).pipe(
      map(res => {
        return res.data.rows;
      }),
    );
  }
}
