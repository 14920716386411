<ng-container *ngIf="checkPopupList()">
  <div
    class="modal top-modal"
    tabindex="-1"
    role="dialog"
    [ngStyle]="{'display':displayStyle}">
    <div class="modal-dialog" role="document">
      <div class="modal-content popup-bg">
        <div>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="closePopup()">x</button>
        </div>
        <!-- <div class="modal-header"> <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="closePopup()">x</button></div> -->
        <div class="modal-body">
          <swiper class="swiper-popup" [config]="config" *ngIf="content$.length > 0">
            <div class="swiper-wrapper">
              <div class="swiper-slide" *ngFor="let item of content$; let i = index">
                <div class="w-100 custom-padding">
                  <ng-container *ngIf="item.desktop_link && item.media_type == 1">
                      <!-- <iframe  *ngIf='displayVideoType(item.desktop_link) == "youtube"' class="w-100 video-height" [src]="getUrl(item.desktop_link)"></iframe> -->
                      <object  *ngIf='displayVideoType(item.desktop_link) == "youtube"' class="w-100 video-height margin-adjust" [data]="getUrl(item.desktop_link)"></object>
                      <!-- <video  controls loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" *ngIf='displayVideoType(item.desktop_link) == "video"' class="w-100 video-height"  preload="auto"><source [src]="getUrl(item.desktop_link)" type="video/mp4"></video> -->
                  </ng-container>
                  <ng-container *ngIf="item.desktop_link && item.media_type == 2">
                        <img id="image{{i}}" class="img-fluid margin-adjust" [src]="item.desktop_link"  [class] alt="desktop image">
                        {{ getImageDimenstion(i) }}
                  </ng-container>
                  <h3 *ngIf="item.title" class="popup_title m-auto w-75 ">{{ item.title }}</h3>
                  <p *ngIf="item.content" class="popup_content  w-100" [innerHTML]="item.content"></p>
                  <!-- <button *ngIf="item.cta_button_text && item.cta_button_link" type="button" class="btn btn-lg btn-block popup_button m-auto w-50" (click)="onRedirect(item.cta_button_link)" >{{ item.cta_button_text | uppercase }}</button> -->
                  <div class="popup_content d-flex" style="justify-content: space-between;">
                    <button *ngIf="item.cta_button_text_1 && item.cta_button_link_1" type="button" class="btn btn-lg btn-block popup_button_1 w-50 m-auto" [ngClass]="item.cta_button_text_2 && item.cta_button_link_2? 'm-r-10' : ''" (click)="onRedirect(item, item.cta_button_link_1)" >{{ item.cta_button_text_1 | uppercase }}</button>
                    <button *ngIf="item.cta_button_text_2 && item.cta_button_link_2" type="button" class="btn btn-lg btn-block popup_button_2 w-50 m-auto" (click)="onRedirect(item, item.cta_button_link_2)" >{{ item.cta_button_text_2 | uppercase }}</button>
                  </div>
                  <!-- <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="closePopup()">x</button> -->
                </div>
              </div>
            </div>

            <div class="swiper-pagination-popup"></div>
            <!-- <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div> -->

          </swiper>
        </div>
      </div>
    </div>
  </div>
</ng-container>
