export enum MemberPromotion {
  Pending = 0,
  Active = 1,
  Cancelled = 2,
  Completed = 3,
  Error = 4,
  Expired = 5,
  Fatal = 6,
  Rejected = 7,
  "Low Balance" = 8,
  "Low Balance 2" = 9,
}
