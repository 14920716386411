<!-- <div class="loading top-most"> -->
  <!-- FOR FUTURE REFERENCE <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> -->
  <!-- <div class="boxes">
      <div class="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
      </div>
      <div class="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
      </div>
      <div class="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
      </div>
      <div class="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
      </div>
  </div>
</div> -->

<!-- New Preloader -->
<div class="no-freeze-spinner">
    <div id="no-freeze-spinner">
      <div>
          <i class="material-icons">
              <img src="/assets/images/preloading-icon/logo.svg">
          </i>
          
          <i class="material-icons">
              <img src="/assets/images/preloading-icon/clubs.svg">
          </i>

          <i class="material-icons">
              <img src="/assets/images/preloading-icon/diamond.svg">
          </i>

          <i class="material-icons">
              <img src="/assets/images/preloading-icon/hearts.svg">
          </i>

          <i class="material-icons">
              <img src="/assets/images/preloading-icon/spade.svg">
          </i>

<div>
    </div>
      </div>
    </div>
</div>