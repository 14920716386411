import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/models/api-response.model';
import { AesService } from '@core/services/aes.service';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OneSignalService {

  constructor(
    private http: HttpClient,
    private aesService: AesService
  ) { }

  async onLoad(): Promise<any> {
    window.OneSignal = window.OneSignal || [];
    return new Promise((resolve) => {
      window.OneSignal.push(function () {
        resolve(window.OneSignal);
      });
    });
  }

  onInit() {
    return of(null).pipe(
      tap(async () => {
        // Create variable and get data from local storage
        let encryptedAppId = localStorage.getItem('osAppId'),
          decryptedAppId = null;

        // Data from local storage, no need to call API
        if (encryptedAppId != null) {
          decryptedAppId = this.aesService.decryptWithReqSignKey(encryptedAppId);
        }
        // Local storage no data, get data from API 
        else {
          await this.http.get<ApiResponse>(`/onesignal/app`).pipe(
            map(res => {
              decryptedAppId = this.aesService.decryptWithReqSignKey(res.data.app_id);
              if (res.data.app_id) {
                localStorage.setItem('osAppId', res.data.app_id);
              }
            })
          ).toPromise();
        }

        // Init OneSignal
        this.onLoad().then((OneSignal) => {
          OneSignal.init({
            appId: decryptedAppId
          });
        });
      })
    );
  }
}
