// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Model
import { ApiResponse } from '@core/models/api-response.model';
// RxJS
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
// Module
import { ForgotPasswordModule } from '@views/modules/main/pages/forgot-password/forgot-password.module';

@Injectable({
  providedIn: ForgotPasswordModule
})
export class ForgotPasswordHttpService {

  messages$ = new Subject<any[]>();
  isSuccess: boolean;

  constructor(private http: HttpClient) { }

  forgotPassword(data: any) {
    return this.http.post<ApiResponse>(`/forgotpassword`, data).pipe(
      tap(res => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      }));
  }

  recaptcha(data: any) {
    return this.http.post<ApiResponse>(`/recaptcha`, data);
  }

  forgotUsername(data: any) {
    return this.http.post<ApiResponse>(`/forgotusername`, data).pipe(
      tap(res => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      })
    );
  }
}

