// Model
import { ApiResponse } from './../models/api-response.model';
import { Dropdown } from './../models/dropdown.model';
// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// RxJS
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MemberBankHttpService {

  constructor(private http: HttpClient) { }

  getMemberBanks(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/bankaccount`).pipe(
      map(res => res.data.rows)
    );
  }

}
