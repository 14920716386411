import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss']
})
export class MainFooterComponent implements OnInit, AfterViewInit {

  @Input()
  countryCode: string;

  languageCode: string = localStorage.getItem('language_code').toUpperCase();
  sitePrefix = (environment.sitePrefix.toUpperCase() == 'BP9' || environment.sitePrefix.toUpperCase() == 'BP77') ? 'BP' : environment.sitePrefix.toUpperCase();

  constructor(
  ) { }

  ngOnInit(): void {
    this.countryCode = localStorage.getItem('country_code');
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.updateFooterContent();
    }, 500)
  }

  private updateFooterContent() {
    const replaceFunction = (elementId: string, stringToReplace: string, href: string, position: number = 1) => {
      let doms = document.querySelectorAll(`#${elementId}`),
        regex = new RegExp(stringToReplace, 'g');

      doms.forEach(dom => {
        let domContent = dom.textContent,
          count = 0;

        dom.innerHTML = domContent.replace(regex, match => {
          ++count;
          return count == position ? `<a href="${href}">${stringToReplace}</a>` : match;
        });
      })
    };

    if (this.sitePrefix == 'BP') {
      // MY_EN
      if (this.countryCode == 'MY' && this.languageCode == 'EN') {
        // BP9
        if (environment.sitePrefix.toUpperCase() == 'BP9') {
          replaceFunction('s1-1', 'BP9 Malaysia', 'https://118.107.211.142/my_en/');
          replaceFunction('s2-1', 'BP9 Malaysia', 'https://bp9asia.com');
          replaceFunction('s2-2', 'BP9 Casino', 'https://bp9casino.co');
        }
        // BP77
        else {
          replaceFunction('s1-1', 'BP77 Malaysia', 'https://bp77game.com');
          replaceFunction('s1-2', 'BP77 online casino Malaysia', 'https://118.107.211.142/my_en/');
        }
      }
      // ID_ID 
      else if (this.countryCode == 'ID' && this.languageCode == 'ID') {
        replaceFunction('s1-1', 'situs judi online BP9', 'https://bp9gaming.com');
        replaceFunction('s2-1', 'situs judi online BP9 Indonesia', 'https://rebrand.ly/bp77idr');
      }
    } else if (this.sitePrefix == 'BX99') {
      if (this.countryCode == 'MY' && this.languageCode == 'ZH') {
        replaceFunction('s1-1', 'BX99', 'https://bx99my.com/?lang=MY_ZH', 2);
      } else if (this.countryCode == 'MY' && this.languageCode == 'EN') {
        replaceFunction('s1-1', 'BX99 Online Casino Malaysia', 'https://bx99my.com/?lang=MY_EN');
      } else if (this.countryCode == 'SG' && this.languageCode == 'ZH') {
        replaceFunction('s1-1', 'BX99', 'https://bx99sg.com/?lang=SG_ZH', 2);
      } else if (this.countryCode == 'SG' && this.languageCode == 'EN') {
        replaceFunction('s1-1', 'BX99 Online Casino Singapore', 'https://bx99sg.com/?lang=SG_EN');
      }
    } else if (this.sitePrefix == 'YE55') {
      if (this.countryCode == 'MY' && this.languageCode == 'EN') {
        replaceFunction('s1-1', 'YE55 Malaysia', 'https://ye55my.com', 2);
      } else if (this.countryCode == 'MY' && this.languageCode == 'ZH') {
        replaceFunction('s1-1', 'YE55马来西亚', 'https://ye55my.com/?lang=MY_ZH', 2);
      } else if (this.countryCode == 'SG' && this.languageCode == 'EN') {
        replaceFunction('s1-1', 'YE55 Singapore', 'https://ye55sg.com', 2);
      }
    }

  }

}
