// Service
import { HistoryHttpService } from '../../services/history-http.service';
import { DateFilterService } from '@core/services/date-filter.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { RewardsHttpService } from '../../services/rewards-http.service';
import { DepositHttpService } from '../../services/deposit-http.service';
import { RequestReceiptHttpService } from '@core/services/request-receipt-http.service';
// Model
import { Pagination } from '@core/models/pagination.model';
import { ReceiptModalComponent } from '../../dialog/receipt-modal/receipt-modal.component';
import { TransactionReceiptModalComponent } from '../../dialog/transaction-receipt-modal/transaction-receipt-modal.component';
import { RequestReceipt } from '@core/models/request-receipt.model';
// Angular
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// Enum
import { MessageStatus } from '@core/enums/message-status.enum';
import { RewardsStatus } from '@core/enums/rewards-status.enum';
// RxJS
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { tap, delay, catchError } from 'rxjs/operators';
// Third Party
import * as moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { environment } from '@env/environment';
import { PromotionContentHttpService } from '../../../promotion/services/promotion-content-http.service';
import { PromotionCancelModalComponent } from '../../dialog/promotion-cancel-modal/promotion-cancel-modal.component';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import svgIconList from 'assets/icons.json';
declare const $: any;

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  host: {
    '(document:mousedown)': 'onClickOutside($event)',
  }
})
export class HistoryComponent implements OnInit {
  svg: any = svgIconList;

  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
  status = MessageStatus;
  activeTab = parseInt(this.activatedRoute.snapshot.paramMap.get('tab'), 10) ? parseInt(this.activatedRoute.snapshot.paramMap.get('tab'), 10) : 1;
  history$ = [];
  tab: number;
  allHistory$: any;
  userData = JSON.parse(localStorage.getItem('user_data'));
  isSuccess = this.depositHttpService.isSuccess;
  messages$ = this.depositHttpService.messages$;
  dataSub$ = new BehaviorSubject<any>(null);
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  form: FormGroup;
  activeFilter = {
    date: 'All Time',
    type: 'Transaction Type',
    amount: 'Amount',
    status: 'Status'
  };
  activeDateFilter = 'All Time';
  private datePickerSubscription = new Subscription();
  max = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  filterButtons = {
    date: ['Today', 'Yesterday', 'Last 7 Days', 'Last 30 Days', 'Last 90 Days', 'All Time'],
    type: ['Deposit', 'Withdrawal'],
    amount: [
      { id: 1, name: 'High to Low' },
      { id: 2, name: 'Low to High' }
    ],
    status: [
      { id: 0, name: 'Pending' },
      { id: 1, name: 'Approved' },
      { id: 2, name: 'Rejected' },
      { id: 3, name: 'In Progress' },
    ]
  };
  showDateTimePicker = false;
  currencyCode = JSON.parse(localStorage.getItem('user_data')).currency.code;
  rewardStatus = RewardsStatus;
  defaultTab = window.history.state.tab ? window.history.state.tab : 0;
  rowIcon = [];
  activePromotionList = [];
  newRequestIncoming = this.requestReceiptHttpService.newRequestIncoming;
  depositData: RequestReceipt = null;

  constructor(
    private _eref: ElementRef,
    private loadingBar: LoadingBarService,
    private historyHttpService: HistoryHttpService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dateFilterService: DateFilterService,
    private rewardDataService: RewardsHttpService,
    public dialog: MatDialog,
    private promotionContentHttpService: PromotionContentHttpService,
    public gameCategoryHttpService: GameCategoryHttpService,
    private translateService: TranslateService,
    private depositHttpService: DepositHttpService,
    private requestReceiptHttpService: RequestReceiptHttpService,
  ) { }

  ngOnInit() {
    this.reload();
    this.pagination = this.historyHttpService.pagination;
    this.formInit();
    this.depositData = RequestReceiptHttpService.requestReceipt;
    this.newRequestIncoming.subscribe(() => {
      this.depositData = RequestReceiptHttpService.requestReceipt;
      this.reload();
    })
    // this.historyHttpService.dataUpdated$.subscribe(() => {
    //   this.reload();
    // })
  }

  ngOnDestroy() {
    this.datePickerSubscription.unsubscribe();
  }

  onClickOutside(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      of(null).pipe(
        delay(105), tap(() => this.onHideCalendar()
        )).subscribe();
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';

    return this.setHistory(page, pageSize, params);
  }

  onShowCalendar() {
    this.showDateTimePicker = true;
  }

  onHideCalendar() {
    if (this.showDateTimePicker = true) {
      this.showDateTimePicker = false;
    }
  }

  onClose() {
    this.showDateTimePicker = false;
  }

  onDateFilter(type: any, event?: any) {
    this.activeFilter.date = type;
    switch (type) {
      case 'Today':
        this.form.patchValue({
          start_date: this.dateFilterService.getToday().from,
          end_date: this.dateFilterService.getToday().to
        });
        break;
      case 'Yesterday':
        this.form.patchValue({
          start_date: this.dateFilterService.getYesterday().from,
          end_date: this.dateFilterService.getYesterday().to
        });
        break;
      case 'Last 7 Days':
        this.form.patchValue({
          start_date: this.dateFilterService.getLast7Days().from,
          end_date: this.dateFilterService.getLast7Days().to
        });
        break;
      case 'Last 30 Days':
        this.form.patchValue({
          start_date: this.dateFilterService.getLast30Days().from,
          end_date: this.dateFilterService.getLast30Days().to
        });
        break;
      case 'Last 90 Days':
        this.form.patchValue({
          start_date: this.dateFilterService.getLast90Days().from,
          end_date: this.dateFilterService.getLast90Days().to
        });
        break;
      case 'All Time':
        this.form.patchValue({
          start_date: null,
          end_date: null
        });
        break;
      case 'custom range':
        if (event) {
          this.form.patchValue({
            start_date: moment(event.startDate._d).format('YYYY-MM-DD'),
            end_date: moment(event.endDate._d).format('YYYY-MM-DD')
          });
        }
    }
    this.onSubmit();
  }

  /*onFormDate(formKey: string, separator = '/') {
    const value = this.form.get(formKey).value;
    if (value !== null) {
      let date = this.form.get(formKey).value.replace(/[^0-9]+/g, "");
      let newDate = '';
      for (var i = 0; i < date.length; i++) {
        if (i === 4 || i === 6) {
          newDate += separator;
        }
        newDate += date[i];
      }
      this.form.patchValue({ [formKey]: newDate })
    }
  }*/

  onSort(filterType?: string, value?: any) {
    if (filterType === 'type') {
      this.activeFilter.type = value;
      this.form.patchValue({
        transaction_type: value == 'Withdrawal' ? 'withdrawal' : value.toLocaleLowerCase()
      });
    } else if (filterType === 'amount') {
      this.activeFilter.amount = value.name;
      this.form.patchValue({
        amount_sort: value.id
      });
    } else if (filterType === 'status') {
      this.activeFilter.status = value.name;
      this.form.patchValue({
        status: value.id
      });
    }
    this.onSubmit();
  }

  onSubmit() {
    const data = this.filterFormFields(this.form.value);
    this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    const parameters = this.params ? `&${this.params}` : '';
    this.showDateTimePicker = false;
    return this.setHistory(1, this.pageSize, parameters);
  }

  onReset(type: string) {
    switch (type) {
      case 'date':
        this.activeFilter.date = 'All Time';
        this.form.patchValue({
          start_date: null,
          end_date: null
        });
        break;
      case 'type':
        this.activeFilter.type = 'Transaction Type';
        this.form.patchValue({
          transaction_type: null,
        });
        break;
      case 'amount':
        this.activeFilter.amount = 'Amount';
        this.form.patchValue({
          amount_sort: null,
        });
        break;
      default:
        this.activeFilter.status = 'Status';
        this.form.patchValue({
          status: null,
        });
    }
    this.onSubmit();
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onChangeTab($event) {
    this.page = 1;
    this.history$ = [];
    this.rowIcon = [];
    this.defaultTab = $event.index;
    this.onViewPageBy(this.page);
  }

  private async setHistory(page: number, pageSize: number, params: string) {
    // defaultTab --> 0: Transaction; 1: Transfer; 2: Activated Promotion; 3: Bet Summary; 4: Rebate & Cashback; 5: Reward;
    this.history$ = [];
    switch (this.defaultTab) {
      case 0:
        this.allHistory$ = this.historyHttpService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
          tap(res => {
            this.pagination = this.historyHttpService.pagination;
            this.dataSub$.next(res);
            this.loadingBar.complete();

            // TODO: Revise this map block!
            res.map((row: any) => {
              this.rowIcon.push('+');
              this.history$.push({
                ...row,
                created_at: moment(row.created_at).local(true).format('YYYY-MM-DD HH:mm')
              });
            });
          })
        ).subscribe();
        break;
      case 1:
        this.allHistory$ = this.historyHttpService.getTransfer(`?page=${page}&perPage=${pageSize}${params}`).pipe(
          tap(res => {
            this.pagination = this.historyHttpService.pagination;
            this.dataSub$.next(res);
            this.loadingBar.complete();

            // TODO: Revise this map block!
            res.map((row: any) => {
              this.history$.push({
                ...row,
                created_at: moment(row.created_at).local(true).format('YYYY-MM-DD HH:mm:ss')
              });
            });
          })
        ).subscribe();
        break;
      case 2:
        this.activePromotionList = await new Promise(resolve => {
          this.promotionContentHttpService.getActivePromotionList().subscribe(res => {
            resolve(res);
          });
        });
        this.historyHttpService.getPromotionHistory(`?page=${page}&perPage=${pageSize}`).pipe(
          tap(res => {
            this.history$ = res;
            res.map(() => {
              this.rowIcon.push('+');
            });
            this.pagination = this.historyHttpService.pagination;
            this.dataSub$.next(res);
            this.loadingBar.complete();
          })
        ).subscribe();
        break;
      case 3:
        const start_date = this.dateFilterService.getLastMonthWithTime().from,
          end_date = this.dateFilterService.getLastMonthWithTime().to;
        this.historyHttpService.getBetHistory(`?page=${page}&perPage=${pageSize}&start_date_time=${start_date}&end_date_time=${end_date}`).pipe(
          tap(res => {
            this.history$ = res;
            res.map(() => {
              this.rowIcon.push('+');
            });
            this.pagination = this.historyHttpService.pagination;
            this.dataSub$.next(res);
            this.loadingBar.complete();
          })
        ).subscribe();
        break;
      case 4:
        this.historyHttpService.getRebateHistory(`?page=${page}&perPage=${pageSize}`).pipe(
          tap(res => {
            this.history$ = res;
            res.map(() => {
              this.rowIcon.push('+');
            });
            this.pagination = this.historyHttpService.pagination;
            this.dataSub$.next(res);
            this.loadingBar.complete();
          })
        ).subscribe();
        break;
      case 5:
        this.rewardDataService.getWithQuery(`?page=${page}&perPage=${pageSize}&active=0`).pipe(
          tap(res => {
            this.history$ = res;
            this.pagination = this.rewardDataService.pagination;
            this.dataSub$.next(res);
            this.loadingBar.complete();
          })
        ).subscribe();
        break;
    }
  }

  showDetails(value: any, index: number, event: Event) {
    // defaultTab --> 0: Transaction; 1: Transfer; 2: Activated Promotion; 3: Bet Summary; 4: Rebate & Cashback; 5: Reward;
    if (!(event.target instanceof HTMLImageElement) && this.defaultTab !== 1 && this.defaultTab !== 5) {
      this.rowIcon[index] = this.rowIcon[index] === '+' ? '-' : '+';
    } else {
      event.stopPropagation();
    }
  }

  openWithdrawReceipt(path: any) {
    if (path !== '' || path !== null) {
      this.dialog.open(ReceiptModalComponent, {
        width: 'auto',
        data: {
          receipt_path: path,
          caption: 'Receipt'
        }
      });
    }
  }



  openReceipt(transaction: any) {
    let paths = [];

    if (transaction.type_id == 1) {
      paths = transaction.deposit_receipts;
    } else {
      if (transaction.bank_transactions && transaction.bank_transactions.length > 0) {
        Object.keys(transaction.bank_transactions).forEach((key) => {
          paths.push(transaction.bank_transactions[key]);
        });
      }
    }
    this.dialog.open(TransactionReceiptModalComponent, {
      width: 'auto',
      data: {
        member_data: this.userData,
        receipt_path: paths,
        transaction: transaction
      }
    });
  }

  cancelDeposit(deposit: any) {
    Swal.fire({
      html: '<div class="msg-icon">' + this.svg.dangerIcon + '</div>' +
        '<div class="text-center m-t-20">' +
        '<ul><li class="mt-4">' + this.translateService.instant('Are you sure you want to cancel this deposit submission?') + '</li></ul>' +
        '</div>',
      confirmButtonText: this.translateService.instant('YES'),
      showDenyButton: true,
      showConfirmButton: true,
      denyButtonText: this.translateService.instant('NO'),
      reverseButtons: true,
      customClass: {
        denyButton: 'deny-button',
        confirmButton: 'confirm-button',
      }
    }).then(result => {
      if (result.isConfirmed) {

        const data = {
          status: 2 // 2 = Rejected Status
        };
        this.requestReceiptHttpService.update(this.depositData.deposit_id, data).pipe(
          tap(res => {
            this.historyHttpService.triggerDataUpdate();
          }),
          catchError(err => {
            throw err;
          })
        ).subscribe();
      }
    });

  }

  checkCancelable(item: any) {
    if (item.allow_cancel) {
      if (this.activePromotionList.length > 0) {
        // Active Promotion, Low Balance 1, Low Balance 2
        if (item.status == 1 || item.status == 8 || item.status == 9) {
          const data = this.activePromotionList.find(x => x.promotion_id === item.promotion_id);
          if (data) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }

  }

  onOpenDialog(type: string, item: any) {
    if (type === 'cancel') {
      const detail = item;
      const memberPromotion = this.activePromotionList.find(x => x.promotion_id === detail.promotion_id && x.game_provider_id == detail.game_provider_id);
      const total_promo_amount = +memberPromotion.amount + +memberPromotion.bonus_amount;
      if (+memberPromotion.game_wallet_balance > total_promo_amount && +memberPromotion.accumulated_target_amount == 0) {
        Swal.fire({
          html: '<div class="msg-icon">' + this.svg.dangerIcon + '</div>' +
            '<div class="text-center m-t-20">' +
            '<ul><li><b>' + this.translateService.instant('ACTION TEMPORARY UNAVAILABLE') + '</b></li></ul>' +
            '<ul><li class="mt-4">' + this.translateService.instant('The turnover is currently being updated and will be available shortly.') + '</li></ul>' +
            '<ul><li class="mt-4 rejected-text small">' + this.translateService.instant('Please try again later or contact customer service if you have any urgent concerns.') + '</li></ul>' +
            '</div>',
          confirmButtonText: this.translateService.instant('OK'),
        });
      } else {
        if (detail.limit_transfer_in == 0) {
          Swal.fire({
            html: '<div class="msg-icon">' + this.svg.dangerIcon + '</div>' +
              '<div class="text-center m-t-20">' +
              '<ul><li>' + this.translateService.instant('This action may result in a forfeiture of your transferred funds.') + '</li></ul>' +
              '<ul><li class="mt-4">' + this.translateService.instant('Are you sure you want to cancel this promotion?') + '</li></ul>' +
              '</div>',
            confirmButtonText: this.translateService.instant('YES'),
            showDenyButton: true,
            showConfirmButton: true,
            denyButtonText: this.translateService.instant('NO'),
            reverseButtons: true,
            customClass: {
              denyButton: 'deny-button',
              confirmButton: 'confirm-button',
            }
          }).then(result => {
            if (result.isConfirmed) {
              this.cancelPromotionDialog(memberPromotion);
            }
          });
        } else {
          this.cancelPromotionDialog(memberPromotion);
        }
      }
    }
  }

  cancelPromotionDialog(memberPromotion: any) {
    this.loadingBar.start()
    this.promotionContentHttpService.getBonusWinAmount(memberPromotion.id).subscribe(res => {
      this.gameCategoryHttpService.getProviderBalanceBy(memberPromotion.game_provider_code).subscribe(gameres => {
        this.loadingBar.complete();
        const dialogRef = this.dialog.open(PromotionCancelModalComponent, {
          width: '500px',
          data: {
            detail: memberPromotion,
            amount: res,
            gameWalletBalance: gameres,
            activePromotionList: this.activePromotionList.filter(x => x.promotion_id === memberPromotion.promotion_id)
          }
        });

        dialogRef.afterClosed().subscribe(async (result) => {
          if (result === true) {
            this.activePromotionList = [];
            this.activePromotionList = await new Promise(resolve => {
              this.promotionContentHttpService.getActivePromotionList().subscribe(res => {
                resolve(res);
              });
            });
            this.onViewPageBy(this.page);
          }
        });
      });
    });
  }

  checkReceiptAvailable(type: number, receipts = []) {
    var haveReceipt = false;
    if (receipts) {
      if (type == 1) {
        receipts.forEach(item => {
          if (item?.content.length > 0) {
            haveReceipt = true;
          }
        });
      } else {
        receipts.forEach(item => {
          if (item?.receipt_image.length > 0) {
            haveReceipt = true;
          }
        });
      }
    }

    return haveReceipt;
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== 'all time') {
        fields[key] = key === 'start_date' ? moment(formData[key] + ' 00:00:00').utc().format('YYYY-MM-DD HH:mm:ss') : key === 'end_date' ? moment(formData[key] + ' 23:59:59').utc().format('YYYY-MM-DD HH:mm:ss') : formData[key];
      }
    });
    return fields;
  }

  private reload() {
    this.onViewPageBy(this.page);
  }

  private formInit() {
    this.form = new FormGroup({
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      transaction_type: new FormControl(null),
      status: new FormControl(null),
      amount_sort: new FormControl(null)
    });
  }
}

