import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sports-footer',
  templateUrl: './sports-footer.component.html',
  styleUrls: ['./sports-footer.component.scss']
})
export class SportsFooterComponent implements OnInit {

  @Input()
  provider: string;

  constructor() { }

  ngOnInit(): void {
  }

}
