import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-slots-footer',
  templateUrl: './slots-footer.component.html',
  styleUrls: ['./slots-footer.component.scss']
})
export class SlotsFooterComponent implements OnInit {

  @Input()
  provider: string;
  constructor() { }

  ngOnInit(): void {
  }

}
