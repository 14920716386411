import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DepositHttpService } from '@views/modules/member/services/deposit-http.service';
import { TranslateService } from '@ngx-translate/core';
import { TwoStepDepositDataService } from './two-step-deposit-data.service';
import svgIconList from 'assets/icons.json';

@Injectable({
  providedIn: 'root',
})
export class TwoStepDepositService {
  svg: any = svgIconList;
  constructor(
    private depositHttpService: DepositHttpService,
    private loadingBar: LoadingBarService,
    private translateService: TranslateService,
    private router: Router,
    private twoStepDepositDataService: TwoStepDepositDataService,
  ) {}

  /**
   * Cancels a pending deposit callback by its ID.
   * 
   * Note:
   * return value in this func is for leaving page only, don't use return value as indicator of success/failed operation
   *
   * If there is no pending deposit callback, a message is displayed to the user indicating that they have no pending deposit.
   *
   * If a pending deposit callback is found, it is canceled by making a request to the `depositHttpService`. If the cancellation is successful, the step two local storage data is cleared and the page is reloaded.
   *
   * @param stepTwoDeposit - The pending deposit callback to be canceled.
   * @param reload - Whether or not to reload the page after the deposit is canceled.
   */
  async cancelStepTwoDeposit(stepTwoDeposit, reload = true) {
    if (!stepTwoDeposit) {
      Swal.fire({
        html:
          '<div class="msg-icon">' + this.svg.dangerIcon + '</div>' +
          '<div class="text-center m-t-20">' +
          this.translateService.instant('You have no pending deposit') +
          '</div>',
      });
      return;
    }

    const result = await Swal.fire({
      html: '<div class="msg-icon">' + this.svg.dangerIcon + '</div>' +
        '<div class="text-center m-t-20">' +
        '<ul><li class="mt-4">' + this.translateService.instant('Are you sure you want to cancel this deposit submission?') + '</li></ul>' +
        '</div>',
      confirmButtonText: this.translateService.instant('YES'),
      showCloseButton: true,
      showDenyButton: true,
      showConfirmButton: true,
      denyButtonText: this.translateService.instant('NO'),
      reverseButtons: true,
      customClass: {
        denyButton: 'deny-button',
        confirmButton: 'confirm-button',
      }
    });

    // For leaving page cancel deposit, default to true (leave) if user choose "Ok".
    // Although this will most likely cause issue if reload=true, but for
    // leaving page reload param should always be false
    if (result.isConfirmed) {
      this.loadingBar.start();

      this.depositHttpService
        .cancelDeposit(stepTwoDeposit.id)
        .subscribe((res) => {
          this.loadingBar.complete();

          if (res.success) {
            if (reload) {
              this.twoStepDepositDataService.clearStepTwoLocalStorageAndReload();
            } else {
              this.twoStepDepositDataService.clearStepTwoLocalStorage();
            }
          }
        });

      return false;
    }

    return false;
  }

  /**
   * Indicate that a deposit is paid (but backend side still waiting for vendor callback)
   *
   * This function is used to clean up the local storage and redirect the user to the member history page after a successful two-step deposit.
   */
  stepTwoDepositPaid() {
    this.twoStepDepositDataService.clearStepTwoLocalStorage();

    const tab = 1;
    return this.router.navigate(['/member/history', { tab }]);
  }

  /**
   * Displays a message to the user indicating that their deposit session has expired,
   * and clears the step two local storage data and reloads the page.
   */
  showDepositExpiredMsg(stepTwoDeposit) {
    Swal.fire({
      html:
        '<div class="msg-icon-expired">' + this.svg.expiredIcon + '</div>' +
        '<div class="text-center m-t-20">' +
          this.translateService.instant(this.twoStepDepositDataService.getSessionExpiredMsg()) +
        '</div>',
      showCloseButton: true,
    }).then(() => {
      this.loadingBar.start();

      this.depositHttpService.changeDepositToExpired(stepTwoDeposit.id).subscribe((res) => {
        this.loadingBar.complete();

        if (res.success) {
          this.twoStepDepositDataService.clearStepTwoLocalStorageAndReload();
        }
      });
    });
  }

  /**
   * Return false = stay on page
   * Return true = leave page
   *
   * Handles the user leaving the two-step deposit page, 
   * providing options to continue the payment, cancel the deposit, or stay on the page.
   *
   * @param lsStepTwoDeposit - A string representation of the pending callback deposit object, stored in local storage.
   * @returns `false` if the user chooses to continue the payment or the function is unable to determine the user's choice, `true` if the user chooses to cancel the deposit.
   */
  async handleLeavingStepTwoPage(lsStepTwoDeposit: string) {
    const result = await this.showLeavingPageAlert();

    // continue payment
    if (result.isConfirmed || result.isDismissed) {
      return false;
    }

    // cancel deposit
    if (result.isDenied) {
      const stepTwoDeposit = JSON.parse(lsStepTwoDeposit);

      const res = await this.cancelStepTwoDeposit(stepTwoDeposit, true);
      return res;
    }

    // just in case unable to get isConfirmed, isDismissed and isDenied, we let user to stay, but this is very unlikely
    return false;
  }

  /**
   * Displays a confirmation dialog to the user when they attempt to leave the current page,
   * warning them that doing so will discard their payment progress.
   *
   * The dialog provides options to either cancel the deposit or continue with the payment.
   *
   * @returns A Promise that resolves to the user's choice (either 'confirm' or 'deny') when the dialog is closed.
   */
  showLeavingPageAlert() {
    return Swal.fire({
      html:
        '<div class="msg-icon">' + this.svg.dangerIcon + '</div>' +
        '<div class="text-center mt-5 mb-4">' +
          this.translateService.instant(this.twoStepDepositDataService.getLeavingPageMsg()) +
        '</div>',
      buttonsStyling: false,
      showCloseButton: true,
      showDenyButton: true,
      showConfirmButton: true,
      reverseButtons: true,
      denyButtonText: this.translateService.instant('Cancel Deposit'),
      confirmButtonText: this.translateService.instant('Continue Payment'),
      padding: '50px 0 3rem',
      customClass: {
        denyButton: 'swal-button-blue-outline',
        confirmButton: 'swal-button-blue',
      },
    });
  }
}
