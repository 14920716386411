import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vpn-notice',
  templateUrl: './vpn-notice.component.html',
  styleUrls: ['./vpn-notice.component.scss']
})
export class VpnNoticeComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<VpnNoticeComponent>
  ) { }

  ngOnInit() {
  }

  onConfirm() {
    this.dialogRef.close();
  }

  onNotShowAgain() {
    localStorage.setItem('isShowVPNNotice','0');
    this.onConfirm();
  }

}
