<div class="dark m-t-40">
  <div class="row vertical-row d-flex justify-content-around">
    <!--Radial Progress Bar-->
    <div class="refresh-balance d-flex align-items-center" (click)="onRefresh()">
      <i class="fas fa-redo-alt" [ngClass]="{ 'refresh-spin': refreshIsClicked }"></i>
      <span>{{ 'Refresh' | translate }}</span>
    </div>
    <div class="col-5 p-r-0">
      <div class="wallet-box">
        <div class="wallet-balance">
          <p>{{ 'Balance' | translate | uppercase }}</p>
          <hr class="mt-0 mb-0 w-50">

          <ng-container *ngIf="(wallet.currency_code !== '-' && providerBalancesIsLoading == 0 && memberBalanceIsLoading == 0); else elseLoadingMainWallet">
            <h3>{{ wallet.currency_code + ' ' + (totalBalance | number: '1.2-2') }}</h3>
          </ng-container>
          <ng-template #elseLoadingMainWallet>
            <span class="me-2 spinner-border spinner-border-sm align-middle mt-2"></span>
          </ng-template>
        </div>
        <svg class="radial-progress" data-percentage="82" viewBox="0 0 80 80">
          <circle class="incomplete" cx="40" cy="40" r="35" [ngClass]="{'zero-amount': totalBalance === 0}"></circle>
          <circle class="complete" cx="40" cy="40" r="35" [style]="'stroke-dashoffset:' + totalGamesBalancePercentage"></circle>
        </svg>
      </div>
    </div>

    <!--Wallet Amount Display-->
    <div class="col-5 p-l-0">
      <div class="row m-l-0 m-r-0 m-b-10 wallet-bar-amount">
        <div class="left-grad">
          <p class="d-flex align-items-center wallet-label shadow-none">{{ 'Main Wallet' | translate }}</p>
          <div class="blue-grad"></div>
        </div>
        <p class="wallet-amount-bg" unselectable="on">
          <ng-container *ngIf="(wallet && memberBalanceIsLoading == 0); else elseLoadingWallet">
            {{ wallet.currency_code + ' ' + (wallet.balance | number: '1.2-2') }}
          </ng-container>
          <ng-template #elseLoadingWallet>
            <span class="me-2 spinner-border spinner-border-sm align-middle"></span>
          </ng-template>
        </p>
      </div>
      <div class="row m-l-0 m-r-0 m-b-10 p-b-10 wallet-bar-amount">
        <div class="left-grad">
          <p class="d-flex align-items-center wallet-label shadow-none">{{ 'Game Wallet' | translate }}</p>
          <div class="orange-grad"></div>
        </div>
        <p class="wallet-amount-bg">
          <ng-container *ngIf="(gameProvidersBalance?.total_game_balance >= 0 && providerBalancesIsLoading == 0); else elseLoadingGameWallet">
            {{ wallet.currency_code + ' ' + (gameProvidersBalance.total_game_balance | number: '1.2-2') }}
          </ng-container>
          <ng-template #elseLoadingGameWallet>
            <span class="me-2 spinner-border spinner-border-sm align-middle"></span>
          </ng-template>
        </p>
      </div>

      <div class="row p-b-10 m-l-5">
        <div class="col-4 p-l-0 p-r-0">
          <button type="button" class="button-blue" [ngClass]="{'disabled': (providerBalancesIsLoading > 0 || memberBalanceIsLoading > 0)}" [disabled]="(providerBalancesIsLoading > 0 || memberBalanceIsLoading > 0)" (click)="onRestore()">{{ 'Restore' | translate }}</button>
        </div>
        <div class="col-8 p-l-0 p-r-0 text-right">
          <div class="text-right smart-transfer">
            <p>{{ 'Smart Transfer' | translate }}</p>
            <label class="switch m-t-0 m-b-0">
              <input type="checkbox" *ngIf="smartTransfer !== null" [checked]="smartTransfer" id="togBtn" (change)="onSmartTransfer()" [disabled]="disabledSmartTransferSwitch || (providerBalancesIsLoading > 0 || memberBalanceIsLoading > 0)">
              <div class="slider round" [style]="(disabledSmartTransferSwitch || (providerBalancesIsLoading > 0 || memberBalanceIsLoading > 0)) ? 'background-color: rgb(108 117 125)' : null">
                <span class="on text-nowrap">{{ 'On' | translate | uppercase }}</span>
                <span class="off text-nowrap">{{ 'Off' | translate | uppercase }}</span>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-swal-alert [success]="isSuccess" [message]="messages$ | async" [flashStyle]="flashStyle"></app-swal-alert>