<div class="block mx-auto ma-box modal-box">
    <div class="modal1" id="modal-site-route">
        <div class="demomode-notice">
            <div class="notice-body">
                <p class="icon-wrapper"><span class="icon">i</span></p>
                <h6 class="text-center">{{ 'Announcement' | translate }}</h6>
                <div *ngIf="sitePrefix != 'BP9'">
                    <p class="text-center" [innerHTML]="'Dear [username], we\'re happy to announce BP77 is going international. You may login from our new link <b>[link]</b> with your existing username and password.' | translate: {username: username, link: link}"></p>
                    <p class="text-center" [innerHTML]="'Press the <b>\'CONTINUE\'</b> button below to proceed to BP9!' | translate"></p>
                    <button type="button" class="button-blue w-100" (click)="redirectsite();" [disabled]="disableButton">{{ 'Continue' | translate | uppercase }}</button>
                </div>
                <div *ngIf="sitePrefix == 'BP9'">
                    <p class="text-center" [innerHTML]="'Welcome to BP9! Save our new link <b>[link]</b> for your ease of access in the future.' | translate: {link: link}"></p>
                    <button type="button" class="button-blue w-100" (click)="closedialog();">OK</button>
                </div>
            </div>
            <a *ngIf="sitePrefix == 'BP9'" class="d-flex justify-content-center my-2" (click)="hidenotice()"><u>{{ 'Do not show again' | translate }}.</u></a>
        </div>
    </div>
 </div>