// Model
import { GameProvider } from '../models/game-provider.model';
import { ApiResponse } from '../models/api-response.model';
// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// RxJS
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GameProviderHttpService {
  isDemo$ = new BehaviorSubject<boolean>(true);
  isSlotsPage$ = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient
  ) { }

  getGameBalance(params?: string): Observable<GameProvider[]> {
    return this.http.get<ApiResponse>(`/gameprovider/balance${params}`).pipe(
      map(res => res.data.rows)
    );
  }

  launchGame(params?: string): Observable<GameProvider[]> {
    const { isDesktop } = JSON.parse((localStorage.getItem('user_browser')));
    const deviceType = isDesktop ? 'desktop' : 'mobile';
    return this.http.get<ApiResponse>(`/gameprovider/launch${params}&device_type=${deviceType}`).pipe(
      map(res => res.data.rows)
    );
  }

  toggleSlotsDemoStorage() {
    setTimeout(() => {
      if (localStorage.getItem('isDemoSelected') === undefined || localStorage.getItem('isDemoSelected') === null) {
        localStorage.setItem('isDemoSelected', '0');
        this.isDemo$.next(false);
        return;
      }
      this.isDemo$.next(+localStorage.getItem('isDemoSelected') === 1 ? true : false);
    });
  }

  getWeeklyTurnover() {
    return this.http.get<ApiResponse>(`/gameprovider/getweeklyturnover`).pipe(
      map(res => {
        return res.data
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

}
