import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination } from '@core/models/pagination.model';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { RequestReceiptHttpService } from '@core/services/request-receipt-http.service';
import { RequestReceipt } from '@core/models/request-receipt.model';
import { UploadHttpService } from '@core/services/upload-http.service';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-transaction-receipt-modal',
  templateUrl: './transaction-receipt-modal.component.html',
  styleUrls: ['./transaction-receipt-modal.component.scss']
})
export class TransactionReceiptModalComponent implements OnInit {
  svg: any = svgIconList;
  defaultTab = window.history.state.tab ? window.history.state.tab : 0;

  activeTab = this.data.transaction.type_id == 1 && this.data.transaction.status == 7 ? 'upload' : 'view';
  newRequestIncoming = this.requestReceiptHttpService.newRequestIncoming;
  depositData: RequestReceipt = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      member_data: any,
      receipt_path: Array<String>,
      transaction: any
    },
    private activatedRoute: ActivatedRoute,
    public dialogRef: MatDialogRef<TransactionReceiptModalComponent>,
    private requestReceiptHttpService: RequestReceiptHttpService,
  ) {}

  ngOnInit() {
    this.defaultTab = 0;
    this.depositData = RequestReceiptHttpService.requestReceipt;
    this.newRequestIncoming.subscribe(() => {
      this.depositData = RequestReceiptHttpService.requestReceipt;
    })
  }

  ngOnDestroy() {
    UploadHttpService.uploadedFile = null;
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSelectTab(tab: string) {
    this.activeTab = tab;
    //window.history.replaceState({}, '', `/member/wallet;tab=${tab}`);
    //this.seoService.execute();
    //this.onSetBankMaintenanceHours();
    //this.formatBankMaintenanceHour(JSON.parse(localStorage.getItem('bank_maintenance_hour'))['rows'], tab, true);
  }

}
