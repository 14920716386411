// Service
import { EventEmitterService } from '@core/services/event-emitter.service';
// RxJS
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
// Model
import { Pagination } from './../models/pagination.model';
import { PortalMessage } from './../models/portal-message.model';
import { ApiResponse } from '@core/models/api-response.model';
// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebsocketService } from './websocket.service';
import { environment } from '@env/environment';
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class PortalMessageHttpService {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  isSuccess: boolean;
  messageCount$ = new BehaviorSubject<number>(null);
  messageSubject = new Subject<any>();

  constructor(
    private http: HttpClient,
    private eventEmitterService: EventEmitterService,
    private snackBar: MatSnackBar,
    private  websocketService: WebsocketService,
  ) {}

  getWithQuery(pageParam: string): Observable<PortalMessage[]> {
    return this.http.get<ApiResponse>(`/message${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getUnread(): Observable<any> {
    return this.http.get<ApiResponse>(`/message/unread`).pipe(
      tap(res => {
        if (res.data.rows.new_messages > 0) {
          this.eventEmitterService.onReloadUreadMessage();
          this.messageCount$.next(res.data.rows.new_messages);
        }
      }),
      map(res => res.data.rows.new_messages)
    );
  }

  updateRead(data: any) {
    return this.http.post<ApiResponse>(`/message/read`, data).pipe(
      tap(() => {
        setTimeout(() => {
          this.eventEmitterService.onReloadMessage();
        }, 500)
      })
    );
  }

  deleteMessage(data: any) {
    return this.http.delete<ApiResponse>(`/message${data}`).pipe(
      tap((res: any) => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
        setTimeout(() => {
          this.eventEmitterService.onReloadMessage();
        }, 500)
      })
    );
  }

  getContents(id: number): Observable<PortalMessage> {
    return this.http.get<ApiResponse>(`/message/${id}`).pipe(
      map(res => res.data.rows)
    )
  }

  messageWebsocket(memberAccountId: number) {
    this.websocketService
      .getChannel('message-channel.' + memberAccountId)
      .listen(`.MessageEvent`, (data) => {
        this.messageCount$.next(data.unread);
        this.messageSubject.next(data);
        if (data && data.event_type == 'new') {
          const cta = data.CTA;

          if (cta) {
            const newData = {
              ...data,
              //TODO: add buttonConfirm and buttonCancel after BE side added CTA
            };
            this.websocketService.ctaNotification(newData);
          } else {
            this.messages$.next([data.title]);
          }
        }
      });
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}
