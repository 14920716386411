// Model
import { GameCategoryList } from './../models/games/game-category-list.model';
import { ApiResponse } from '../models/api-response.model';
import { GameCategory } from '@core/models/games/game-category.model';
// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// RxJS
import { catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { EventEmitterService } from './event-emitter.service';
import { LoadingService } from './loading-service.service';

@Injectable({
  providedIn: 'root'
})
export class GameCategoryHttpService {

  gameProvidersBalance: BehaviorSubject<any> = new BehaviorSubject<any>({ rows: [], total_game_balance: 0 });

  constructor(
    private http: HttpClient,
    private eventEmitterService: EventEmitterService,
    private loadingService: LoadingService
  ) { }

  getWithQuery(pageParam: string): Observable<GameCategory[]> {
    return this.http.get<ApiResponse>(`/gameprovider/gamelistbycategory${pageParam}`).pipe(
      map(res => res.data.rows.filter(game => game.suspend != 1))
    );
  }

  getAllGameProvider(): Observable<any[]> {
    return this.http.get<ApiResponse>(`/gameprovider/gamelistbycategory`).pipe(
      tap(res => {
        localStorage.setItem('available_providers', JSON.stringify(res.data.rows));
        this.eventEmitterService.onTopBarTabEmitter();
      }),
      map(res => res.data.rows)
    );
  }

  getGames(code: string): Observable<GameCategory[]> {
    return this.http.get<ApiResponse>(`/gameprovider/gamelistbycategory?category_code=${code}`).pipe(
      map(res => res.data.rows.filter(game => game.suspend != 1))
    );
  }

  getCategoryList(): Observable<GameCategoryList[]> {
    return this.http.get<ApiResponse>(`/gameprovider/list`).pipe(
      map(res => res.data)
    );
  }

  getCategoryListPromo(): Observable<any[]> {
    return this.http.get<ApiResponse>(`/gameprovider/listpromo`).pipe(
      map(res => res.data.rows)
    );
  }

  getProviderBalanceBy(code: string): Observable<number> {
    return this.http.get<ApiResponse>(`/gameprovider/balance?game_provider_code=${code}&check_maintenance=false`).pipe(
      map(res => res.data.rows.balance)
    );
  }

  getGameCategoryListWithPromo(refresh: boolean = false): Observable<any> {
    let balances = refresh ? null : JSON.parse(sessionStorage.getItem('game_providers_balance'));

    this.loadingService.updateProviderBalancesLoadingIndicator('increase');
    if (balances == null) {
      return forkJoin([
        this.getCategoryList(),
        this.getCategoryListPromo()
      ]).pipe(
        map(([categoryList, categoryListPromo]) => {
          // Maps into one object
          categoryList['rows'].map((provider, index) => {
            categoryListPromo.map(promo => {
              if (+provider.id === +promo.game_provider_id) {
                categoryList['rows'][index]['has_promo'] = promo['has_promo'];
              }
            });
          });

          // Push the latest data into gameProvidersBalance BehaviorSubject
          this.pushData(categoryList)

          // Set latest data into session storage
          sessionStorage.setItem('game_providers_balance', JSON.stringify(categoryList));
          this.loadingService.updateProviderBalancesLoadingIndicator('decrease');

          // Return the latest data
          return categoryList;
          // this.eventEmitterService.onRefreshProviderBalance(true);
        }),
        catchError(err => {
          this.loadingService.updateProviderBalancesLoadingIndicator('decrease');
          return throwError(err);
        })
      );
    } else {
      // Push data to gameProvidersBalance BehaviorSubject only when no data
      if (this.gameProvidersBalance.getValue().rows.length == 0) {
        this.pushData(balances)
      }

      this.loadingService.updateProviderBalancesLoadingIndicator('decrease');

      // Return the data
      return of(balances);
    }
  }

  private pushData(balances: any) {
    this.gameProvidersBalance.next(balances);
  }

}
