import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CryptoHttpService } from '@core/services/crypto-http.service';
import { CryptoWalletHttpService } from '@core/services/crypto-wallet-http.service';
import { catchError, map, tap } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { CryptoWalletDetailsModalComponent } from '@views/modules/member/dialog/crypto-wallet-details-modal/crypto-wallet-details-modal.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VerificationModalComponent } from '@views/modules/member/dialog/verification-modal/verification-modal.component';
import { EventEmitterService } from '../../core/services/event-emitter.service';
import { NumberVerificationHttpService } from '@core/services/number-verification-http.service';


@Component({
  selector: 'app-crypto-wallet-modal',
  templateUrl: './crypto-wallet-modal.component.html',
  styleUrls: ['./crypto-wallet-modal.component.scss']
})
export class CryptoWalletModalComponent implements OnInit {
  form: FormGroup;
  availableTokens:any;

  filteredTokens = [];
  selectedToken = [];
  selectedNetwork = [];
  networkDropdownList = [];
  selectedTokenNetwork: any;
  disabledButton = false;
  cryptoWallets$ = [];
  isLoading = false;
  validated = false;
  showError = false;
  isSuccess = this.cryptoWalletHttpService.isSuccess;
  messages$ = this.cryptoWalletHttpService.messages$;
  requestOtpMessages$ = this.numberVerificationHttpService.messages$;
  onDialog = this.data.onDialog ?? false;
  closeDialog = false;
  counter: number;
  timer: any;
  showSeconds = false;
  disabled = '';

  tokensDropdownSettings = {
    singleSelection: true,
    text: this.translateService.instant("Select Token"),
    primaryKey: 'token',
    labelKey: 'token',
    showCheckbox: false,
    disabled: false
  };

  networkDropdownSettings = {
    singleSelection: true,
    text: this.translateService.instant("Select Network"),
    primaryKey: 'id',
    labelKey: 'network',
    showCheckbox: false,
    disabled: false
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { onDialog: boolean },
    public dialogRef: MatDialogRef<CryptoWalletModalComponent>,
    private translateService: TranslateService,
    private cryptoHttpService: CryptoHttpService,
    private cryptoWalletHttpService: CryptoWalletHttpService,
    public loadingBar: LoadingBarService,
    private dialog: MatDialog,
    private eventEmitterService: EventEmitterService,
    private numberVerificationHttpService: NumberVerificationHttpService,
  ) { }

  ngOnInit(): void {
    this.cryptoHttpService.getCryptoTokens().subscribe(res => {
      this.availableTokens = JSON.parse(JSON.stringify(res));
      this.filteredTokens = Array.from(new Set(this.availableTokens.map(token => token.token))).map(token => {
        return this.availableTokens.find(t => t.token === token);
      });
    })
    this.formInit();
    if (this.onDialog) {
      if (sessionStorage.getItem('OTP_timer') !== null) {
        this.onTimer(Number(sessionStorage.getItem('OTP_timer')));
      }
    } else {
      this.onGetCryptoWallet(null);
    }
  }

  onGetCryptoWallet(memberCryptoWallets: any) {
    if (memberCryptoWallets === null && !this.onDialog) {
      this.selectedToken = this.selectedNetwork = [];
      this.cryptoWalletHttpService.getAll().pipe(
        tap(res => {
          this.cryptoWallets$ = res;
        })
      ).subscribe();
    }
  }

  private formInit() {
    this.form = new FormGroup({
      token: new FormControl(null, [Validators.required]),
      network: new FormControl(null, [Validators.required]),
      wallet_address: new FormControl(null, [Validators.required]),
      wallet_nickname: new FormControl(null),
      otp: new FormControl(null, this.onDialog ? [Validators.required] : []) // Set validators based on this.onDialog
    });

    this.setupDebouncing('wallet_address');
  }

  setupDebouncing(controlName: string) {
    const wallet_address_control = this.form.get(controlName);
    this.form.get(controlName).valueChanges.subscribe(query => { wallet_address_control.setErrors({ invalidWallet: true });})
    this.form.get(controlName).valueChanges.pipe(
      debounceTime(400), // Adjust the debounce time as needed
      distinctUntilChanged(),
      switchMap(query => {
        const wallet_address = this.form.get(controlName)?.value;
        if (wallet_address) {
          this.validated = false;
          this.isLoading = true;
          const token = this.form.get('token')?.value;
          const network = this.form.get('network')?.value;
          const queryString = `?wallet_address=${wallet_address}&token=${token}&network=${network}`;

          return this.cryptoWalletHttpService.validateWalletAddress(queryString)
            .pipe(
              catchError(err => {
                this.isLoading = false;
                this.showError = true;
                this.validated = false;
                return of(false);
              })
            );
        } else {
          return of(null);
        }
      }),
    ).subscribe(res => {
      if (res == true) {
        wallet_address_control.setErrors(null);
        this.validated = true;
        this.showError = false;
      } else if (res == false) {
        this.showError = true;
        this.validated = false;
        wallet_address_control.setErrors({ invalidWallet: true });
      }
      this.isLoading = false;
    });
  }

  onSelectToken(selectedToken: string) {
    this.selectedNetwork = [];
    const matchingTokens = this.availableTokens.filter(token => token.token === selectedToken);
    this.networkDropdownList = matchingTokens.map(token => ({ id: token.id, token: token.token, network: token.network }));
    if (this.networkDropdownList.length == 1) {
      this.selectedNetwork = this.networkDropdownList[0];
      this.onSelectNetwork(this.networkDropdownList[0])
    } else {
      this.onSelectNetwork([])
    }
  }

  onDeSelectToken() {
    this.validated = false;
    this.selectedNetwork = this.selectedToken = this.networkDropdownList = [];

    this.onSelectNetwork([]);
  }


  onSelectNetwork(selectedNetwork) {
    this.form.patchValue({
      wallet_address: null,
      wallet_nickname: null,
      token: selectedNetwork?.token,
      network: selectedNetwork?.network,
    });
    this.showError = false;
    this.form.get('wallet_address').setErrors(null);
  }

  onDeSelectNetwork() {
    this.form.patchValue({
      wallet_address: null,
      wallet_nickname: null,
      network: null,
    });
    this.showError = false;
    this.form.get('wallet_address').setErrors(null);
  }

  onSave() {
    const data = { ...this.form.value };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    const addWalletSubscription = this.cryptoWalletHttpService.addWallet(data);

    if (!this.onDialog) {
      const dialogRef = this.dialog.open(VerificationModalComponent, {
        width: '600px',
        data: {
          can_request_otp: 1,
          new_mobile_number: JSON.parse(localStorage.getItem('user_data')).mobile,
          request_type: "crypto_wallet"
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.loadingBar.start();
        this.disabledButton = true;
        if (result === true) {
          addWalletSubscription.subscribe(() => {
            this.handleSuccess();
          }, error => {
            this.handleError();
          });
        } else {
          this.handleError();
        }
      });
    } else {
      this.loadingBar.start();
      this.disabledButton = true;
      const verifyData = {
        otp: this.form.value.otp,
        request_type: 'crypto_wallet'
      }
      this.numberVerificationHttpService.verifyOTP(verifyData, false).subscribe(() => {
        localStorage.setItem('verified_number', 'true');
        addWalletSubscription.subscribe(() => {
          this.handleSuccess();
        }, error => {
          this.handleError();
        });
      }, error => {
        this.handleError();
      });
    }
  }

  private handleSuccess() {
    this.form.reset();
    this.isSuccess = this.cryptoWalletHttpService.isSuccess;
    this.loadingBar.complete();
    this.disabledButton = false;
    this.networkDropdownList = [];
    if (this.onDialog) {
      this.closeDialog = true;
      this.onCloseDialog();
    } else {
      this.onGetCryptoWallet(null);
    }
  }

  private handleError() {
    this.loadingBar.complete();
    this.disabledButton = false;
    if(this.onDialog) {
      this.dialogRef.close();
    }
  }

  onEdit(row) {
    const dialogRef = this.dialog.open(CryptoWalletDetailsModalComponent, {
      width: '800px',
      data: {
        wallet: row
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(result === true) {
        this.onGetCryptoWallet(null);
      }
    });
  }

  onCloseDialog(event?: Event) {
    if (!this.onDialog) {
      this.onGetCryptoWallet(null);
      this.formInit();
    } else {
      this.dialogRef.close(this.closeDialog);
    }
  }

  onRequestOTP() {
    this.disabled = 'disabled';
    const newNumber = JSON.parse(localStorage.getItem('user_data')).mobile ? JSON.parse(localStorage.getItem('user_data')).mobile : null;
    const requestType = 'crypto_wallet';
    this.numberVerificationHttpService.requestOTP(newNumber, requestType).subscribe(
      res => {
        this.onTimer(60);
        this.isSuccess = true
      }
    );
  }

  onTimer(startTime: number) {
    this.disabled = 'disabled';
    this.counter = startTime;
    this.eventEmitterService.onVerificationTimerEmitter();
    window.clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.counter--;
      if (this.counter === 0) {
        window.clearInterval(this.timer);
        this.disabled = '';
      }
    }, 1000);
  }
}
