<div class="block mx-auto modal-box languages-box">
  <div class="modal1" id="modal-languages">
    <button title="Close (Esc)" type="button" class="mfp-close m-t-15 m-r-15" (click)="onCloseDialog()">×</button>
    <div class="p-20">
      <!-- TITLE -->
      <div class="fancy-title">
        <span [innerHTML]="svg.languageIcon  | safeHtml" class="title-image"></span>
        <h4>{{ 'Region & Language' | translate }}</h4>
      </div>
      <hr>
      <!-- BEFORE LOGIN -->
      <ng-container *ngIf="isLoggedIn === false; else elseAfterLogin">
        <div class="row languages-country" *ngFor="let item of availableCountryAndLanguages[currentDomain] | keyvalue: returnZero">
          <div class="col-3 col-md-3 center"> 
            <img src="/assets/images/language/{{ item.key }}.png">
          </div>
          <div class="col-9 col-md-9 p-l-0">
            <h6>{{ item.value.name | translate }}</h6>
            <ng-container *ngFor="let code of item.value.languages; let i = index">
              <a (click)="setLanguage(code, item.key)"
                [ngClass]="(currentLanguageCode == code && currentCountryCode == item.key) ? 'selected' : ''">
                {{ languages[code] }}
              </a>
            </ng-container>
            <ng-container *ngIf="item.value.languages.length == 0">
                <div class="col-12 p-0">
                    <span>{{ 'Coming soon...' | translate }}</span>
                </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <!-- AFTER LOGIN -->
      <ng-template #elseAfterLogin>
        <div class="row languages-country">
          <div class="col-3 col-md-3 center">
            <img [src]="'/assets/images/language/' + currentCountryCode + '.png'"
              [alt]="availableCountryAndLanguages[currentDomain][currentCountryCode].name + ' Flag Icon'">
          </div>
          <div class="col-9 col-md-9 p-l-0">
            <h6>{{ availableCountryAndLanguages[currentDomain][currentCountryCode].name | translate }}</h6>
            <ng-container
              *ngFor="let code of availableCountryAndLanguages[currentDomain][currentCountryCode].languages; let i = index">
              <a (click)="setLanguage(code, currentCountryCode)"
                [ngClass]="(currentLanguageCode == code) ? 'selected' : ''">
                {{ languages[code] }}
              </a>
            </ng-container>
            <ng-container *ngIf="availableCountryAndLanguages[currentDomain][currentCountryCode].languages.length == 0">
                <div class="col-12 p-0">
                    <span>{{ 'Coming soon...' | translate }}</span>
                </div>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
