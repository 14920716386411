// Angular
import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { Router } from '@angular/router';
// Service
import { OwlOptions } from 'ngx-owl-carousel-o';
// Model
import {
  RegistrationPage,
  RegistrationPageBanner,
  RegistrationPageContainer,
} from '@core/models/registration-page.model';

@Component({
  selector: 'app-signup-layout1',
  templateUrl: './layout1.component.html',
  styleUrls: ['./layout1.component.scss'],
})
export class Layout1Component implements OnInit, AfterViewInit {
  @Input()
  dynamicData: RegistrationPage = null;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 1000,
    autoplaySpeed: 1000,
    autoplay: true,
    items: 1,
    nav: false,
    animateOut: 'animate__animated animate__slideOutDown',
  };
  topContainer: RegistrationPageContainer;
  bottomContainer: RegistrationPageContainer;
  topBanner: RegistrationPageBanner;
  bottomBanner: RegistrationPageBanner;

  constructor(private router: Router) {}

  ngOnInit() {
    this.setContainers();
    this.setBanners();
  }

  ngAfterViewInit() {
    this.setSectionContainersHeight();
  }

  private setContainers() {
    if (!this.dynamicData) return;

    const containers = this.dynamicData.containers;

    // only takes 2 containers in UP even if containers count > 2
    this.topContainer = containers.filter(
      (container) => container.container_position === 1
    )[0];

    this.bottomContainer = containers.filter(
      (container) => container.container_position === 2
    )[0];
  }

  private setBanners() {
    if (!this.dynamicData) return;

    const banners = this.dynamicData.banners;

    this.topBanner = banners.filter(
      (banner) => banner.banner_section_type_id === 1 // top
    )[0];

    this.bottomBanner = banners.filter(
      (banner) => banner.banner_section_type_id === 2 // bottom
    )[0];
  }

  private setSectionContainersHeight() {
    const signupFormHeight = document.querySelector<HTMLElement>('.signup-form-box')?.offsetHeight;
    document.querySelector<HTMLElement>('.section-containers-div').style.maxHeight = `${signupFormHeight}px`;
  }

  public areAllImagesAvailable(images?: Array<any>) {
    return images?.every((item) => !!item.banner_image);
  }

  onNavigate(link: string) {
    if (link !== '') {
      window.location.href = link;
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
