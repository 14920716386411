// Angular
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Model
import { ApiResponse } from '../models/api-response.model';

@Injectable({
  providedIn: 'root'
})
export class SystemSettingHttpService {

  constructor(
    private http: HttpClient,
  ) { }

  getMaintenance() {
    return this.http.get<ApiResponse>(`/settingsystem/maintenance`);
  }

  getApiVersion() {
    return this.http.get<ApiResponse>('/version').pipe(
      map(res => res.data.rows)
    );
  }

  verifyPromotionKYC(setting, item = null): { isValid: boolean, isVerified: Record<string, boolean> } {
    let progression = 'Basic';
    let isValid = true;
    let isVerified = {
      fullname: true,
      dob: true,
      mobile_otp: true,
      email_otp: true,
      kyc_basic: true,
      kyc_advanced: true,
      kyc_pro: true,
    };
    
    const userData = JSON.parse(localStorage.getItem('user_data'));

    if (!userData || !setting) {
      return { isValid, isVerified };
    }

    if (userData.name != null && userData.mobile_status == 1){
      progression = 'Advanced';
    }

    if (userData.name != null && userData.mobile_status == 1 && userData.date_of_birth != null && userData.date_of_birth != '0000-00-00' && userData.email_status == 1){
      progression = 'Pro';
    }

    item = (item === 'profile' ? null : item);
  
    const checkAndSetVerification = (verificationKey, requiresCheck) => {
      isVerified[verificationKey] = true;
      if (requiresCheck) {
        isVerified[verificationKey] = isValid = false;
      }
    };

    if ( (item === null || item === 'fullname') ) {
      checkAndSetVerification('fullname', setting?.requires_fullname == 1 && this.isEmptyItem(userData.name));
    }
    if ( (item === null || item === 'dob') ) {
      checkAndSetVerification('dob', setting?.requires_dob == 1 && this.isEmptyItem(userData.date_of_birth));
    }
    if ( [null, 'mobile', 'mobile_otp'].includes(item) ) {
      checkAndSetVerification('mobile_otp', setting?.requires_mobile == 1 && userData.mobile_status != 1);
    }
    if ( [null, 'email', 'email_otp'].includes(item) ) {
      checkAndSetVerification('email_otp', setting?.requires_email == 1 && userData.email_status != 1);
    }

    // kyc status
    if (setting?.kyc_basic == 1 || setting?.kyc_advanced == 1 || setting?.kyc_pro == 1) {
      switch (progression) {
        case 'Basic':
          checkAndSetVerification('kyc_basic', setting?.kyc_basic != 1);
          break;
        case 'Advanced':
          checkAndSetVerification('kyc_advanced', setting?.kyc_advanced != 1);
          break;
        case 'Pro':
          checkAndSetVerification('kyc_pro', setting?.kyc_pro != 1);
          break;
      }
    }
    
    return { isValid, isVerified };
  }

  private isEmptyItem(item) {
    if (item === null || item === undefined) {
      return true;
    }

    if (Array.isArray(item)) {
      return item.length === 0;
    }
    if (typeof item === 'object') {
      return Object.keys(item).length === 0;
    }
  
    if (typeof item === 'string') {
      return item.trim() === '' || item === '0000-00-00';
    }

    return false;
  }
}
