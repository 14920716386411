import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '@env/environment';

@Component({
  selector: 'app-count-down-details',
  templateUrl: './count-down-details.component.html',
  styleUrls: ['./count-down-details.component.scss']
})
export class CountDownDetailsComponent implements OnInit {

  contentHash = '';
  constructor(
    public dialogRef: MatDialogRef<CountDownDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      dateEnd: any;
      name: string;
      time: string;
    },

  ) { }

  ngOnInit() {
    this.contentHash = Math.random().toString(36).substring(7);
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

}
