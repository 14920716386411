import { EventEmitterService } from '@core/services/event-emitter.service';
import { BannerHttpService } from '@core/services/banner-http.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { environment } from '@env/environment';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { map, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { LoginModalComponent } from '@shared/login-modal/login-modal.component';
import { CountDownDetailsComponent } from '@shared/count-down-details/count-down-details.component';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { Store, select } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { Observable } from 'rxjs/internal/Observable';
import { combineLatest, merge } from 'rxjs';
import { Banner } from '@core/models/banner.model';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {

  visible = false;
  banners = ['sports-banner', 'fishing-banner', 'live-casino-banner', 'slots-banner'];
  bannerCount = this.banners.length;
  bannerPosition = 0;

  isLoggedIn$: Observable<boolean> = this.store.pipe(select(isLoggedIn));
  isLoggedIn;
  banners$: any;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 1000,
    autoplaySpeed: 1000,
    autoplay: true,
    items: 1,
    nav: false,
    animateOut: 'animate__animated animate__slideOutDown',
  };

  affiliateDomain = this.dropdownHttpService.affiliateDomain;

  constructor(
    private bannerHttpService: BannerHttpService,
    private eventEmitterService: EventEmitterService,
    private router: Router,
    private dropdownHttpService: DropdownHttpService,
    private gameCategoryHttpService: GameCategoryHttpService,
    public dialog: MatDialog,
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.getBanner();
    this.eventEmitterService.updateCountryAndLanguageEmitter.subscribe(() => {
      this.getBanner();
    });

    this.isLoggedIn$.subscribe(res=>{
      this.isLoggedIn = res
    })
  }

  private getBanner(){
    this.banners$ = this.bannerHttpService.getAllBanner().pipe(
      map(banners => {
        if (this.isLoggedIn) {
          return banners.filter(banner => banner.images[0].image_mobile !== null && (banner.session == 1 || banner.session == 3));
        } else {
          return banners.filter(banner => banner.images[0].image_mobile !== null && (banner.session == 1 || banner.session == 2));
        }
      })
    )
  }

  getOffset(el: any) {
    el = el.getBoundingClientRect();
    return {
      left: el.left + window.scrollX,
      top: el.top + window.scrollY,
      bottom: el.top + window.scrollY
    };
  }

  scroll() {
    const scrollTo = document.getElementById('content');
    const topHight = this.getOffset(scrollTo).top;
    window.scrollTo(0, topHight);
  }

  onRedirect(link: string) {
    if (link !== (null && '')) {
      if (link.includes('external')) {
        this.router.navigate([]).then(() => { window.open(link, '_blank'); });
      }
      else {
        this.router.navigateByUrl(link);
      }
    }
  }

  navToAffLink(link: string) {
    if (link !== (null && '')) {
      if ((environment.sitePrefix as string).toLowerCase() != 'bp9' && (link.indexOf('http://') !== -1 || link.indexOf('https://') !== -1)) {
        //for http & https link redirect
        window.location.href = link;
      } else {
        //for /aff affiliate link shortcut
        let currentCountryCode = localStorage.getItem('country_code'), affCountryObj = this.affiliateDomain[currentCountryCode];
        window.location.href = (environment.https ? 'https://' : 'http://') + affCountryObj.desktopPreExt[environment.domainEnv] + affCountryObj[environment.domainEnv][0] + `?lang=${currentCountryCode}_${localStorage.getItem('language_code')}`;
      }
    }
  }

  onLaunchGame(data: string) {
    // E.g data : Launch|EZ||481000, Launch|EZ|roulette|481000
    let datas = data.split('|');
    this.gameCategoryHttpService.getGames('LC').pipe(
      map(res => res.find(x => x.game_provider_code === datas[1]))
    ).subscribe((gameProvider: any) => {
      // To update the params on the URL
      const dateEnd = Date.parse(gameProvider.maintain_end);
      const dateNow = new Date().getTime();

      if (localStorage.getItem('user_data') === null) {
        this.dialog.open(LoginModalComponent, { width: 'auto' });
      }
      else if (gameProvider.maintenance === 1 && dateEnd > dateNow) {
        this.dialog.open(CountDownDetailsComponent, {
          width: 'auto',
          data: {
            dateEnd: gameProvider.maintain_end,
            name: gameProvider.game_provider_name,
            time: gameProvider.maintain_time_left,
          }
        });
      }
      //maintenance with no end date
      else if (gameProvider.maintenance === 1 && gameProvider.maintain_end === '') {
        this.dialog.open(CountDownDetailsComponent, {
          width: 'auto',
          data: {
            name: gameProvider.game_provider_name,
            time: gameProvider.maintain_time_left,
          }
        });
      } else {
        localStorage.setItem('gameProviderCode', gameProvider.game_provider_code);
        localStorage.setItem('gameCategory', 'LC');
        localStorage.setItem('isPlayTechClicked', (gameProvider.game_provider_code === "PT").toString());
        localStorage.setItem('gameProviderId', gameProvider.game_provider_id);
        datas[2] !== undefined ? localStorage.setItem('subCategoryCode', datas[2]) : localStorage.removeItem('subCategoryCode');
        datas[3] !== undefined ? localStorage.setItem('gameCode', datas[3]) : localStorage.removeItem('gameCode');
        localStorage.setItem('lobbyUrl', location.href);

        this.router.navigate([]).then(() => { window.open('/launch', '_blank'); });
      }
    })

  }
}
