import { Subscription } from 'rxjs/internal/Subscription';
import { delay, tap, map } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import { ViewChildren, QueryList, ElementRef, OnDestroy } from '@angular/core';
// Angular
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
// Service
import { GamePasswordHttpService } from '@views/modules/member/services/game-password-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-game-password',
  templateUrl: './game-password.component.html',
  styleUrls: ['./game-password.component.scss']
})
export class GamePasswordComponent implements OnInit, OnDestroy {
  svg: any = svgIconList;

  @ViewChildren('focusInput') focusInput: QueryList<ElementRef>;
  form: FormGroup;
  passwordMatched = true;

  gameProviderId = window.history.state.appId;
  filename = window.history.state.appFileName;
  username = window.history.state.appUsername;
  gameProviderCode = window.history.state.gameCode;

  slotProviders$: any;

  messages$ = this.gamePasswordHttpService.messages$;
  isSuccess = this.gamePasswordHttpService.isSuccess;
  gameCategoryList: { rows: [any], total_game_balance: 0 }; // With balance

  confirmPasswordInput = {
    type: 'text',
    icon: 'icon-eye-slash'
  };

  textInput = {
    type: 'text'
  }

  passwordInput = {
    isMasked: true,
    type: 'password',
    icon: this.svg.hidePasswordIcon
  };

  usernameList: any;

  showPasswordFormat = false;
  checkValidity = false;
  usernameFormatValid = {
    special: false
  };
  private subscriptions: Subscription[] = [];
  constructor(
    public dialog: MatDialog,
    private gamePasswordHttpService: GamePasswordHttpService,
    public loadingBar: LoadingBarService,
    public gameCategoryHttpService: GameCategoryHttpService,
    private gameProviderHttpService: GameProviderHttpService,
    private loading: LoadingBarService
  ) {
    // Subscribe to get push update for games balance
    this.subscriptions.push(
      this.gameCategoryHttpService.gameProvidersBalance.subscribe(res => {
        this.gameCategoryList = res;
        this.usernameList = res.rows;
      })
    );
  }

  ngOnInit() {
    this.gameCategoryHttpService.getGameCategoryListWithPromo().subscribe();
    this.formInit();
    this.slotProviders$ = this.gameCategoryHttpService.getWithQuery('?category_code=SL');
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  onCheckPassword() {
    this.passwordMatched = true;

    //Check if password has lowercase, uppercase, number
    let characterMatch = this.form.value.password.match("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).+$") !== null;
    //Check that password has no symbols
    let hasSymbol = this.form.value.password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/) === null;
    this.showPasswordFormat = !(characterMatch && hasSymbol && this.form.get('password').valid);

    if (this.form.value.confirm_password !== null && this.form.value.password !== null) {
      if (this.form.value.password !== this.form.value.confirm_password) {
        this.passwordMatched = false;
      }

      if (this.form.value.password.length === 0 || this.form.value.confirm_password.length === 0) {
        this.passwordMatched = true;
      }
    }
  }

  onCheckPasswordFormat(username: any) {
    return this.usernameFormatValid = {
      special: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(username)
    };
  }

  selectProvider(provider: any, type?: string) {
    this.filename = provider.filename?.split(".")[0];

    this.form.patchValue({
      ... this.form,
      game_provider_id: provider.game_provider_id
    });

    this.gameProviderId = provider.game_provider_id;
    this.username = this.usernameList.find(x => x.id === provider.game_provider_id)?.username;
  }


  onPasswordMask() {
    this.passwordInput.isMasked = !this.passwordInput.isMasked;
    this.passwordInput.type = this.passwordInput.isMasked ? 'password' : 'hide';
    this.passwordInput.icon = this.passwordInput.isMasked ? this.svg.hidePasswordIcon : this.svg.viewPasswordIcon;
  }

  onSave() {
    this.checkValidity = true;
    if (this.form.valid && this.passwordMatched) {
      const data = {
        ...this.form.value
      };
      Object.keys(data).forEach((key) => (data[key] == null || data[key] === ''));
      this.gamePasswordHttpService.updatePassword(data).subscribe(() => {
        this.isSuccess = this.gamePasswordHttpService.isSuccess
        this.filename = null;
        this.gameProviderId = null;
        this.username = null;
        this.formInit();
        this.checkValidity = false;
      });
    }
  }

  toJSON(content: string) {
    if (content == undefined || content == null) {
      return {}
    } else {
      return JSON.parse(content);
    }
  }

  private formInit() {
    this.form = new FormGroup({
      game_provider_id: new FormControl(this.gameProviderId, [Validators.required]),
      password: new FormControl(null,
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(`(^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$)|([a-zA-Z])`)
        ])),
      confirm_password: new FormControl(null, [Validators.required]),
    });
    of(null).pipe(
      delay(200), tap(() => this.focusInput.first.nativeElement.focus()
      )).subscribe();
  }

  onConfirm() {
    this.formInit();
  }

}
