import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthHttpService } from '@core/services/auth-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { ForgotPasswordHttpService } from '@views/modules/main/services/forgot-password-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
//Recaptcha
import { ReCaptchaV3Service } from 'ng-recaptcha';
// RxJS
import { tap, catchError } from 'rxjs/operators';
// Third Party
import Swal from 'sweetalert2';
import svgIconList from 'assets/icons.json';
declare var $: any;

@Component({
  selector: 'app-forgot-password-modal',
  templateUrl: './forgot-password-modal.component.html',
  styleUrls: ['./forgot-password-modal.component.scss']
})
export class ForgotPasswordModalComponent implements OnInit {
  svg: any = svgIconList;

  form: FormGroup;

  messages$ = this.forgotPasswordHttpService.messages$;
  isSuccess = this.forgotPasswordHttpService.isSuccess;
  disableButton = false;
  checkValidity = false;
  recaptchScore = 0;

  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordModalComponent>,
    public dialog: MatDialog,
    public loadingBar: LoadingBarService,
    private router: Router,
    private auth: AuthHttpService,
    private eventEmitterService: EventEmitterService,
    private recaptchaV3Service: ReCaptchaV3Service,
    public forgotPasswordHttpService: ForgotPasswordHttpService) { }

  ngOnInit() {
    this.initializeForm();
    this.eventEmitterService.buttonEmitter.subscribe(() => {
      this.disableButton = false;
    });
  }

  submit() {
    this.checkValidity = true;
    if (this.form.valid) {
      try {
        this.recaptchaV3Service.execute('ForgotPassword').pipe(
          tap((token: any) => {
            this.forgotPasswordHttpService.recaptcha({ response: token, username: this.form.value.username }).pipe(
              tap(() => {
                this.loadingBar.start();
                const data = {
                  ...this.form.value
                };
                this.forgotPasswordHttpService.forgotPassword(data).subscribe(() => {
                  this.isSuccess = this.forgotPasswordHttpService.isSuccess;
                  this.loadingBar.complete();
                  this.checkValidity = false;
                });
              })
            ).subscribe();
          }),
          catchError((error) => {
            var message = "Failed captcha";
            Swal.fire({
              html: '<div class="msg-icon">' + this.svg.dangerIcon + '</div>' +
                '<div class="text-center m-t-20">' +
                message +
                '</div>'
            });
            throw error;
          })
        ).subscribe();
      } catch (e) { }
    }
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onRedirectHome() {
    this.router.navigate(['/']);
    this.dialog.closeAll();
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.auth.forceLowerCaseInputControl(this.form, controlName, event);
  }

  numberOnly(event: KeyboardEvent) {
    return this.auth.numberOnly(event);
  }

  private initializeForm() {
    this.form = new FormGroup({
      username: new FormControl(null, [Validators.required]),
      phone_number: new FormControl(null, [Validators.required])
    });
  }
}
