// Service
import { EventEmitterService } from '@core/services/event-emitter.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthHttpService } from '@core/services/auth-http.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { environment } from '@env/environment';
// Angular
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-language-modal',
  templateUrl: './language-modal.component.html',
  styleUrls: ['./language-modal.component.scss']
})
export class LanguageModalComponent implements OnInit {
  svg: any = svgIconList;

  mode: string = window.mode;
  local = localStorage;
  availableCountryAndLanguages = JSON.parse(JSON.stringify(this.dropdownHttpService.availableCountryAndLanguages)); // To Clone
  languages = this.dropdownHttpService.languages;

  isLoggedIn = false;
  currentCountryCode = localStorage.getItem('country_code')?.toUpperCase();
  currentLanguageCode = localStorage.getItem('language_code')?.toUpperCase();
  currentDomain: string;
  hostname = window.location.hostname;
  hiddenDomains = this.dropdownHttpService.vipHiddenDomains;

  constructor(
    public dialogRef: MatDialogRef<LanguageModalComponent>,
    private dropdownHttpService: DropdownHttpService,
    private translateService: TranslateService,
    private authService: AuthHttpService,
    private store: Store<AppState>,
    private eventEmitterService: EventEmitterService,
  ) { }

  ngOnInit() {
    // First Alternative 
    if (
      JSON.stringify(this.availableCountryAndLanguages[environment.altDomainName]).includes('"' + this.hostname + '"') ||
      environment.altDomainName.split('|').includes(this.hostname)
    ) {
      this.currentDomain = environment.altDomainName;
    }
    // Second Alternative
    else if (
      JSON.stringify(this.availableCountryAndLanguages[environment.altDomainNameTwo]).includes('"' + this.hostname + '"') ||
      environment.altDomainNameTwo.split('|').includes(this.hostname)
    ) {
      this.currentDomain = environment.altDomainNameTwo;
    }
    // Default
    else {
      this.currentDomain = environment.domainName;
    }

    this.store.pipe(select(isLoggedIn)).subscribe(value => {
      this.isLoggedIn = value
      if (this.isLoggedIn) {
        this.availableCountryAndLanguages = JSON.parse(JSON.stringify(this.dropdownHttpService.availableCountryAndLanguages));
      } else {
        delete this.availableCountryAndLanguages[this.currentDomain]['TH'];
      }
    });
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  setLanguage(languageCode: string, countryCode: string) {
    sessionStorage.removeItem('promotionSession');
    sessionStorage.removeItem('slotSession');
    sessionStorage.removeItem('fishingSession');
    sessionStorage.removeItem('crashGameSession');
    sessionStorage.removeItem('lotterySession');
    sessionStorage.removeItem('tableSession');
    sessionStorage.removeItem('casinoSession');

    localStorage.setItem('country_code', countryCode);
    localStorage.setItem('language_code', languageCode);

    this.eventEmitterService.onFlagEmitter();

    this.translateService.use(languageCode);
    this.translateService.setDefaultLang(languageCode);

    this.onCloseDialog();

    if (this.isLoggedIn) {
      this.authService.updateLocale().subscribe(_ => {
        let userData = JSON.parse(localStorage.getItem('user_data'));
        userData = { ...userData, lang_code: languageCode };
        localStorage.setItem('user_data', JSON.stringify(userData));
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
    } else {
      var urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('lang');
      urlParams.append('lang', countryCode + '_' + languageCode);
      window.location.assign((environment.https ? 'https://' : 'http://') + window.location.hostname + window.location.pathname + '?' + urlParams.toString());
    }
  }

  returnZero() {
    return 0;
  }
}
