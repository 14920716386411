import { FormBuilder } from '@angular/forms';
// Angular
import { Component, OnInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormGroup } from '@angular/forms';
// Service
import { AuthHttpService } from '@core/services/auth-http.service';
import { ForgotPasswordHttpService } from '@views/modules/main/services/forgot-password-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
// RxJS
import { of } from 'rxjs';
import { tap, delay, catchError } from 'rxjs/operators';
//Recaptcha
import { ReCaptchaV3Service } from 'ng-recaptcha';
// Third Party
import Swal from 'sweetalert2';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  svg: any = svgIconList;

  @ViewChildren('focusInput') focusInput: QueryList<ElementRef>;

  mode: string = window.mode;
  form: FormGroup;

  messages$ = this.forgotPasswordHttpService.messages$;
  isSuccess = this.forgotPasswordHttpService.isSuccess;
  checkValidity = false;
  recaptchScore = 0;
  activeTab = this.activatedRoute.snapshot.paramMap.get('tab') ? this.activatedRoute.snapshot.paramMap.get('tab') : 'email';

  constructor(
    private fb: FormBuilder,
    public loadingBar: LoadingBarService,
    private router: Router,
    private auth: AuthHttpService,
    private recaptchaV3Service: ReCaptchaV3Service,
    public forgotPasswordHttpService: ForgotPasswordHttpService,
    private activatedRoute: ActivatedRoute
    ) { }

  ngOnInit() {
    this.initializeForm();
    of(null).pipe(
      delay(200), tap(() => this.focusInput.first.nativeElement.focus()
      )).subscribe();
  }

  onSelectTab(tab: string) {
    this.activeTab = tab;
    window.history.replaceState({}, '', `/forgot-password;tab=${tab}`);
  }

  submit() {
    this.checkValidity = true;
    if (this.form.valid) {
      try {
        this.recaptchaV3Service.execute('ForgotPassword').pipe(
          tap((token: any) => {
            this.forgotPasswordHttpService.recaptcha({response: token, username: this.form.value.username}).pipe(
              tap(() => {
                this.loadingBar.start();
                const data = {
                  ...this.form.value,
                  option: this.activeTab,
                };
                this.forgotPasswordHttpService.forgotPassword(data).subscribe(() => {
                  this.isSuccess = this.forgotPasswordHttpService.isSuccess;
                  this.loadingBar.complete();
                  this.checkValidity = false;
                });
              })
            ).subscribe();
          }),
          catchError((error) => {
            var message = "Failed captcha";
            Swal.fire({
              html: '<div class="msg-icon">' + this.svg.dangerIcon + '</div>' +
                '<div class="text-center m-t-20">' +
                message +
                '</div>'
            });
            throw error;
          })
        ).subscribe();
      } catch (e) { }
    }
  }

  onRedirectLogin() {
    this.router.navigate(['/login']);
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.auth.forceLowerCaseInputControl(this.form, controlName, event);
  }

  numberOnly(event: KeyboardEvent) {
    return this.auth.numberOnly(event);
  }

  private initializeForm() {
    this.form = this.fb.group({
      username: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(16)]
      ]
    });
  }
}
