// Model
import { ApiResponse } from './../models/api-response.model';
// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// RxJS
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NumberVerificationHttpService {
  messages$ = new Subject<any[]>();
  isSuccess: boolean;
  constructor(private http: HttpClient) { }

  requestOTP(new_mobile_number: string, requestType: string) {
    const parameter = new_mobile_number !== null ? `?mobile=${new_mobile_number}&request_type=${requestType}` : '';
    return this.http.get<ApiResponse>(`/otp/request${parameter}`).pipe(
      map(res => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      })
    );
  }

  verifyOTP(data: any, alert = true) {
    return this.http.post<ApiResponse>(`/otp/verify`, data).pipe(
      map(res => {
        if (alert){
          this.messages$.next(res.message);
        }
        this.isSuccess = res.success;
      })
    );
  }

}
